import dayjs from "dayjs";
import {
  IHistoryStackData,
  IJournalTableData,
  IProgressTableData,
} from "../../pages/Journal/types";

export const sortJournalsByCreateAt = (
  journals: IJournalTableData[]
): IJournalTableData[] => {
  return journals.sort(
    (a, b) => dayjs(b.createdAt)?.diff(dayjs(a.createdAt)) || 0
  );
};

export const sortProgressingTradeDataByTradeTime = (
  progressingTrades: IProgressTableData[]
): IProgressTableData[] => {
  return progressingTrades.sort(
    (a, b) => dayjs(b.tradeTime)?.diff(dayjs(a.tradeTime)) || 0
  );
};

export const sortHistoryTradeDataByTradeTime = (
  historyData: IHistoryStackData[]
): IHistoryStackData[] => {
  return historyData.sort(
    (a, b) => dayjs(b.tradeTime)?.diff(dayjs(a.tradeTime)) || 0
  );
};
