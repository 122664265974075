import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  IconButtonProps,
  Input,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Slider,
  Stack,
  TextField,
  Theme,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import PositionLeverageDialogSelect, {
  CheckboxesTags,
  PositionLeverageText,
  getIconAndName,
  CssTextField,
  StyledBox,
  ALLTAGS,
  TradeTypeMap,
} from "../../CommonTableNode";
import { ReactNode, useEffect, useRef, useState } from "react";
import createExchangeIconTable from "../../../../components/Icons/Exchanges/IconMapTable";
import CurrencyExchangeRoundedIcon from "@mui/icons-material/CurrencyExchangeRounded";
import createCoinIconTable from "../../../../components/Icons/CoinSymbols/IconMapTable";
import TollRoundedIcon from "@mui/icons-material/TollRounded";
import SwapVerticalCircleOutlinedIcon from "@mui/icons-material/SwapVerticalCircleOutlined";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";
import MoneyOffCsredRoundedIcon from "@mui/icons-material/MoneyOffCsredRounded";
import PaidRoundedIcon from "@mui/icons-material/PaidRounded";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import BalanceOutlinedIcon from "@mui/icons-material/BalanceOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import React from "react";
import Tooltip from "@mui/material/Tooltip";

import dayjs, { Dayjs } from "dayjs";

import {
  formatNumber,
  localeStringToNumber,
  renderWithLineBreaks,
  round,
} from "../../../../utils/utils";
import CustomEditField from "../../../../components/CustomEditField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CSSTransition } from "react-transition-group";
import {
  IAllTags,
  IClosedTradeTableData,
  ICollapseTradeTableData,
  IJournalTableData,
  IOpenTradeTableData,
  TagOptionType,
  TradeDataRecord,
  TradeType,
} from "../../types";
import { useAppDispatch } from "../../../../store/hooks";
import { RootState } from "../../../../store/store";
import { useSelector } from "react-redux";
import {
  deleteTrade,
  updateTrade,
} from "../../../../store/reducers/journal/data/tradeData";
import {
  setChangedGroupId,
  setEditDialogOpen,
  setEditDialogType,
  setEditDrawerOpen,
  setSelectedJournalGroupId,
  setSelectedJournalRowIndex,
  setSelectedJournalType,
} from "../../../../store/reducers/journal/page/journalDetails";

import {
  selectAllTags,
  selectClosedTrades,
  selectOpenTrades,
} from "../../../../store/selector/tradeDataSelector";
import { allExchanges, allSymbols } from "../../../../components/CommonNodes";
import { TradeTimeProps } from "../../Analysis/types";
import CustomDateTimeRangePicker from "../../../../components/CustomDateTimeRangePicker";
import { enqueueSnackbar } from "notistack";
import CustomRectSlider from "../../../../components/CustomRectSlider";
import MonetizationOnRoundedIcon from "@mui/icons-material/MonetizationOnRounded";

const TradeTimeRow: React.FC<TradeTimeProps> = ({
  mode,
  theme,
  tradeTime,
  setTradeTime,
  minDate,
  maxDate,
  selectedJournal,
}) => {
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <React.Fragment>
      <Grid item xs={4} md={2.4}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start", // 수평 중앙 정렬
            alignItems: "center", // 수직 중앙 정렬
            height: "100%", // Grid 아이템의 전체 높이 사용
          }}
        >
          <AccessTimeOutlinedIcon
            style={{
              color: theme.palette.secondary.light,
              fontSize: 18,
              marginRight: 4,
            }}
          />
          <Typography
            style={{
              color: theme.palette.secondary.light,
              fontSize: 14,
              fontWeight: "500",
            }}
          >
            거래시간
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={8} md={6}>
        <CustomDateTimeRangePicker
          mode={
            selectedJournal &&
            selectedJournal.exchangeMap &&
            Object.keys(selectedJournal.exchangeMap).length === 0
              ? mode
              : "READONLY"
          }
          theme={theme}
          tradeTime={tradeTime}
          setTradeTime={setTradeTime}
          minDate={minDate}
          maxDate={maxDate}
        />
      </Grid>
    </React.Fragment>
  );
};

//Second Row

interface ExchangeSymbolRowProps {
  mode: EditTradeMode;
  theme: Theme;
  tradeType: TradeType;
  exchange: string;
  symbol: string;
  setExchange: React.Dispatch<React.SetStateAction<string>>;
  setSymbol: React.Dispatch<React.SetStateAction<string>>;
  selectedJournal?: IJournalTableData;
}

const ExchangeSymbolRow: React.FC<ExchangeSymbolRowProps> = ({
  mode,
  theme,
  tradeType,
  exchange,
  symbol,
  setExchange,
  setSymbol,
  selectedJournal,
}) => {
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <React.Fragment>
      <Grid item xs={4} md={2.4}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start", // 수평 중앙 정렬
            alignItems: "center", // 수직 중앙 정렬
            height: "100%", // Grid 아이템의 전체 높이 사용
          }}
        >
          <CurrencyExchangeRoundedIcon
            style={{
              color: theme.palette.secondary.light,
              fontSize: 18,
              marginRight: 4,
            }}
          />
          <Typography
            style={{
              color: theme.palette.secondary.light,
              fontSize: 14,
              fontWeight: "500",
            }}
          >
            거래소
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={8} md={3.6}>
        <CssTextField
          placeholder="비어있음"
          value={exchange || null}
          fullWidth
          select
          onChange={(e) =>
            e.target.value && setExchange(e.target.value as string)
          }
          InputProps={{
            readOnly:
              selectedJournal &&
              selectedJournal.exchangeMap &&
              Object.keys(selectedJournal.exchangeMap).length === 0
                ? mode === "READONLY"
                : true,
          }}
          disabled={
            selectedJournal &&
            selectedJournal.exchangeMap &&
            Object.keys(selectedJournal.exchangeMap).length === 0
              ? mode === "READONLY"
              : true
          }
        >
          {Object.entries(allExchanges).map(([key, value]) => (
            <MenuItem key={key} value={key}>
              {value}
            </MenuItem>
          ))}
        </CssTextField>
      </Grid>
      <Grid item xs={4} md={2.4}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start", // 수평 중앙 정렬
            alignItems: "center", // 수직 중앙 정렬
            height: "100%", // Grid 아이템의 전체 높이 사용
          }}
        >
          <TollRoundedIcon
            style={{
              color: theme.palette.secondary.light,
              fontSize: 18,
              marginRight: 4,
            }}
          />
          <Typography
            style={{
              color: theme.palette.secondary.light,
              fontSize: 14,
              fontWeight: "500",
            }}
          >
            종목
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={8} md={3.6}>
        <CssTextField
          placeholder="비어있음"
          value={symbol || null}
          fullWidth
          select
          onChange={(e) =>
            e.target.value && setSymbol(e.target.value as string)
          }
          InputProps={{
            readOnly:
              selectedJournal &&
              selectedJournal.exchangeMap &&
              Object.keys(selectedJournal.exchangeMap).length === 0
                ? mode === "READONLY"
                : true,
          }}
          disabled={
            selectedJournal &&
            selectedJournal.exchangeMap &&
            Object.keys(selectedJournal.exchangeMap).length === 0
              ? mode === "READONLY"
              : true
          }
        >
          {Object.entries(allSymbols).map(([key, value]) => (
            <MenuItem key={key} value={key}>
              {value}
            </MenuItem>
          ))}
        </CssTextField>
      </Grid>
    </React.Fragment>
  );
};

//Third Row

interface PositionMarketTypeRowProps {
  mode: EditTradeMode;
  theme: Theme;
  tradeType: TradeType;
  position?: string;
  leverage?: string | number;
  marketType: string;
  dialogOpen: boolean;
  setMarketType: React.Dispatch<React.SetStateAction<string>>;
  handleCloseDialog: (result: {
    position: string | undefined;
    leverage: number | string | undefined;
  }) => void;
  handleOpenDialog: () => void;
  selectedJournal?: IJournalTableData;
}

const PositionMarketTypeRow: React.FC<PositionMarketTypeRowProps> = ({
  mode,
  theme,
  tradeType,
  position,
  leverage,
  marketType,
  dialogOpen,
  setMarketType,
  handleCloseDialog,
  handleOpenDialog,
  selectedJournal,
}) => {
  return (
    <React.Fragment>
      <Grid item xs={4} md={2.4}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start", // 수평 중앙 정렬
            alignItems: "center", // 수직 중앙 정렬
            height: "100%", // Grid 아이템의 전체 높이 사용
          }}
        >
          <SwapVerticalCircleOutlinedIcon
            style={{
              color: theme.palette.secondary.light,
              fontSize: 18,
              marginRight: 4,
            }}
          />
          <Typography
            style={{
              color: theme.palette.secondary.light,
              fontSize: 14,
              fontWeight: "500",
            }}
          >
            포지션
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={8} md={3.6}>
        <StyledBox
          sx={{
            height: "100%",
            padding: 0,
            display: "flex", // 내부 요소를 flexbox로 정렬
            alignItems: "center", // 수직 정렬
            justifyContent:
              position === null || leverage === null ? "flex-start" : "center", // 수평 정렬
            border: "1.2px solid transparent", // 기본 border 설정
          }}
          onClick={() => {
            if (
              selectedJournal &&
              selectedJournal.exchangeMap &&
              Object.keys(selectedJournal.exchangeMap).length === 0
                ? mode !== "READONLY"
                : false
            )
              handleOpenDialog();
          }}
        >
          {position === null || position === undefined ? (
            <Typography
              sx={{
                p: 0,
                m: 0,
                pl: 0.5,
                fontSize: 14,
                color: theme.palette.secondary.light,
              }}
            >
              비어있음
            </Typography>
          ) : position !== null &&
            leverage !== null &&
            position !== undefined &&
            leverage !== undefined ? (
            <PositionLeverageText
              position={{ text: position }}
              leverage={{ text: `X${leverage}` }}
            />
          ) : null}
        </StyledBox>
        <PositionLeverageDialogSelect
          open={dialogOpen}
          onClose={handleCloseDialog}
        />
      </Grid>
      <Grid item xs={4} md={2.4}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start", // 수평 중앙 정렬
            alignItems: "center", // 수직 중앙 정렬
            height: "100%", // Grid 아이템의 전체 높이 사용
          }}
        >
          <StorefrontOutlinedIcon
            style={{
              color: theme.palette.secondary.light,
              fontSize: 18,
              marginRight: 4,
            }}
          />
          <Typography
            style={{
              color: theme.palette.secondary.light,
              fontSize: 14,
              fontWeight: "500",
            }}
          >
            시장
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={8} md={3.6}>
        <CssTextField
          placeholder="비어있음"
          value={marketType}
          fullWidth
          select
          onChange={(e) => setMarketType(e.target.value as string)}
          InputProps={{
            readOnly:
              selectedJournal &&
              selectedJournal.exchangeMap &&
              Object.keys(selectedJournal.exchangeMap).length === 0
                ? mode === "READONLY"
                : true,
          }}
          disabled={
            selectedJournal &&
            selectedJournal.exchangeMap &&
            Object.keys(selectedJournal.exchangeMap).length === 0
              ? mode === "READONLY"
              : true
          }
        >
          <MenuItem key="선물" value="USDT-FUTURES">
            선물
          </MenuItem>
          <MenuItem key="현물" value="SPOT">
            현물
          </MenuItem>
        </CssTextField>
      </Grid>
    </React.Fragment>
  );
};

//Fourth Row
interface EntryAmountSizeRowProps {
  mode: EditTradeMode;
  theme: Theme;
  tradeType: TradeType;
  entryPrice?: number;
  allSize?: number;
  backupAllSize: any;
  backupSize: any;
  backupRatio: any;
  symbol?: string;
  size?: number;
  dragging: boolean;
  entryAmount?: number;
  amountRatio?: number;
  position?: string;
  prevIndividualAvgEntryPrice?: number;
  setEntryPrice: React.Dispatch<React.SetStateAction<number | undefined>>;
  setAllSize: React.Dispatch<React.SetStateAction<number>>;
  setSize: React.Dispatch<React.SetStateAction<number | undefined>>;
  setEntryAmount: React.Dispatch<React.SetStateAction<number | undefined>>;
  setAmountRatio: React.Dispatch<React.SetStateAction<number>>;
  setProfit: React.Dispatch<React.SetStateAction<number | undefined>>;
  selectedJournal?: IJournalTableData;
}

const EntryAmountSizeRow: React.FC<EntryAmountSizeRowProps> = ({
  mode,
  theme,
  tradeType,
  entryPrice,
  allSize,
  backupAllSize,
  backupSize,
  backupRatio,
  symbol,
  size,
  dragging,
  entryAmount,
  amountRatio,
  position,
  prevIndividualAvgEntryPrice,
  setEntryPrice,
  setAllSize,
  setSize,
  setProfit,
  setEntryAmount,
  setAmountRatio,
  selectedJournal,
}) => {
  const [tempEntryAmount, setTempEntryAmount] = useState<string>(
    entryAmount?.toLocaleString() || ""
  );
  const [tempSize, setTempSize] = useState<string>(
    size?.toLocaleString() || ""
  );

  const handleEntryAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTempEntryAmount(event.target.value);
  };

  const handleSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTempSize(event.target.value);
  };

  const handleEntryAmountBlur = () => {
    const value = tempEntryAmount.replace(/,/g, "");
    let newEntryAmount = 0;
    if (value === "") {
      setEntryAmount(undefined);
      setTempEntryAmount("");
    } else if (!isNaN(Number(value))) {
      newEntryAmount = localeStringToNumber(value);
      setEntryAmount(newEntryAmount);
      setTempEntryAmount(newEntryAmount.toLocaleString());
    }

    if (
      selectedJournal &&
      selectedJournal.exchangeMap &&
      Object.keys(selectedJournal.exchangeMap).length === 0
    ) {
      if (
        newEntryAmount !== 0 &&
        entryPrice !== undefined &&
        entryPrice !== 0
      ) {
        if (tradeType !== "PositionEnd") {
          const newSize = round(newEntryAmount / entryPrice, 5);

          let entireSize = 0;
          let newAllSize = 0;

          if (tradeType === "PositionStart" || tradeType === "Buy") {
            entireSize = backupAllSize - (backupSize || 0);
            newAllSize = entireSize + newSize;
          } else {
            entireSize = backupAllSize + (backupSize || 0);
            newAllSize = entireSize - newSize;
            const newProfit =
              position === "SHORT"
                ? ((prevIndividualAvgEntryPrice || 0) - entryPrice) * newSize
                : (entryPrice - (prevIndividualAvgEntryPrice || 0)) * newSize;

            setProfit(newProfit);
          }

          const newAmountRatio =
            newAllSize === newSize
              ? newSize === 0
                ? 0
                : 100
              : Math.abs(round(((newSize || 0) / entireSize) * 100.0, 1));

          setSize(newSize);
          setAllSize(newAllSize);
          setAmountRatio(newAmountRatio);
        } else {
          const newProfit =
            position === "SHORT"
              ? ((prevIndividualAvgEntryPrice || 0) - entryPrice) * backupSize
              : (entryPrice - (prevIndividualAvgEntryPrice || 0)) * backupSize;

          setProfit(newProfit);
          setSize(backupSize);
          setAllSize(backupAllSize);
          setAmountRatio(backupRatio);
        }
      }
    }
  };

  const handleAllSizeBlur = ({
    newSize,
    allSize,
  }: {
    newSize: number;
    allSize: number;
  }) => {
    if (tradeType === "PositionStart" || tradeType === "Buy") {
      setAllSize(allSize + newSize);
    } else {
      setAllSize(allSize - newSize);
    }
  };

  const handleSizeBlur = () => {
    const value = tempSize.replace(/,/g, "");
    if (value === "") {
      handleAllSizeBlur({ newSize: size || 0, allSize: allSize || 0 });

      setSize(undefined);
      setTempSize("");
    } else if (!isNaN(Number(value))) {
      const newSize = localeStringToNumber(value);

      handleAllSizeBlur({ newSize: newSize, allSize: allSize || 0 });
      setSize(newSize);
      setTempSize(newSize.toLocaleString());
    }
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement>,
    onBlurCallback: () => void
  ) => {
    if (event.key === "Enter") {
      onBlurCallback();
      event.currentTarget.blur();
    }
  };

  useEffect(() => {
    setTempSize(size?.toLocaleString() || "");
  }, [size]);

  useEffect(() => {
    setTempEntryAmount(entryAmount?.toLocaleString() || "");
  }, [entryAmount]);

  return (
    <React.Fragment>
      <Grid item xs={4} md={2.4}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start", // 수평 중앙 정렬
            alignItems: "center", // 수직 중앙 정렬
            height: "100%", // Grid 아이템의 전체 높이 사용
          }}
        >
          <PaidRoundedIcon
            style={{
              color: theme.palette.secondary.light,
              fontSize: 18,
              marginRight: 4,
            }}
          />
          <Typography
            style={{
              color: theme.palette.secondary.light,
              fontSize: 14,
              fontWeight: "500",
            }}
          >
            {tradeType === "PositionStart"
              ? "진입비중"
              : tradeType === "Buy"
              ? "매수비중"
              : "매도비중"}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={8} md={3.6}>
        <CssTextField
          placeholder="비어있음"
          value={tempEntryAmount}
          onChange={handleEntryAmountChange}
          onBlur={handleEntryAmountBlur}
          onKeyDown={(e) => handleKeyDown(e, handleEntryAmountBlur)}
          InputProps={{
            endAdornment: <InputAdornment position="end">USDT</InputAdornment>,
            readOnly:
              selectedJournal &&
              selectedJournal.exchangeMap &&
              Object.keys(selectedJournal.exchangeMap).length === 0
                ? tradeType === "PositionEnd"
                  ? true
                  : mode === "READONLY"
                : true,
          }}
          disabled={
            selectedJournal &&
            selectedJournal.exchangeMap &&
            Object.keys(selectedJournal.exchangeMap).length === 0
              ? tradeType === "PositionEnd"
                ? true
                : mode === "READONLY"
              : true
          }
        ></CssTextField>
      </Grid>

      <Grid item xs={4} md={2.4}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start", // 수평 중앙 정렬
            alignItems: "center", // 수직 중앙 정렬
            height: "100%", // Grid 아이템의 전체 높이 사용
          }}
        >
          <AttachMoneyRoundedIcon
            style={{
              color: theme.palette.secondary.light,
              fontSize: 18,
              marginRight: 4,
            }}
          />
          <Typography
            style={{
              color: theme.palette.secondary.light,
              fontSize: 14,
              fontWeight: "500",
            }}
          >
            {tradeType === "PositionStart"
              ? "진입수량"
              : tradeType === "Buy"
              ? "매수수량"
              : "매도수량"}
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={8} md={3.6}>
        <CssTextField
          placeholder="비어있음"
          value={tempSize}
          onChange={handleSizeChange}
          onBlur={handleSizeBlur}
          onKeyDown={(e) => handleKeyDown(e, handleSizeBlur)}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {symbol && symbol.includes("USDT")
                  ? symbol.replace(/USDT$/, "")
                  : ""}
              </InputAdornment>
            ),
            readOnly: true,
          }}
          disabled={true}
        ></CssTextField>
      </Grid>
    </React.Fragment>
  );
};

//Fourth Row
interface EntryAmountSliderProps {
  mode: EditTradeMode;
  theme: Theme;
  tradeType: TradeType;
  entryPrice?: number;
  allSize: number;
  backupAllSize: number;
  backupSize?: number;
  dragging: boolean;
  amountRatio?: number;
  position?: string;
  prevIndividualAvgEntryPrice?: number;
  setAmountRatio: React.Dispatch<React.SetStateAction<number>>;
  setDragging: React.Dispatch<React.SetStateAction<boolean>>;
  setAllSize: React.Dispatch<React.SetStateAction<number>>;
  setSize: React.Dispatch<React.SetStateAction<number | undefined>>;
  setEntryAmount: React.Dispatch<React.SetStateAction<number | undefined>>;
  setProfit: React.Dispatch<React.SetStateAction<number | undefined>>;
  selectedJournal?: IJournalTableData;
}

const EntryAmountSliderRow: React.FC<EntryAmountSliderProps> = ({
  mode,
  theme,
  tradeType,
  entryPrice,
  allSize,
  backupAllSize,
  backupSize,
  dragging,
  amountRatio,
  position,
  prevIndividualAvgEntryPrice,
  setAmountRatio,
  setSize,
  setAllSize,
  setDragging,
  setEntryAmount,
  setProfit,
  selectedJournal,
}) => {
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [sliderValue, setSliderValue] = useState<number>(amountRatio || 0.0); // 슬라이더 값
  const [displayValue, setDisplayValue] = useState<number>(amountRatio || 0.0); // 표시할 값

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      if (dragging) {
        // 마우스의 X 좌표 변화에 따른 속도 기반으로 displayValue 조정
        const movementX = event.movementX || 0;
        const changeAmount = Math.abs(movementX) * 0.2; // 속도에 비례하여 변화폭 조정

        // 오른쪽으로 드래그 시 값 증가
        if (movementX > 0) {
          setDisplayValue((prev) => {
            const newDisplayValue = prev + changeAmount;
            if (tradeType === "PositionEnd" || tradeType === "Sell") {
              if (newDisplayValue > 100) return 100.0;
            }
            return newDisplayValue;
          }); // 값을 계속 증가시킴
        }
        // 왼쪽으로 드래그 시 값 감소
        else if (movementX < 0) {
          setDisplayValue((prev) => {
            const newDisplayValue = Math.max(0, prev - changeAmount);
            return newDisplayValue;
          }); // 값을 감소시키고 최소값 0으로 설정
        }
      }
    };

    const handleMouseUp = () => {
      setDragging(false); // 드래그 종료
    };

    if (dragging) {
      // 마우스가 어디서든 움직일 수 있게 전역 이벤트 리스너 추가
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp); // 전역 마우스 업 이벤트 처리
    }

    // Cleanup 함수로 이벤트 리스너 제거
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [dragging]);

  useEffect(() => {
    // displayValue가 100 미만일 경우 sliderValue를 동기화
    if (displayValue <= 100) {
      setSliderValue(displayValue);
    }
    setAmountRatio(displayValue);
    if (
      displayValue !== amountRatio &&
      entryPrice !== undefined &&
      entryPrice !== 0 &&
      allSize !== undefined &&
      tradeType !== "PositionStart" &&
      tradeType !== "PositionEnd"
    ) {
      let entireSize = 0;
      let newAllSize = 0;
      let newSize = 0;

      if (tradeType === "Buy") {
        entireSize = backupAllSize - (backupSize || 0);
        newSize = round((entireSize * displayValue) / 100.0, 5);
        newAllSize = entireSize + newSize;
      } else {
        entireSize = backupAllSize + (backupSize || 0);
        newSize = round((entireSize * displayValue) / 100.0, 5);
        newAllSize = entireSize - newSize;

        const newProfit =
          position === "SHORT"
            ? ((prevIndividualAvgEntryPrice || 0) - entryPrice) * newSize
            : (entryPrice - (prevIndividualAvgEntryPrice || 0)) * newSize;

        setProfit(newProfit);
      }

      const newEntryAmount = entryPrice * newSize;

      setEntryAmount(newEntryAmount);
      setSize(newSize);
      setAllSize(newAllSize);
    }
  }, [displayValue]);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {};

  const handleSliderChangeCommitted = (
    event: React.SyntheticEvent | Event,
    value: number | number[]
  ) => {};

  const handleMouseDown = () => {
    setDragging(true); // 드래그 시작
  };

  useEffect(() => {
    if (!dragging) {
      const newAmountRatio = amountRatio || 0.0;
      setDisplayValue(newAmountRatio);
      setSliderValue(newAmountRatio > 100 ? 100 : newAmountRatio);
    }
  }, [amountRatio]);

  return (
    <React.Fragment>
      <Grid item xs={4} md={2.4}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start", // 수평 중앙 정렬
            alignItems: "center", // 수직 중앙 정렬
            height: "100%", // Grid 아이템의 전체 높이 사용
          }}
        >
          <PaidRoundedIcon
            style={{
              color: theme.palette.secondary.light,
              fontSize: 18,
              marginRight: 4,
            }}
          />
          <Typography
            style={{
              color: theme.palette.secondary.light,
              fontSize: 14,
              fontWeight: "500",
            }}
          >
            비중비율
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={8} md={9.2}>
        <Box sx={{ width: "100%", position: "relative" }}>
          {/* 슬라이더 중앙에 표시될 텍스트 */}
          <Typography
            variant="subtitle1"
            sx={{
              position: "absolute",
              top: matches ? "42%" : "48%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 3, // 텍스트가 thumb 위에 위치하도록 설정
              color:
                selectedJournal &&
                selectedJournal.exchangeMap &&
                Object.keys(selectedJournal.exchangeMap).length === 0
                  ? mode === "READONLY"
                    ? "black"
                    : tradeType === "PositionStart" ||
                      tradeType === "PositionEnd" ||
                      entryPrice === undefined ||
                      allSize === undefined ||
                      entryPrice === 0
                    ? "black"
                    : "white"
                  : "black",
              fontSize: 12,
              fontWeight: "400",
            }}
          >
            {displayValue && displayValue.toFixed(3)} %
          </Typography>

          {/* 슬라이더 */}
          <Slider
            disabled={
              (selectedJournal &&
              selectedJournal.exchangeMap &&
              Object.keys(selectedJournal.exchangeMap).length === 0
                ? mode === "READONLY"
                : true) ||
              tradeType === "PositionStart" ||
              tradeType === "PositionEnd" ||
              entryPrice === undefined ||
              allSize === undefined ||
              entryPrice === 0
            }
            value={sliderValue}
            onChange={handleSliderChange}
            aria-labelledby="custom-slider"
            valueLabelDisplay="off"
            onMouseDown={handleMouseDown}
            onChangeCommitted={handleSliderChangeCommitted}
            min={0}
            max={100}
            step={0.1}
            sx={{
              padding: 0,
              width: "100%",
              height: "12px", // 슬라이더 높이 설정
              zIndex: 2, // 텍스트 아래에 위치하도록 설정
              "& .MuiSlider-thumb": {
                width: "4px", // thumb 크기 설정
                height: "12px", // thumb 높이 설정

                borderRadius: "8px",
                boxShadow: "none",
                zIndex: 2, // thumb이 텍스트 뒤에 위치하도록 설정
              },
              "& .MuiSlider-track": {
                height: "12px", // 트랙 높이 설정
                borderRadius: "4px",
              },
              "& .MuiSlider-rail": {
                height: "12px", // 레일 높이 설정
                borderRadius: "4px",
              },
              // disabled 상태일 때 색상 변경
              "&.Mui-disabled": {
                "& .MuiSlider-track": {
                  backgroundColor: "#B2BAC2", // 트랙 색상 설정
                },
                "& .MuiSlider-thumb": {
                  backgroundColor: "#B2BAC2", // thumb 색상 설정
                },
                "& .MuiSlider-rail": {
                  backgroundColor: "#B2BAC2", // 레일 색상 설정
                },
              },
            }}
          />
        </Box>
      </Grid>
    </React.Fragment>
  );
};

//Fifth Row
interface EntryPriceAllSizeRowProps {
  mode: EditTradeMode;
  theme: Theme;
  tradeType: TradeType;
  entryPrice?: number;
  allSize?: number;
  backupAllSize: any;
  backupSize: any;
  backupRatio: any;
  symbol?: string;
  size?: number;
  dragging: boolean;
  entryAmount?: number;
  amountRatio?: number;
  position?: string;
  prevIndividualAvgEntryPrice?: number;
  setEntryPrice: React.Dispatch<React.SetStateAction<number | undefined>>;
  setAllSize: React.Dispatch<React.SetStateAction<number>>;
  setSize: React.Dispatch<React.SetStateAction<number | undefined>>;
  setEntryAmount: React.Dispatch<React.SetStateAction<number | undefined>>;
  setAmountRatio: React.Dispatch<React.SetStateAction<number>>;
  setProfit: React.Dispatch<React.SetStateAction<number | undefined>>;
  selectedJournal?: IJournalTableData;
}

const EntryPriceAllEntrySizeRow: React.FC<EntryPriceAllSizeRowProps> = ({
  mode,
  theme,
  tradeType,
  entryPrice,
  allSize,
  backupAllSize,
  backupSize,
  backupRatio,
  symbol,
  size,
  position,
  prevIndividualAvgEntryPrice,
  dragging,
  amountRatio,
  entryAmount,
  setEntryPrice,
  setAllSize,
  setSize,
  setEntryAmount,
  setAmountRatio,
  setProfit,
  selectedJournal,
}) => {
  const [tempEntryPrice, setTempEntryPrice] = useState<string>(
    entryPrice?.toLocaleString() || ""
  );
  const [tempAllSize, setTempAllSize] = useState<string>(
    allSize?.toLocaleString() || ""
  );

  const handleEntryPriceChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTempEntryPrice(event.target.value);
  };

  const handleAllSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTempAllSize(event.target.value);
  };

  const handleEntryPriceBlur = () => {
    const value = tempEntryPrice.replace(/,/g, "");
    let newEntryPrice = 0;
    if (value === "") {
      setEntryPrice(undefined);
      setTempEntryPrice("");
    } else if (!isNaN(Number(value))) {
      newEntryPrice = localeStringToNumber(value);
      setEntryPrice(newEntryPrice);
      setTempEntryPrice(newEntryPrice.toLocaleString());
    }

    if (
      selectedJournal &&
      selectedJournal.exchangeMap &&
      Object.keys(selectedJournal.exchangeMap).length === 0
    ) {
      if (tradeType !== "PositionEnd" && tradeType !== "Sell") {
        if (
          newEntryPrice !== 0 &&
          entryAmount !== undefined &&
          entryAmount !== 0
        ) {
          const newSize = round(entryAmount / newEntryPrice, 5);

          let entireSize = 0;
          let newAllSize = 0;

          entireSize = backupAllSize - (backupSize || 0);
          newAllSize = entireSize + newSize;

          const newAmountRatio =
            newAllSize === newSize
              ? newSize === 0
                ? 0
                : 100
              : Math.abs(round(((newSize || 0) / entireSize) * 100.0, 1));

          setSize(newSize);
          setAllSize(newAllSize);
          setAmountRatio(newAmountRatio);
        } else {
          setSize(backupSize);
          setAllSize(backupAllSize);
          setAmountRatio(backupRatio);
        }
      } else {
        const newEntryAmount = (size || 0) * newEntryPrice;

        const newProfit =
          position === "SHORT"
            ? ((prevIndividualAvgEntryPrice || 0) - newEntryPrice) * (size || 0)
            : (newEntryPrice - (prevIndividualAvgEntryPrice || 0)) *
              (size || 0);

        setProfit(newProfit);
        setEntryAmount(newEntryAmount);
      }
    }
  };

  const handleAllSizeBlur = () => {
    const value = tempAllSize.replace(/,/g, "");
    if (value === "") {
      setAllSize(0);
      setTempAllSize("");
    } else if (!isNaN(Number(value))) {
      setAllSize(localeStringToNumber(value));
      setTempAllSize(localeStringToNumber(value).toLocaleString());
    }
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement>,
    onBlurCallback: () => void
  ) => {
    if (event.key === "Enter") {
      onBlurCallback();
      event.currentTarget.blur();
    }
  };

  useEffect(() => {
    setTempEntryPrice(entryPrice?.toLocaleString() || "");
  }, [entryPrice]);

  useEffect(() => {
    setTempAllSize(allSize?.toLocaleString() || "");
  }, [allSize]);

  return (
    <React.Fragment>
      <Grid item xs={4} md={2.4}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start", // 수평 중앙 정렬
            alignItems: "center", // 수직 중앙 정렬
            height: "100%", // Grid 아이템의 전체 높이 사용
          }}
        >
          <PaidOutlinedIcon
            style={{
              color: theme.palette.secondary.light,
              fontSize: 18,
              marginRight: 4,
            }}
          />
          <Typography
            style={{
              color: theme.palette.secondary.light,
              fontSize: 14,
              fontWeight: "500",
            }}
          >
            {tradeType === "PositionStart"
              ? "진입가"
              : tradeType === "Buy"
              ? "매수가"
              : "매도가"}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={8} md={3.6}>
        <CssTextField
          placeholder="비어있음"
          value={tempEntryPrice}
          onChange={handleEntryPriceChange}
          onBlur={handleEntryPriceBlur}
          onKeyDown={(e) => handleKeyDown(e, handleEntryPriceBlur)}
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end">USDT</InputAdornment>,
            readOnly:
              selectedJournal &&
              selectedJournal.exchangeMap &&
              Object.keys(selectedJournal.exchangeMap).length === 0
                ? mode === "READONLY"
                : true,
          }}
          disabled={
            selectedJournal &&
            selectedJournal.exchangeMap &&
            Object.keys(selectedJournal.exchangeMap).length === 0
              ? mode === "READONLY"
              : true
          }
        ></CssTextField>
      </Grid>

      <Grid item xs={4} md={2.4}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start", // 수평 중앙 정렬
            alignItems: "center", // 수직 중앙 정렬
            height: "100%", // Grid 아이템의 전체 높이 사용
          }}
        >
          <AttachMoneyRoundedIcon
            style={{
              color: theme.palette.secondary.light,
              fontSize: 18,
              marginRight: 4,
            }}
          />
          <Typography
            style={{
              color: theme.palette.secondary.light,
              fontSize: 14,
              fontWeight: "500",
            }}
          >
            보유수량
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={8} md={3.6}>
        <CssTextField
          disabled={true}
          placeholder="비어있음"
          value={tempAllSize}
          onChange={handleAllSizeChange}
          onBlur={handleAllSizeBlur}
          onKeyDown={(e) => handleKeyDown(e, handleAllSizeBlur)}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {symbol && symbol.includes("USDT")
                  ? symbol.replace(/USDT$/, "")
                  : ""}
              </InputAdornment>
            ),
            readOnly: true,
          }}
        ></CssTextField>
      </Grid>
    </React.Fragment>
  );
};

interface StopLossPriceTakeProfitPriceRowProps {
  mode: EditTradeMode;
  theme: Theme;
  tradeType: TradeType;
  stopLossPrice?: number;
  takeProfitPrice?: number;
  setStopLossPrice: React.Dispatch<React.SetStateAction<number | undefined>>;
  setTakeProfitPrice: React.Dispatch<React.SetStateAction<number | undefined>>;
  selectedJournal?: IJournalTableData;
}

const StopLossPriceTakeProfitPriceRow: React.FC<
  StopLossPriceTakeProfitPriceRowProps
> = ({
  mode,
  theme,
  tradeType,
  stopLossPrice,
  takeProfitPrice,
  setStopLossPrice,
  setTakeProfitPrice,
  selectedJournal,
}) => {
  const [tempStopLossPrice, setTempStopLossPrice] = useState<string>(
    stopLossPrice?.toLocaleString() || ""
  );
  const [tempTakeProfitPrice, setTempTakeProfitPrice] = useState<string>(
    takeProfitPrice?.toLocaleString() || ""
  );

  const handleStopLossPriceChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTempStopLossPrice(event.target.value);
  };

  const handleTakeProfitPriceChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTempTakeProfitPrice(event.target.value);
  };

  const handleStopLossPriceBlur = () => {
    const value = tempStopLossPrice.replace(/,/g, "");
    if (value === "") {
      setStopLossPrice(undefined);
      setTempStopLossPrice("");
    } else if (!isNaN(Number(value))) {
      setStopLossPrice(localeStringToNumber(value));
      setTempStopLossPrice(localeStringToNumber(value).toLocaleString());
    }
  };

  const handleTakeProfitPriceBlur = () => {
    const value = tempTakeProfitPrice.replace(/,/g, "");
    if (value === "") {
      setTakeProfitPrice(undefined);
      setTempTakeProfitPrice("");
    } else if (!isNaN(Number(value))) {
      setTakeProfitPrice(localeStringToNumber(value));
      setTempTakeProfitPrice(localeStringToNumber(value).toLocaleString());
    }
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement>,
    onBlurCallback: () => void
  ) => {
    if (event.key === "Enter") {
      onBlurCallback();
      event.currentTarget.blur();
    }
  };

  useEffect(() => {
    setTempStopLossPrice(stopLossPrice?.toLocaleString() || "");
  }, [stopLossPrice]);

  useEffect(() => {
    setTempTakeProfitPrice(takeProfitPrice?.toLocaleString() || "");
  }, [takeProfitPrice]);

  return (
    <React.Fragment>
      <Grid item xs={4} md={2.4}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start", // 수평 중앙 정렬
            alignItems: "center", // 수직 중앙 정렬
            height: "100%", // Grid 아이템의 전체 높이 사용
          }}
        >
          <AttachMoneyRoundedIcon
            style={{
              color: theme.palette.secondary.light,
              fontSize: 18,
              marginRight: 4,
            }}
          />
          <Typography
            style={{
              color: theme.palette.secondary.light,
              fontSize: 14,
              fontWeight: "500",
            }}
          >
            손절가
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={8} md={3.6}>
        <CssTextField
          placeholder="비어있음"
          value={tempStopLossPrice}
          onChange={handleStopLossPriceChange}
          onBlur={handleStopLossPriceBlur}
          onKeyDown={(e) => handleKeyDown(e, handleStopLossPriceBlur)}
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end">USDT</InputAdornment>,
            readOnly: mode === "READONLY",
          }}
          disabled={mode === "READONLY"}
        ></CssTextField>
      </Grid>

      {tradeType === "PositionStart" ? (
        <Grid item xs={4} md={2.4}>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start", // 수평 중앙 정렬
              alignItems: "center", // 수직 중앙 정렬
              height: "100%", // Grid 아이템의 전체 높이 사용
            }}
          >
            <AttachMoneyRoundedIcon
              style={{
                color: theme.palette.secondary.light,
                fontSize: 18,
                marginRight: 4,
              }}
            />
            <Typography
              style={{
                color: theme.palette.secondary.light,
                fontSize: 14,
                fontWeight: "500",
              }}
            >
              익절가
            </Typography>
          </Box>
        </Grid>
      ) : null}

      {tradeType === "PositionStart" ? (
        <Grid item xs={8} md={3.6}>
          <CssTextField
            placeholder="비어있음"
            value={tempTakeProfitPrice}
            onChange={handleTakeProfitPriceChange}
            onBlur={handleTakeProfitPriceBlur}
            onKeyDown={(e) => handleKeyDown(e, handleTakeProfitPriceBlur)}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">USDT</InputAdornment>
              ),
              readOnly: mode === "READONLY",
            }}
            disabled={mode === "READONLY"}
          ></CssTextField>
        </Grid>
      ) : null}
    </React.Fragment>
  );
};

//Sixth Row

interface CommissionProfitLossRatioRowProps {
  mode: EditTradeMode;
  theme: Theme;
  tradeType: TradeType;
  commission?: number;
  profitLossRatio?: string;
  profit?: number;
  setCommission: React.Dispatch<React.SetStateAction<number | undefined>>;
  setProfitLossRatio: React.Dispatch<React.SetStateAction<string | undefined>>;
  setProfit: React.Dispatch<React.SetStateAction<number | undefined>>;
  selectedJournal?: IJournalTableData;
}

const CommissionProfitLossRatioRow: React.FC<
  CommissionProfitLossRatioRowProps
> = ({
  mode,
  theme,
  tradeType,
  commission,
  profitLossRatio,
  profit,
  setCommission,
  setProfitLossRatio,
  setProfit,
  selectedJournal,
}) => {
  const [tempCommission, setTempCommission] = useState<string>(
    commission?.toLocaleString() || ""
  );

  const handleCommissionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTempCommission(event.target.value);
  };

  const handleCommissionBlur = () => {
    const value = tempCommission.replace(/,/g, "");
    if (value === "") {
      setCommission(undefined);
      setTempCommission("");
    } else if (!isNaN(Number(value))) {
      setCommission(localeStringToNumber(value));
      setTempCommission(localeStringToNumber(value).toLocaleString());
    }
  };

  const [tempProfit, setTempProfit] = useState<string>(
    profit?.toLocaleString() || ""
  );

  const handleProfitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTempProfit(event.target.value);
  };

  const handleProfitBlur = () => {
    const value = tempCommission.replace(/,/g, "");
    if (value === "") {
      setProfit(undefined);
      setTempProfit("");
    } else if (!isNaN(Number(value))) {
      setProfit(localeStringToNumber(value));
      setTempProfit(localeStringToNumber(value).toLocaleString());
    }
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement>,
    onBlurCallback: () => void
  ) => {
    if (event.key === "Enter") {
      onBlurCallback();
      event.currentTarget.blur();
    }
  };

  const profitLossStateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setProfitLossRatio(event.target.value);
  };

  useEffect(() => {
    setTempCommission(commission?.toLocaleString() || "");
  }, [commission]);

  useEffect(() => {
    setTempProfit(profit?.toLocaleString() || "");
  }, [profit]);

  return (
    <React.Fragment>
      <Grid item xs={4} md={2.4}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start", // 수평 중앙 정렬
            alignItems: "center", // 수직 중앙 정렬
            height: "100%", // Grid 아이템의 전체 높이 사용
          }}
        >
          <MoneyOffCsredRoundedIcon
            style={{
              color: theme.palette.secondary.light,
              fontSize: 18,
              marginRight: 4,
            }}
          />
          <Typography
            style={{
              color: theme.palette.secondary.light,
              fontSize: 14,
              fontWeight: "500",
            }}
          >
            수수료
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={8} md={3.6}>
        <CssTextField
          placeholder="비어있음"
          value={tempCommission}
          onChange={handleCommissionChange}
          onBlur={handleCommissionBlur}
          onKeyDown={(e) => handleKeyDown(e, handleCommissionBlur)}
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end">USDT</InputAdornment>,
            readOnly:
              selectedJournal &&
              selectedJournal.exchangeMap &&
              Object.keys(selectedJournal.exchangeMap).length === 0
                ? mode === "READONLY"
                : true,
          }}
          disabled={
            selectedJournal &&
            selectedJournal.exchangeMap &&
            Object.keys(selectedJournal.exchangeMap).length === 0
              ? mode === "READONLY"
              : true
          }
        ></CssTextField>
      </Grid>
      {tradeType === "Sell" || tradeType === "PositionEnd" ? (
        <Grid item xs={4} md={2.4}>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start", // 수평 중앙 정렬
              alignItems: "center", // 수직 중앙 정렬
              height: "100%", // Grid 아이템의 전체 높이 사용
            }}
          >
            <MonetizationOnRoundedIcon
              style={{
                color: theme.palette.secondary.light,
                fontSize: 18,
                marginRight: 4,
              }}
            />
            <Typography
              style={{
                color: theme.palette.secondary.light,
                fontSize: 14,
                fontWeight: "500",
              }}
            >
              수익
            </Typography>
          </Box>
        </Grid>
      ) : null}

      {tradeType === "Sell" || tradeType === "PositionEnd" ? (
        <Grid item xs={8} md={3.6}>
          <CssTextField
            placeholder="비어있음"
            value={tempProfit}
            onChange={handleProfitChange}
            onBlur={handleProfitBlur}
            onKeyDown={(e) => handleKeyDown(e, handleProfitBlur)}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">USDT</InputAdornment>
              ),
              readOnly:
                selectedJournal &&
                selectedJournal.exchangeMap &&
                Object.keys(selectedJournal.exchangeMap).length === 0
                  ? mode === "READONLY"
                  : true,
            }}
            disabled={
              selectedJournal &&
              selectedJournal.exchangeMap &&
              Object.keys(selectedJournal.exchangeMap).length === 0
                ? mode === "READONLY"
                : true
            }
          ></CssTextField>
        </Grid>
      ) : null}

      {tradeType === "PositionStart" ? (
        <Grid item xs={4} md={2.4}>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start", // 수평 중앙 정렬
              alignItems: "center", // 수직 중앙 정렬
              height: "100%", // Grid 아이템의 전체 높이 사용
            }}
          >
            <BalanceOutlinedIcon
              style={{
                color: theme.palette.secondary.light,
                fontSize: 18,
                marginRight: 4,
              }}
            />
            <Typography
              style={{
                color: theme.palette.secondary.light,
                fontSize: 14,
                fontWeight: "500",
              }}
            >
              손익비
            </Typography>
          </Box>
        </Grid>
      ) : null}

      {tradeType === "PositionStart" ? (
        <Grid item xs={8} md={3.6}>
          <CssTextField
            placeholder="비어있음"
            value={profitLossRatio || ""}
            onChange={profitLossStateChange}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            disabled={true}
          ></CssTextField>
        </Grid>
      ) : null}
    </React.Fragment>
  );
};

// Seventh Row

export interface TagsRowProps {
  mode: EditTradeMode;
  theme: Theme;
  tradeType?: TradeType;
  tags: TagOptionType[] | undefined;
  setTags: React.Dispatch<React.SetStateAction<TagOptionType[] | undefined>>;
  selectedJournal?: IJournalTableData;
  allTags: IAllTags[];
}

export const TagsRow: React.FC<TagsRowProps> = ({
  mode,
  theme,
  tradeType,
  tags,
  setTags,
  allTags,
}) => {
  return (
    <React.Fragment>
      <Grid item xs={4} md={2.4}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start", // 수평 중앙 정렬
            alignItems: "center", // 수직 중앙 정렬
            height: "100%", // Grid 아이템의 전체 높이 사용
          }}
        >
          <LocalOfferOutlinedIcon
            style={{
              color: theme.palette.secondary.light,
              fontSize: 18,
              marginRight: 4,
            }}
          />
          <Typography
            style={{
              color: theme.palette.secondary.light,
              fontSize: 14,
              fontWeight: "500",
            }}
          >
            태그
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={8} md={9.6}>
        {/* <CssTextField placeholder="태그 검색..." fullWidth /> */}
        <CheckboxesTags
          mode={mode}
          options={allTags}
          tags={tags || []}
          setTags={setTags}
        />
      </Grid>
    </React.Fragment>
  );
};

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  // border: "1px solid", // 테두리 추가
  // borderColor: theme.palette.divider, // 테두리 색상 설정
}));

export type EditTradeMode = "EDIT" | "READONLY" | "CREATE";

export interface IEditTradeTemplate extends ICollapseTradeTableData {
  mode?: EditTradeMode;
  setMode?: (modeParam: EditTradeMode) => void;
}

const EditTradeTemplate: React.FC<IEditTradeTemplate> = ({
  id,
  groupId,
  mode,
  num,
  setMode,
  tradeType,
  orderId,
  tradeTime,
  exchange = "비트겟",
  symbol = "BTCUSDT",
  position,
  leverage,
  marketType = "USDT-FUTURES",
  entryAmount,
  entryPrice,
  stopLossPrice,
  takeProfitPrice,
  commission,
  profitLossRatio,
  allEntryAmount,
  allSize,
  tags,
  notes,
  link,
  minDate,
  maxDate,
  individualAvgEntryPrice,
  prevIndividualAvgEntryPrice,
  size,
  profit,
}) => {
  const theme = useTheme();

  const dispatch = useAppDispatch();
  const selectedJournal = useSelector(
    (state: RootState) => state.journalOverView.selectedJournalData
  );

  const selectedJournalType = useSelector(
    (state: RootState) => state.journalDetails.selectedJournalType
  );

  const allTags: IAllTags[] = useSelector((state: RootState) =>
    selectAllTags(state, selectedJournal.Jid)
  );

  const nodeRef = useRef(null); // nodeRef 추가

  const [positionDialogOpen, setPositionDialogOpen] = useState(false);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState(renderWithLineBreaks(""));
  const [dialogAction, setDialogAction] = useState<(() => void) | null>(null);
  const [dragging, setDragging] = useState<boolean>(false); // 드래그 여부 확인

  const [tradeTimeState, setTradeTimeState] = useState(dayjs(tradeTime));
  const [exchangeState, setExchangeState] = useState(exchange);
  const [symbolState, setSymbolState] = useState(symbol);
  const [marketTypeState, setMarketTypeState] = useState(marketType);
  const [positionState, setPositionState] = useState(position);
  const [leverageState, setLeverageState] = useState(leverage);
  const [entryAmountState, setEntryAmountState] = useState(entryAmount);
  const [stopLossPriceState, setStopLossPriceState] = useState(stopLossPrice);
  const [entryPriceState, setEntryPriceState] = useState(entryPrice);
  const [takeProfitPriceState, setTakeProfitPriceState] =
    useState(takeProfitPrice);
  const [commissionState, setCommissionState] = useState(commission);
  const [profitLossRatioState, setProfitLossRatioState] =
    useState(profitLossRatio);

  const [profitState, setProfitState] = useState(profit);

  const [tagsState, setTagsState] = useState(tags);

  const [notesState, setNotesState] = useState(notes);
  const [linkState, setLinkState] = useState(link);

  const [sizeState, setSizeState] = useState(size);
  const [allSizeState, setAllSizeState] = useState(allSize);

  let isBuyOrSell =
    tradeType === "PositionStart" || tradeType === "Buy"
      ? true
      : tradeType === "PositionEnd" || tradeType === "Sell"
      ? false
      : null;

  let changeAmountRatio = isBuyOrSell
    ? size === allSize
      ? size === 0
        ? 0
        : 100
      : Math.abs(round(((size || 0) / (allSize - size!)) * 100.0, 1))
    : size === allSize
    ? size === 0
      ? 0
      : Math.abs(round(((size || 0) / (allSize + size!)) * 100.0, 1))
    : Math.abs(round(((size || 0) / (allSize + size!)) * 100.0, 1));

  const [amountRatioState, setAmountRatioState] = useState(changeAmountRatio);

  const [expanded, setExpanded] = useState(false);
  const [backupData, setBackupData] = useState<ICollapseTradeTableData>({
    id,
    groupId,
    num,
    tradeType,
    orderId,
    tradeTime,
    exchange,
    symbol,
    position,
    leverage,
    marketType,
    entryAmount,
    size,
    entryPrice,
    stopLossPrice,
    takeProfitPrice,
    commission,
    profitLossRatio,
    allEntryAmount,
    allSize,
    tags,
    notes,
    link,
    profit,
  });

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleOpenPositionDialog = () => {
    setPositionDialogOpen(true);
  };

  const handleClosePositionDialog = (result: {
    position: string | undefined;
    leverage: number | string | undefined;
  }) => {
    setPositionDialogOpen(false);
    setLeverageState(result.leverage);
    setPositionState(result.position);
  };

  const handleOpenDeleteDialog = (message: string, action: () => void) => {
    setDialogMessage(message);
    setDialogAction(() => action);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleSave = async () => {
    const updatedData: ICollapseTradeTableData = {
      id,
      groupId,
      num,
      tradeType,
      orderId,
      tradeTime: tradeTimeState.valueOf(),
      exchange: exchangeState,
      symbol: symbolState,
      position: positionState,
      leverage: leverageState,
      marketType: marketTypeState,
      entryAmount: entryAmountState,
      entryPrice: entryPriceState,
      stopLossPrice: stopLossPriceState,
      takeProfitPrice: takeProfitPriceState,
      commission: commissionState,
      profitLossRatio: profitLossRatioState,
      tags: tagsState,
      notes: notesState,
      link: linkState,
      allEntryAmount, // 추가
      allSize: allSizeState,
      size: sizeState,
      profit: profitState,
    };

    setMode && setMode("READONLY");

    await dispatch(
      updateTrade({
        journalId: selectedJournal.Jid,
        updatedData: updatedData,
        journalType: selectedJournalType,
        shouldUpdateJournal:
          selectedJournal &&
          selectedJournal.exchangeMap &&
          Object.keys(selectedJournal.exchangeMap).length === 0,
      })
    )
      .then((result) => {
        if (result.meta.requestStatus === "fulfilled") {
          // Update 가능여부 체크 및 업데이트 성공
          setBackupData(updatedData);
          enqueueSnackbar(`거래 기록을 업데이트하였습니다.`, {
            variant: "info",
            autoHideDuration: 2000,
          });
        } else {
          console.log(result.payload);
          // 업데이트 실패, 경고 메시지 표시 및 백업
          handleCancel();

          dispatch(setEditDialogType("alert"));
          dispatch(setEditDialogOpen(true));
        }
      })
      .catch((error) => {
        // 업데이트 실패, 경고 메시지 표시 및 백업
        handleCancel();

        dispatch(setEditDialogType("alert"));
        dispatch(setEditDialogOpen(true));
      });
  };

  const handleCancel = () => {
    setTradeTimeState(dayjs(backupData.tradeTime));
    setExchangeState(backupData.exchange || "");
    setSymbolState(backupData.symbol || "");
    setMarketTypeState(backupData.marketType || "");
    setPositionState(backupData.position);
    setLeverageState(backupData.leverage);
    setEntryAmountState(backupData.entryAmount);
    setStopLossPriceState(backupData.stopLossPrice);
    setEntryPriceState(backupData.entryPrice);
    setTakeProfitPriceState(backupData.takeProfitPrice);
    setCommissionState(backupData.commission);
    setProfitLossRatioState(backupData.profitLossRatio);
    setTagsState(backupData.tags);
    setNotesState(backupData.notes);
    setLinkState(backupData.link);
    setSizeState(backupData.size);
    setAllSizeState(backupData.allSize);
    setAmountRatioState(changeAmountRatio);

    setMode && setMode("READONLY");
  };

  const handleDelete = async () => {
    if (tradeType === "PositionStart") {
      const message = renderWithLineBreaks(
        "이 기록을 제거하면 해당 거래 중인 기록 전체가 제거됩니다.\n삭제하시겠습니까?"
      ) as string;
      handleOpenDeleteDialog(message, async () => {
        if (id && groupId) {
          dispatch(
            deleteTrade({
              journalId: selectedJournal.Jid,
              id: id,
              groupId: groupId,
              tradeType: tradeType,
              journalType: selectedJournalType,
              shouldUpdateJournal:
                selectedJournal &&
                selectedJournal.exchangeMap &&
                Object.keys(selectedJournal.exchangeMap).length === 0,
            })
          )
            .then((result) => {
              if (result.meta.requestStatus === "fulfilled") {
                // 삭제 성공
                enqueueSnackbar(
                  `"${TradeTypeMap[tradeType]}" 데이터를 삭제하였습니다.`,
                  {
                    variant: "info",
                    autoHideDuration: 2000,
                  }
                );
              } else {
                // 삭제 실패
                enqueueSnackbar(
                  `"${TradeTypeMap[tradeType]}"가 삭제 되지 않았습니다.`,
                  {
                    variant: "error",
                    autoHideDuration: 3000,
                  }
                );
              }
            })
            .catch((error) => {
              // 삭제 실패
              enqueueSnackbar(
                `"${TradeTypeMap[tradeType]}"가 삭제 되지 않았습니다.`,
                {
                  variant: "error",
                  autoHideDuration: 3000,
                }
              );
            });
          dispatch(setSelectedJournalRowIndex(-1));
          dispatch(setSelectedJournalGroupId(""));
          dispatch(setEditDrawerOpen(false));
        }
      });
    } else if (tradeType === "PositionEnd") {
      const message = renderWithLineBreaks(
        "이 기록을 제거하면 해당 기록은 진행 중인 거래 기록으로 이동됩니다.\n삭제하시겠습니까?"
      ) as string;
      handleOpenDeleteDialog(message, async () => {
        if (selectedJournal && id && groupId) {
          dispatch(
            deleteTrade({
              journalId: selectedJournal.Jid,
              id: id,
              groupId: groupId,
              tradeType: tradeType,
              journalType: selectedJournalType,
              shouldUpdateJournal:
                selectedJournal &&
                selectedJournal.exchangeMap &&
                Object.keys(selectedJournal.exchangeMap).length === 0,
            })
          )
            .then((result) => {
              if (result.meta.requestStatus === "fulfilled") {
                enqueueSnackbar(`해당 거래 내역을 "OPEN" 으로 이동합니다.`, {
                  variant: "info",
                  autoHideDuration: 2000,
                });
                // 삭제 성공
                enqueueSnackbar(
                  `"${TradeTypeMap[tradeType]}" 데이터를 삭제하였습니다.`,
                  {
                    variant: "info",
                    autoHideDuration: 2000,
                  }
                );
                dispatch(setChangedGroupId(groupId));
                dispatch(setSelectedJournalType("OPEN"));
              } else {
                // 삭제 실패
                enqueueSnackbar(
                  `"${TradeTypeMap[tradeType]}" 삭제를 취소하였습니다.`,
                  {
                    variant: "info",
                    autoHideDuration: 2000,
                  }
                );
              }
            })
            .catch((error) => {
              // 삭제 실패
              enqueueSnackbar(
                `"${TradeTypeMap[tradeType]}" 삭제를 취소하였습니다.`,
                {
                  variant: "info",
                  autoHideDuration: 2000,
                }
              );
            });
        }
      });
    } else {
      if (selectedJournal && id && groupId) {
        dispatch(
          deleteTrade({
            journalId: selectedJournal.Jid,
            id: id,
            groupId: groupId,
            tradeType: tradeType,
            journalType: selectedJournalType,
            shouldUpdateJournal:
              selectedJournal &&
              selectedJournal.exchangeMap &&
              Object.keys(selectedJournal.exchangeMap).length === 0,
          })
        )
          .then((result) => {
            if (result.meta.requestStatus === "fulfilled") {
              // 삭제 성공
              enqueueSnackbar(
                `"${TradeTypeMap[tradeType]}" 데이터를 삭제하였습니다.`,
                {
                  variant: "info",
                  autoHideDuration: 2000,
                }
              );
            } else {
              // 삭제 실패
              enqueueSnackbar(
                `"${TradeTypeMap[tradeType]}"가 삭제 되지 않았습니다.`,
                {
                  variant: "error",
                  autoHideDuration: 3000,
                }
              );
            }
          })
          .catch((error) => {
            // 삭제 실패
            enqueueSnackbar(
              `"${TradeTypeMap[tradeType]}"가 삭제 되지 않았습니다.`,
              {
                variant: "error",
                autoHideDuration: 3000,
              }
            );
          });
      }
    }
  };

  useEffect(() => {
    if (entryPriceState && stopLossPriceState && takeProfitPriceState) {
      const totalCommission = commissionState ? commissionState * 2 : 0; // 진입과 청산 시 두 번의 수수료를 고려합니다.

      const profit = takeProfitPriceState - entryPriceState - totalCommission;
      const loss = entryPriceState - stopLossPriceState + totalCommission;

      if (loss === 0) {
        return;
      }

      // 손익비를 계산합니다.
      const ratio = profit / loss;

      setProfitLossRatioState(`${formatNumber(ratio)}:1`);
    }
  }, [
    entryPriceState,
    stopLossPriceState,
    takeProfitPriceState,
    commissionState,
  ]);

  return (
    <Box sx={{ flexGrow: 1, paddingTop: 0 }}>
      <Grid container spacing={1}>
        <Grid container item spacing={1}>
          <TradeTimeRow
            mode={mode || "READONLY"}
            theme={theme}
            tradeTime={tradeTimeState}
            setTradeTime={setTradeTimeState}
            minDate={minDate}
            maxDate={maxDate}
            selectedJournal={selectedJournal}
          />
        </Grid>
        {tradeType === "PositionStart" ? (
          <Grid container item spacing={1}>
            <ExchangeSymbolRow
              mode={mode || "READONLY"}
              theme={theme}
              tradeType={tradeType}
              exchange={exchangeState}
              symbol={symbolState}
              setExchange={setExchangeState}
              setSymbol={setSymbolState}
              selectedJournal={selectedJournal}
            />
          </Grid>
        ) : null}

        {tradeType === "PositionStart" ? (
          <Grid container item spacing={1}>
            <PositionMarketTypeRow
              mode={mode || "READONLY"}
              theme={theme}
              tradeType={tradeType}
              position={positionState}
              leverage={leverageState}
              marketType={marketTypeState}
              dialogOpen={positionDialogOpen}
              setMarketType={setMarketTypeState}
              handleCloseDialog={handleClosePositionDialog}
              handleOpenDialog={handleOpenPositionDialog}
              selectedJournal={selectedJournal}
            />
          </Grid>
        ) : null}
        <Grid container item spacing={1}>
          <EntryPriceAllEntrySizeRow
            mode={mode || "READONLY"}
            theme={theme}
            tradeType={tradeType}
            entryPrice={entryPriceState}
            entryAmount={entryAmountState}
            allSize={allSizeState}
            size={sizeState}
            position={positionState}
            prevIndividualAvgEntryPrice={prevIndividualAvgEntryPrice}
            backupAllSize={backupData.allSize}
            backupSize={backupData.size}
            backupRatio={changeAmountRatio}
            symbol={symbolState}
            dragging={dragging}
            amountRatio={amountRatioState}
            setEntryPrice={setEntryPriceState}
            setEntryAmount={setEntryAmountState}
            setAllSize={setAllSizeState}
            setSize={setSizeState}
            setAmountRatio={setAmountRatioState}
            setProfit={setProfitState}
            selectedJournal={selectedJournal}
          />
        </Grid>
        <Grid container item spacing={1}>
          <EntryAmountSizeRow
            mode={mode || "READONLY"}
            theme={theme}
            tradeType={tradeType}
            entryPrice={entryPriceState}
            entryAmount={entryAmountState}
            allSize={allSizeState}
            size={sizeState}
            dragging={dragging}
            backupAllSize={backupData.allSize}
            backupSize={backupData.size}
            backupRatio={changeAmountRatio}
            symbol={symbolState}
            position={positionState}
            amountRatio={amountRatioState}
            prevIndividualAvgEntryPrice={prevIndividualAvgEntryPrice}
            setEntryPrice={setEntryPriceState}
            setEntryAmount={setEntryAmountState}
            setAllSize={setAllSizeState}
            setSize={setSizeState}
            setProfit={setProfitState}
            setAmountRatio={setAmountRatioState}
            selectedJournal={selectedJournal}
          />
        </Grid>
        <Grid container item spacing={1}>
          <EntryAmountSliderRow
            mode={mode || "READONLY"}
            theme={theme}
            tradeType={tradeType}
            entryPrice={entryPriceState}
            allSize={allSizeState}
            backupAllSize={backupData.allSize}
            backupSize={backupData.size}
            dragging={dragging}
            amountRatio={amountRatioState}
            position={positionState}
            prevIndividualAvgEntryPrice={prevIndividualAvgEntryPrice}
            setAmountRatio={setAmountRatioState}
            setSize={setSizeState}
            setAllSize={setAllSizeState}
            setEntryAmount={setEntryAmountState}
            setDragging={setDragging}
            setProfit={setProfitState}
            selectedJournal={selectedJournal}
          />
        </Grid>
        {tradeType === "PositionStart" ? (
          <Grid container item spacing={1}>
            <StopLossPriceTakeProfitPriceRow
              mode={mode || "READONLY"}
              theme={theme}
              tradeType={tradeType}
              stopLossPrice={stopLossPriceState}
              takeProfitPrice={takeProfitPriceState}
              setStopLossPrice={setStopLossPriceState}
              setTakeProfitPrice={setTakeProfitPriceState}
              selectedJournal={selectedJournal}
            />
          </Grid>
        ) : null}
        <Grid container item spacing={1}>
          <CommissionProfitLossRatioRow
            mode={mode || "READONLY"}
            theme={theme}
            tradeType={tradeType}
            commission={commissionState}
            profitLossRatio={profitLossRatioState}
            profit={profitState}
            setCommission={setCommissionState}
            setProfitLossRatio={setProfitLossRatioState}
            setProfit={setProfitState}
            selectedJournal={selectedJournal}
          />
        </Grid>
        <Grid container item spacing={1}>
          <TagsRow
            mode={mode || "READONLY"}
            theme={theme}
            tradeType={tradeType}
            tags={tagsState}
            setTags={setTagsState}
            allTags={allTags}
          />
        </Grid>
        <Grid container item spacing={1}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Divider style={{ flex: 1 }} />
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
            <Divider style={{ flex: 1 }} />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Collapse
              in={expanded}
              timeout="auto"
              unmountOnExit
              style={{ width: "100%" }}
            >
              <CustomEditField
                mode={mode || "READONLY"}
                notes={notesState || ""}
                link={linkState || ""}
                setNotes={setNotesState}
                setLink={setLinkState}
              />
            </Collapse>
          </Box>
        </Grid>
        <CSSTransition
          in={mode === "EDIT" || mode === "CREATE"}
          timeout={300}
          classNames="fade"
          unmountOnExit
          nodeRef={nodeRef}
        >
          <Grid container item spacing={1} ref={nodeRef}>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "right",
                gap: 2,
              }}
            >
              <Button
                variant="contained"
                sx={{ bgcolor: theme.palette.primary.main, color: "white" }}
                onClick={() => {
                  handleSave();
                }}
              >
                저장
              </Button>
              {selectedJournal.exchangeMap &&
              Object.keys(selectedJournal.exchangeMap).length === 0 ? (
                <Button
                  variant="outlined"
                  sx={{ color: theme.palette.primary.main }}
                  onClick={handleDelete}
                >
                  삭제
                </Button>
              ) : null}

              <Button
                variant="outlined"
                sx={{ color: theme.palette.primary.main }}
                onClick={() => {
                  handleCancel();
                }}
              >
                취소
              </Button>
            </Box>
          </Grid>
        </CSSTransition>
      </Grid>
      <Dialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">삭제 확인</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            취소
          </Button>
          <Button
            onClick={() => {
              dialogAction && dialogAction();
              handleCloseDeleteDialog();
            }}
            color="primary"
            autoFocus
          >
            삭제
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EditTradeTemplate;
