import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { TradeTimeProps } from "../pages/Journal/Analysis/types";
import dayjs, { Dayjs } from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { TIME_FORMAT } from "../utils/utils";
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

interface TradeTimeFilterProps extends TradeTimeProps {
  handleSetFilter?: (key: string, value: any) => void;
  handleRemoveFilter?: (key: string) => void;
  type?: string;
}

const CustomDateTimeRangePicker: React.FC<TradeTimeFilterProps> = (props) => {
  const theme = useTheme();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MobileDateTimePicker
        disabled={props.mode === "READONLY" ? true : false}
        value={props.tradeTime || null}
        onAccept={(newValue) => {
          if (newValue) {
            const newValueISOString = newValue.toISOString();

            props.setTradeTime(newValue);
            props.handleSetFilter &&
              props.type &&
              props.handleSetFilter(props.type, (tradeTime: string) => {
                const tradeTimeDate = dayjs(tradeTime);
                return props.type === "startTradeTime"
                  ? tradeTimeDate.isSameOrAfter(newValueISOString)
                  : tradeTimeDate.isSameOrBefore(newValueISOString) ||
                      tradeTimeDate.isSameOrAfter(newValueISOString);
              });
          }
        }}
        format={TIME_FORMAT}
        minDateTime={props.minDate && props.minDate}
        maxDateTime={props.maxDate && props.maxDate}
        readOnly={props.mode === "READONLY" ? true : false}
        views={["year", "month", "day", "hours", "minutes", "seconds"]}
        slotProps={{
          textField: {
            placeholder: "비어있음",
            sx: {
              "& .MuiInputBase-root": {
                padding: 0,
              },
              "& label.Mui-focused": {
                color: "#A0AAB4",
                transition: "color 0.3s ease", // transition 추가
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#B2BAC2",
                transition: "border-bottom-color 0.3s ease", // transition 추가
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "transparent", // 기본적으로 border를 투명하게 설정
                  borderWidth: "1.2px", // 기본 border 두께 설정
                  borderRadius: "2px", // 기본 borderRadius 설정
                  color: theme.palette.secondary.main,
                  transition: "border-color 0.3s ease, border-width 0.3s ease", // transition 추가
                },
                "&:hover fieldset": {
                  borderColor: "#B2BAC2", // 호버 시 border 색상 설정
                  borderWidth: "1.2px", // 호버 시 border 두께 설정
                  borderRadius: "2px", // 기본 borderRadius 설정
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#6F7E8C", // 포커스 시 border 색상 설정
                  borderWidth: "1.2px", // 포커스 시 border 두께 설정
                  borderRadius: "2px", // 기본 borderRadius 설정
                },
                "&.Mui-disabled fieldset": {
                  borderColor: "transparent", // disabled 상태에서 테두리 보이지 않도록 설정
                  backgroundColor: "rgba(160, 170, 180, 0.1)",
                },
              },
              "& .MuiInputBase-input": {
                fontSize: "14px", // 폰트 크기 변경
                padding: 1,
                color: theme.palette.secondary.main,
              },
            },
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default CustomDateTimeRangePicker;
