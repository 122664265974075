import React, { useState, useRef, useEffect } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  useTheme,
  styled,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import CloseIcon from "@mui/icons-material/Close";
import { EditTradeMode } from "../pages/Journal/DetailJournal/EditJournal/EditTradeTemplate";

const EditableDiv = styled("div")(({ theme }) => ({
  padding: "6px",
  outline: "none",
  whiteSpace: "pre-wrap",
  height: "100%",
  fontSize: "14px",
  overflowY: "auto",
  "&:empty:before": {
    content: "attr(data-placeholder)",
    color: theme.palette.secondary.light,
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  border: "1px solid transparent",
  borderRadius: 2,
  marginLeft: 20,
  marginRight: 20,
  padding: theme.spacing(1),
  minHeight: 200,
  backgroundColor: "#F1F4F8",
  overflowY: "auto",
  transition: "border-color 0.3s ease",
  "&:hover": {
    borderColor: "#B2BAC2",
  },
  "&:focus-within": {
    borderColor: "#6F7E8C",
  },
}));

export interface ICustomEditField {
  mode: EditTradeMode;
  notes: string;
  link: string;
  setNotes: React.Dispatch<React.SetStateAction<string | undefined>>;
  setLink: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const CustomEditField: React.FC<ICustomEditField> = ({
  mode,
  notes,
  link,
  setNotes,
  setLink,
}) => {
  const theme = useTheme();
  const [history, setHistory] = useState([notes]);
  const [historyIndex, setHistoryIndex] = useState(0);
  const textAreaRef = useRef<HTMLDivElement>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (textAreaRef.current && textAreaRef.current.innerHTML !== notes) {
      textAreaRef.current.innerHTML = notes;
    }
  }, [notes]);

  const handleNotesChange = () => {
    if (textAreaRef.current) {
      const value = textAreaRef.current.innerHTML;
      setNotes(value);
      const newHistory = history.slice(0, historyIndex + 1);
      setHistory([...newHistory, value]);
      setHistoryIndex(newHistory.length);
    }
  };

  const handleLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLink(event.target.value);
  };

  const handleImageClick = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    // 이미지 로드
    if (link) {
      const img = new Image();
      img.onload = () => setImageSrc(link);
      img.onerror = () => setImageSrc(null);
      img.src = link;
    } else {
      setImageSrc(null);
    }
  }, [link]);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        gap: 2,
        bgcolor: "#f7fafc", // Light grey background color
        borderRadius: 2,
        marginBottom: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          paddingTop: 0,
          paddingLeft: 0,
          paddingRight: 0,
          paddingBottom: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 0,
            //borderBottom: "1px solid #B2BAC2",
            paddingTop: 2,
            paddingLeft: 2,
            paddingRight: 2,
            marginBottom: 1,
          }}
        >
          <Typography sx={{ color: "#5A6F82", fontSize: 14 }}>
            세부 기록
          </Typography>
        </Box>
        <StyledBox>
          <EditableDiv
            contentEditable={mode !== "READONLY" ? true : false}
            data-placeholder={
              mode !== "READONLY"
                ? "일지 내용은 여기에 작성해주세요."
                : "우측 상단 편집 버튼을 클릭하여 작성할 수 있습니다."
            }
            ref={textAreaRef}
            onInput={handleNotesChange} // handleNotesChange 함수 연결
          />
        </StyledBox>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          paddingLeft: 2,
          paddingRight: 2,
          marginBottom: 2,
        }}
      >
        <Typography sx={{ color: "#5A6F82", fontSize: 14 }}>
          트레이딩 뷰 캡쳐 링크
        </Typography>
        <TextField
          placeholder={
            mode !== "READONLY"
              ? "링크를 입력해주세요."
              : "우측 상단 편집 버튼을 클릭하여 작성할 수 있습니다."
          }
          value={link}
          onChange={handleLinkChange}
          variant="outlined"
          fullWidth
          InputProps={{
            readOnly: mode === "READONLY" ? true : false,
          }}
          sx={{
            bgcolor: "#F1F4F8",
            "& label.Mui-focused": {
              color: "#A0AAB4",
              transition: "color 0.3s ease", // transition 추가
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "#B2BAC2",
              transition: "border-bottom-color 0.3s ease", // transition 추가
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "transparent", // 기본적으로 border를 투명하게 설정
                borderWidth: "1.2px", // 기본 border 두께 설정
                borderRadius: "2px", // 기본 borderRadius 설정
                color: theme.palette.secondary.main,
                transition: "border-color 0.3s ease, border-width 0.3s ease", // transition 추가
              },
              "&:hover fieldset": {
                borderColor: "#B2BAC2", // 호버 시 border 색상 설정
                borderWidth: "1.2px", // 호버 시 border 두께 설정
                borderRadius: "2px", // 기본 borderRadius 설정
              },
              "&.Mui-focused fieldset": {
                borderColor: "#6F7E8C", // 포커스 시 border 색상 설정
                borderWidth: "1.2px", // 포커스 시 border 두께 설정
                borderRadius: "2px", // 기본 borderRadius 설정
              },
            },
            "& .MuiInputBase-input": {
              fontSize: "14px", // 폰트 크기 변경
              color: theme.palette.secondary.main,
              "&::placeholder": {
                color: theme.palette.secondary.light, // placeholder 글자색 설정
                opacity: 1, // placeholder 글자색의 투명도 설정
              },
            },
            "& .MuiInputLabel-root": {
              fontSize: "14px", // 라벨 폰트 크기 변경
              color: theme.palette.secondary.main,
            },
          }}
        />
        {imageSrc && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: 2,
              marginBottom: 2,
            }}
          >
            <img
              src={imageSrc}
              alt="Embedded"
              style={{ maxWidth: "100%", height: "auto" }}
              onClick={handleImageClick}
            />
          </Box>
        )}
      </Box>
      <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="lg">
        <DialogContent sx={{ position: "relative", padding: 0 }}>
          <IconButton
            onClick={handleCloseDialog}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: theme.palette.grey[500],
              zIndex: 1,
            }}
          >
            <CloseIcon />
          </IconButton>
          {imageSrc && (
            <TransformWrapper>
              <TransformComponent>
                <img
                  src={imageSrc}
                  alt="Embedded"
                  style={{ width: "100%", height: "auto" }}
                />
              </TransformComponent>
            </TransformWrapper>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default CustomEditField;
