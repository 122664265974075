import * as React from "react";
import { ReactNode } from "react";
import { useTheme, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/StepButton";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";

import { BorderBottom } from "@mui/icons-material";
import { Collapse, Typography, useMediaQuery } from "@mui/material";
import {
  fadeIn,
  fadeInDown,
  fadeOut,
  renderWithLineBreaks,
} from "../utils/utils";
import {
  GenDetailsNode,
  IGenDetailsNode,
} from "../pages/Journal/CommonTableNode";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: "bold",
    textAlign: "center",
    fontSize: 12,
    color: theme.palette.secondary.light,
    borderBottom: `1px solid ${theme.palette.divider}`,
    lineHeight: "1.5", // 줄 간격 조정
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    textAlign: "center",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const ExpandedStyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: "bold",
    textAlign: "center",
    fontSize: 12,
    color: "white",
    borderBottom: `1px solid ${theme.palette.divider}`,
    lineHeight: "1.5", // 줄 간격 조정
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    textAlign: "center",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const StyledTablePageRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    // backgroundColor: theme.palette.action.hover, // Hover 색상 적용
    backgroundColor: `rgba(72,128,200, 0.1)`,
  },
  animation: `${fadeInDown} 0.3s ease-in`,
  "&.fade-out": {
    animation: `${fadeOut} 0.3s ease-out`,
  },
}));

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.0 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export interface ICustomTable {
  rows: ReactNode[][];
  headerNames: string[];
  isExpanded?: boolean;
  expandedTableData?: ReactNode[][][];
  expandedTableHeaderNames?: string[];
  onRowDoubleClick?: (rowIndex: number) => void;
  noPagenation?: boolean;
  defaultRowsPerPage?: number;
}

const CustomTable: React.FC<ICustomTable> = ({
  rows,
  headerNames,
  isExpanded,
  expandedTableData,
  expandedTableHeaderNames,
  onRowDoubleClick,
  noPagenation,
  defaultRowsPerPage,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage || 5);

  const [openStates, setOpenStates] = React.useState(
    rows.map(() => false) // 각 row에 대한 open 상태를 초기화
  );

  React.useEffect(() => {
    setOpenStates(rows.map(() => false));
  }, [rows]);

  const [isLongPress, setIsLongPress] = React.useState(false);
  const timeoutRef = React.useRef<number | null>(null);

  const handleTouchStart = (index: number) => {
    timeoutRef.current = window.setTimeout(() => {
      setIsLongPress(true);
      onRowDoubleClick && onRowDoubleClick(index); // 롱 터치 처리
    }, 500); // 500ms 이상 터치 시 롱 터치로 처리
  };

  const handleTouchEnd = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }

    setIsLongPress(false);
  };

  const headerCount = headerNames.length;
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowDoubleClick = (rowIndex: number) => {
    if (onRowDoubleClick) {
      onRowDoubleClick(rowIndex);
    }
  };

  const toggleOpen = (index: number) => {
    setOpenStates((prevStates) =>
      prevStates.map((state, i) => (i === index ? !state : state))
    );
  };

  const isGenDetailsNode = (
    element: any
  ): element is React.ReactElement<IGenDetailsNode> => {
    return element.type === GenDetailsNode;
  };

  return (
    <TableContainer component={Paper} sx={{ boxShadow: theme.shadows[0] }}>
      <Table sx={{ width: "100%" }} aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            {headerNames.map((header, index) => (
              <StyledTableCell key={index}>
                {renderWithLineBreaks(header ?? header)}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map((row, rowIndex) => (
            <React.Fragment key={rowIndex}>
              <StyledTableRow
                key={`${rowIndex}_main`}
                onDoubleClick={() =>
                  handleRowDoubleClick(page * rowsPerPage + rowIndex)
                }
                onTouchStart={() =>
                  matches
                    ? handleTouchStart(page * rowsPerPage + rowIndex)
                    : undefined
                } // 터치 시작 시
                onTouchEnd={matches ? handleTouchEnd : undefined} // 터치 끝났을 때
                onTouchMove={matches ? handleTouchEnd : undefined} // 슬라이드 중에도 롱 터치 취소
                // onTouchEndCapture={() =>
                //   matches
                //     ? handleRowDoubleClick(page * rowsPerPage + rowIndex)
                //     : null
                // }
              >
                {row.map((child, colIndex) => (
                  <StyledTableCell
                    sx={{ paddingTop: 3, paddingBottom: 3 }}
                    key={`${rowIndex}_${colIndex}`}
                  >
                    {isGenDetailsNode(child)
                      ? React.cloneElement(child, {
                          open: openStates[rowIndex],
                          setOpen: () => toggleOpen(rowIndex),
                        })
                      : child}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
              <TableRow key={`${rowIndex}_collapse`}>
                <TableCell
                  style={{
                    paddingBottom:
                      isExpanded &&
                      expandedTableData &&
                      expandedTableData[rowIndex] &&
                      openStates[rowIndex]
                        ? 10
                        : 0,
                    paddingTop:
                      isExpanded &&
                      expandedTableData &&
                      expandedTableData[rowIndex] &&
                      openStates[rowIndex]
                        ? 10
                        : 0,
                    borderBottom: 0,
                  }}
                  colSpan={headerNames.length}
                >
                  <Collapse
                    in={isExpanded ? openStates[rowIndex] : false}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Box sx={{ margin: 1 }}>
                      <Table size="small" aria-label="expandedTable">
                        <TableHead>
                          <TableRow>
                            {expandedTableHeaderNames &&
                              expandedTableData &&
                              expandedTableData[rowIndex] &&
                              expandedTableHeaderNames.map((header, index) => (
                                <ExpandedStyledTableCell
                                  key={`${index}_collapse_header`}
                                  sx={{
                                    backgroundColor:
                                      theme.palette.secondary.light,
                                  }}
                                >
                                  {renderWithLineBreaks(header ?? header)}
                                </ExpandedStyledTableCell>
                              ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {expandedTableData &&
                            expandedTableData[page * rowsPerPage + rowIndex] &&
                            expandedTableData[
                              page * rowsPerPage + rowIndex
                            ].map((expandedRow, expadnedRowIndex) => (
                              <StyledTableRow
                                key={`${expadnedRowIndex}_collaspe_row`}
                                onDoubleClick={() =>
                                  handleRowDoubleClick(rowIndex)
                                }
                              >
                                {expandedRow.map(
                                  (expandedCol, expadnedColIndex) => (
                                    <ExpandedStyledTableCell
                                      sx={{ p: 1 }}
                                      key={`${expadnedRowIndex}_${expadnedColIndex}_collapse_col`}
                                    >
                                      {expandedCol}
                                    </ExpandedStyledTableCell>
                                  )
                                )}
                              </StyledTableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 56.8 * emptyRows }}>
              <StyledTableCell colSpan={headerCount} />
            </TableRow>
          )}
        </TableBody>
        {noPagenation && noPagenation ? null : (
          <TableFooter>
            <StyledTablePageRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={headerCount}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage="페이지 당"
                page={page}
                slotProps={{
                  select: {
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  },
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </StyledTablePageRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
};

export default CustomTable;
