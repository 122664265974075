import React, { FC } from "react";
import {
  Tooltip,
  TooltipProps,
  tooltipClasses,
  IconButton,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import InfoIcon from "@mui/icons-material/Info";

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
    whiteSpace: "pre-line", // 줄바꿈을 인식하도록 설정
  },
});

interface CustomTooltipProps extends TooltipProps {
  // 추가적인 props를 정의할 수 있습니다.
}

const CustomTooltipIcon: FC<CustomTooltipProps> = ({ children, ...props }) => {
  return <StyledTooltip {...props}>{children}</StyledTooltip>;
};

export default CustomTooltipIcon;
