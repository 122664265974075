// src/theme.ts
import { createTheme } from "@mui/material/styles";
import { blue, grey } from "@mui/material/colors";

// const theme = createTheme({
//   palette: {
//     mode: "light",
//     primary: {
//       light: "#6c99ff",
//       main: "#4880FF",
//       dark: "#3259b2",
//       contrastText: "#000000",
//     },
//     //font color
//     secondary: {
//       light: "#A0AEC0",
//       main: "#2D3748",
//       dark: "#000000",
//       contrastText: "#FFFFFF",
//     },
//     background: {
//       default: "white",
//     },
//     error: {
//       main: "#F56565",
//     },
//   },
//   typography: {
//     fontFamily: "Noto Sans KR, sans-serif",
//     fontSize: 14,
//     fontWeightRegular: 400,
//     fontWeightMedium: 500,
//     fontWeightBold: 700,
//     h1: {
//       fontFamily: "Noto Sans KR",
//       fontSize: "2.5rem",
//       fontWeight: 500,
//       lineHeight: 1.2,
//     },
//     h2: {
//       fontFamily: "Noto Sans KR",
//       fontSize: "2rem",
//       fontWeight: 500,
//       lineHeight: 1.3,
//     },
//     h3: {
//       fontFamily: "Noto Sans KR",
//       fontSize: "1.75rem",
//       fontWeight: 500,
//       lineHeight: 1.4,
//     },
//     h4: {
//       fontFamily: "Noto Sans KR",
//       fontSize: "1.5rem",
//       fontWeight: 500,
//       lineHeight: 1.5,
//     },
//     h5: {
//       fontFamily: "Noto Sans KR",
//       fontSize: "1.25rem",
//       fontWeight: 500,
//       lineHeight: 1.6,
//     },
//     h6: {
//       fontFamily: "Noto Sans KR",
//       fontSize: "1rem",
//       fontWeight: 500,
//       lineHeight: 1.7,
//     },
//     body1: {
//       fontFamily: "Noto Sans KR",
//       fontSize: "1rem",
//       fontWeight: 400,
//       lineHeight: 1.6,
//     },
//     body2: {
//       fontFamily: "Noto Sans KR",
//       fontSize: "0.875rem",
//       fontWeight: 400,
//       lineHeight: 1.6,
//     },
//     button: {
//       fontFamily: "Noto Sans KR",
//       fontSize: "0.875rem",
//       fontWeight: 500,
//       lineHeight: 1.6,
//       textTransform: "uppercase",
//     },
//     caption: {
//       fontFamily: "Noto Sans KR",
//       fontSize: "0.75rem",
//       fontWeight: 400,
//       lineHeight: 1.4,
//     },
//     overline: {
//       fontFamily: "Noto Sans KR",
//       fontSize: "0.625rem",
//       fontWeight: 400,
//       lineHeight: 1.4,
//       textTransform: "uppercase",
//     },
//     subtitle1: {
//       fontFamily: "Noto Sans KR",
//       fontSize: "1rem",
//       fontWeight: 400,
//       lineHeight: 1.6,
//     },
//     subtitle2: {
//       fontFamily: "Noto Sans KR",
//       fontSize: "0.875rem",
//       fontWeight: 500,
//       lineHeight: 1.6,
//     },
//   },
//   // typography: {
//   //   fontFamily: "NanumSquareAc, sans-serif",
//   //   fontSize: 14,
//   //   fontWeightRegular: 400,
//   //   fontWeightMedium: 500,
//   //   fontWeightBold: 700,
//   //   h1: {
//   //     fontFamily: "NanumSquareAc",
//   //     fontSize: "2.5rem",
//   //     fontWeight: 500,
//   //     lineHeight: 1.2,
//   //   },
//   //   h2: {
//   //     fontFamily: "NanumSquareAc",
//   //     fontSize: "2rem",
//   //     fontWeight: 500,
//   //     lineHeight: 1.3,
//   //   },
//   //   h3: {
//   //     fontFamily: "NanumSquareAc",
//   //     fontSize: "1.75rem",
//   //     fontWeight: 500,
//   //     lineHeight: 1.4,
//   //   },
//   //   h4: {
//   //     fontFamily: "NanumSquareAc",
//   //     fontSize: "1.5rem",
//   //     fontWeight: 500,
//   //     lineHeight: 1.5,
//   //   },
//   //   h5: {
//   //     fontFamily: "NanumSquareAc",
//   //     fontSize: "1.25rem",
//   //     fontWeight: 500,
//   //     lineHeight: 1.6,
//   //   },
//   //   h6: {
//   //     fontFamily: "NanumSquareAc",
//   //     fontSize: "1rem",
//   //     fontWeight: 500,
//   //     lineHeight: 1.7,
//   //   },
//   //   body1: {
//   //     fontFamily: "NanumSquareAc",
//   //     fontSize: "1rem",
//   //     fontWeight: 400,
//   //     lineHeight: 1.6,
//   //   },
//   //   body2: {
//   //     fontFamily: "NanumSquareAc",
//   //     fontSize: "0.875rem",
//   //     fontWeight: 400,
//   //     lineHeight: 1.6,
//   //   },
//   //   button: {
//   //     fontFamily: "NanumSquareAc",
//   //     fontSize: "0.875rem",
//   //     fontWeight: 500,
//   //     lineHeight: 1.6,
//   //     textTransform: "uppercase",
//   //   },
//   //   caption: {
//   //     fontFamily: "NanumSquareAc",
//   //     fontSize: "0.75rem",
//   //     fontWeight: 400,
//   //     lineHeight: 1.4,
//   //   },
//   //   overline: {
//   //     fontFamily: "NanumSquareAc",
//   //     fontSize: "0.625rem",
//   //     fontWeight: 400,
//   //     lineHeight: 1.4,
//   //     textTransform: "uppercase",
//   //   },
//   //   subtitle1: {
//   //     fontFamily: "NanumSquareAc",
//   //     fontSize: "1rem",
//   //     fontWeight: 400,
//   //     lineHeight: 1.6,
//   //   },
//   //   subtitle2: {
//   //     fontFamily: "NanumSquareAc",
//   //     fontSize: "0.875rem",
//   //     fontWeight: 500,
//   //     lineHeight: 1.6,
//   //   },
//   // },
// });

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 2480,
      xl: 3840,
    },
  },
  palette: {
    mode: "light",
    primary: {
      light: "#6c99ff",
      main: "#4880FF",
      dark: "#3259b2",
      contrastText: "#000000",
    },
    //font color
    secondary: {
      light: "#A0AEC0",
      main: "#2D3748",
      dark: "#000000",
      contrastText: "#FFFFFF",
    },
    background: {
      default: "white",
    },
    error: {
      main: "#F56565",
    },
  },
  typography: {
    fontFamily: "Noto Sans KR, sans-serif",
    fontSize: 14,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily: "Noto Sans KR",
      fontSize: "2.5rem",
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h2: {
      fontFamily: "Noto Sans KR",
      fontSize: "2rem",
      fontWeight: 500,
      lineHeight: 1.3,
    },
    h3: {
      fontFamily: "Noto Sans KR",
      fontSize: "1.75rem",
      fontWeight: 500,
      lineHeight: 1.4,
    },
    h4: {
      fontFamily: "Noto Sans KR",
      fontSize: "1.5rem",
      fontWeight: 500,
      lineHeight: 1.5,
    },
    h5: {
      fontFamily: "Noto Sans KR",
      fontSize: "1.25rem",
      fontWeight: 500,
      lineHeight: 1.6,
    },
    h6: {
      fontFamily: "Noto Sans KR",
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: 1.7,
    },
    body1: {
      fontFamily: "Noto Sans KR",
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.6,
    },
    body2: {
      fontFamily: "Noto Sans KR",
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: 1.6,
    },
    button: {
      fontFamily: "Noto Sans KR",
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: 1.6,
      textTransform: "uppercase",
    },
    caption: {
      fontFamily: "Noto Sans KR",
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: 1.4,
    },
    overline: {
      fontFamily: "Noto Sans KR",
      fontSize: "0.625rem",
      fontWeight: 400,
      lineHeight: 1.4,
      textTransform: "uppercase",
    },
    subtitle1: {
      fontFamily: "Noto Sans KR",
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.6,
    },
    subtitle2: {
      fontFamily: "Noto Sans KR",
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: 1.6,
    },
  },
  // typography: {
  //   fontFamily: "NanumSquareAc, sans-serif",
  //   fontSize: 14,
  //   fontWeightRegular: 400,
  //   fontWeightMedium: 500,
  //   fontWeightBold: 700,
  //   h1: {
  //     fontFamily: "NanumSquareAc",
  //     fontSize: "2.5rem",
  //     fontWeight: 500,
  //     lineHeight: 1.2,
  //   },
  //   h2: {
  //     fontFamily: "NanumSquareAc",
  //     fontSize: "2rem",
  //     fontWeight: 500,
  //     lineHeight: 1.3,
  //   },
  //   h3: {
  //     fontFamily: "NanumSquareAc",
  //     fontSize: "1.75rem",
  //     fontWeight: 500,
  //     lineHeight: 1.4,
  //   },
  //   h4: {
  //     fontFamily: "NanumSquareAc",
  //     fontSize: "1.5rem",
  //     fontWeight: 500,
  //     lineHeight: 1.5,
  //   },
  //   h5: {
  //     fontFamily: "NanumSquareAc",
  //     fontSize: "1.25rem",
  //     fontWeight: 500,
  //     lineHeight: 1.6,
  //   },
  //   h6: {
  //     fontFamily: "NanumSquareAc",
  //     fontSize: "1rem",
  //     fontWeight: 500,
  //     lineHeight: 1.7,
  //   },
  //   body1: {
  //     fontFamily: "NanumSquareAc",
  //     fontSize: "1rem",
  //     fontWeight: 400,
  //     lineHeight: 1.6,
  //   },
  //   body2: {
  //     fontFamily: "NanumSquareAc",
  //     fontSize: "0.875rem",
  //     fontWeight: 400,
  //     lineHeight: 1.6,
  //   },
  //   button: {
  //     fontFamily: "NanumSquareAc",
  //     fontSize: "0.875rem",
  //     fontWeight: 500,
  //     lineHeight: 1.6,
  //     textTransform: "uppercase",
  //   },
  //   caption: {
  //     fontFamily: "NanumSquareAc",
  //     fontSize: "0.75rem",
  //     fontWeight: 400,
  //     lineHeight: 1.4,
  //   },
  //   overline: {
  //     fontFamily: "NanumSquareAc",
  //     fontSize: "0.625rem",
  //     fontWeight: 400,
  //     lineHeight: 1.4,
  //     textTransform: "uppercase",
  //   },
  //   subtitle1: {
  //     fontFamily: "NanumSquareAc",
  //     fontSize: "1rem",
  //     fontWeight: 400,
  //     lineHeight: 1.6,
  //   },
  //   subtitle2: {
  //     fontFamily: "NanumSquareAc",
  //     fontSize: "0.875rem",
  //     fontWeight: 500,
  //     lineHeight: 1.6,
  //   },
  // },
});
export default theme;
