import {
  INCHIcon,
  AAVEIcon,
  ADAIcon,
  AKTIcon,
  ALGOIcon,
  ANKRIcon,
  APEIcon,
  APTIcon,
  ARBIcon,
  ARKMIcon,
  ARIcon,
  ATOMIcon,
  AVAXIcon,
  AXSIcon,
  BANDIcon,
  BATIcon,
  BCHIcon,
  BELIcon,
  BGBIcon,
  BICOIcon,
  BLURIcon,
  BNBIcon,
  BNTIcon,
  BNXIcon,
  BONDIcon,
  BTCIcon,
  C98Icon,
  CAKEIcon,
  CELOIcon,
  CELRIcon,
  CFXIcon,
  CHRIcon,
  CHZIcon,
  CKBIcon,
  COMPIcon,
  COREIcon,
  COTIIcon,
  CRVIcon,
  CYBERIcon,
  DENTIcon,
  DOGEIcon,
  DOTIcon,
  DYDXIcon,
  EGLDIcon,
  ENJIcon,
  ENSIcon,
  EOSIcon,
  ETCIcon,
  ETHIcon,
  FETIcon,
  FILIcon,
  FLOKIIcon,
  FLOWIcon,
  FLUXIcon,
  FTMIcon,
  FXSIcon,
  GALAIcon,
  GMTIcon,
  GMXIcon,
  GRTIcon,
  GTCIcon,
  HBARIcon,
  HNTIcon,
  HOTIcon,
  ICPIcon,
  ICXIcon,
  IDIcon,
  IMXIcon,
  INJIcon,
  IOSTIcon,
  IOTAIcon,
  IOTXIcon,
  JTOIcon,
  KASIcon,
  KAVAIcon,
  KEYIcon,
  KLAYIcon,
  KNCIcon,
  KSMIcon,
  LDOIcon,
  LINKIcon,
  LITIcon,
  LOOKSIcon,
  LOOMIcon,
  LPTIcon,
  LQTYIcon,
  LRCIcon,
  LSKIcon,
  LTCIcon,
  LUNAIcon,
  MAGICIcon,
  MANAIcon,
  MANTAIcon,
  MASKIcon,
  MAVIcon,
  MBOXIcon,
  MEMEIcon,
  METISIcon,
  MINAIcon,
  MKRIcon,
  NEARIcon,
  NEOIcon,
  NKNIcon,
  NTRNIcon,
  NULSIcon,
  OGNIcon,
  ONDOIcon,
  ONEIcon,
  ONTIcon,
  OPIcon,
  ORBSIcon,
  PAXGIcon,
  PENDLEIcon,
  PEPEIcon,
  POLYXIcon,
  PYTHIcon,
  QNTIcon,
  QTUMIcon,
  RDNTIcon,
  ROSEIcon,
  RSRIcon,
  RUNEIcon,
  RVNIcon,
  SANDIcon,
  SEIIcon,
  SFPIcon,
  SHIBIcon,
  SKLIcon,
  SNXIcon,
  SOLIcon,
  SSVIcon,
  STGIcon,
  STMXIcon,
  STORJIcon,
  STRKIcon,
  STXIcon,
  SUIIcon,
  SUNIcon,
  SUPERIcon,
  SUSHIIcon,
  SXPIcon,
  SYNIcon,
  SYSIcon,
  THETAIcon,
  TIAIcon,
  TONIcon,
  TRBIcon,
  TRUIcon,
  TRXIcon,
  TIcon,
  UNFIIcon,
  UNIIcon,
  USDCIcon,
  VETIcon,
  VGXIcon,
  VIDTIcon,
  WLDIcon,
  WOOIcon,
  XAIIcon,
  XLMIcon,
  XRPIcon,
  XTZIcon,
  XVGIcon,
  ZILIcon,
  ZRXIcon,
} from "../../../components/Icons/CoinSymbols";
import { JSXElementConstructor } from "react";

interface CoinIconTableType {
  [key: string]: JSX.Element;
}

const createCoinIconTable = (
  width: number,
  height: number
): CoinIconTableType => {
  return {
    INCHUSDT: <INCHIcon width={width} height={height} />,
    AAVEUSDT: <AAVEIcon width={width} height={height} />,
    ADAUSDT: <ADAIcon width={width} height={height} />,
    AKTUSDT: <AKTIcon width={width} height={height} />,
    ALGOUSDT: <ALGOIcon width={width} height={height} />,
    ANKRUSDT: <ANKRIcon width={width} height={height} />,
    APEUSDT: <APEIcon width={width} height={height} />,
    APTUSDT: <APTIcon width={width} height={height} />,
    ARBUSDT: <ARBIcon width={width} height={height} />,
    ARKMUSDT: <ARKMIcon width={width} height={height} />,
    ARUSDT: <ARIcon width={width} height={height} />,
    ATOMUSDT: <ATOMIcon width={width} height={height} />,
    AVAXUSDT: <AVAXIcon width={width} height={height} />,
    AXSUSDT: <AXSIcon width={width} height={height} />,
    BANDUSDT: <BANDIcon width={width} height={height} />,
    BATUSDT: <BATIcon width={width} height={height} />,
    BCHUSDT: <BCHIcon width={width} height={height} />,
    BELUSDT: <BELIcon width={width} height={height} />,
    BGBUSDT: <BGBIcon width={width} height={height} />,
    BICOUSDT: <BICOIcon width={width} height={height} />,
    BLURUSDT: <BLURIcon width={width} height={height} />,
    BNBUSDT: <BNBIcon width={width} height={height} />,
    BNTUSDT: <BNTIcon width={width} height={height} />,
    BNXUSDT: <BNXIcon width={width} height={height} />,
    BONDUSDT: <BONDIcon width={width} height={height} />,
    BTCUSDT: <BTCIcon width={width} height={height} />,
    C98USDT: <C98Icon width={width} height={height} />,
    CAKEUSDT: <CAKEIcon width={width} height={height} />,
    CELOUSDT: <CELOIcon width={width} height={height} />,
    CELRUSDT: <CELRIcon width={width} height={height} />,
    CFXUSDT: <CFXIcon width={width} height={height} />,
    CHRUSDT: <CHRIcon width={width} height={height} />,
    CHZUSDT: <CHZIcon width={width} height={height} />,
    CKBUSDT: <CKBIcon width={width} height={height} />,
    COMPUSDT: <COMPIcon width={width} height={height} />,
    COREUSDT: <COREIcon width={width} height={height} />,
    COTIUSDT: <COTIIcon width={width} height={height} />,
    CRVUSDT: <CRVIcon width={width} height={height} />,
    CYBERUSDT: <CYBERIcon width={width} height={height} />,
    DENTUSDT: <DENTIcon width={width} height={height} />,
    DOGEUSDT: <DOGEIcon width={width} height={height} />,
    DOTUSDT: <DOTIcon width={width} height={height} />,
    DYDXUSDT: <DYDXIcon width={width} height={height} />,
    EGLDUSDT: <EGLDIcon width={width} height={height} />,
    ENJUSDT: <ENJIcon width={width} height={height} />,
    ENSUSDT: <ENSIcon width={width} height={height} />,
    EOSUSDT: <EOSIcon width={width} height={height} />,
    ETCUSDT: <ETCIcon width={width} height={height} />,
    ETHUSDT: <ETHIcon width={width} height={height} />,
    FETUSDT: <FETIcon width={width} height={height} />,
    FILUSDT: <FILIcon width={width} height={height} />,
    FLOKIUSDT: <FLOKIIcon width={width} height={height} />,
    FLOWUSDT: <FLOWIcon width={width} height={height} />,
    FLUXUSDT: <FLUXIcon width={width} height={height} />,
    FTMUSDT: <FTMIcon width={width} height={height} />,
    FXSUSDT: <FXSIcon width={width} height={height} />,
    GALAUSDT: <GALAIcon width={width} height={height} />,
    GMTUSDT: <GMTIcon width={width} height={height} />,
    GMXUSDT: <GMXIcon width={width} height={height} />,
    GRTUSDT: <GRTIcon width={width} height={height} />,
    GTCUSDT: <GTCIcon width={width} height={height} />,
    HBARUSDT: <HBARIcon width={width} height={height} />,
    HNTUSDT: <HNTIcon width={width} height={height} />,
    HOTUSDT: <HOTIcon width={width} height={height} />,
    ICPUSDT: <ICPIcon width={width} height={height} />,
    ICXUSDT: <ICXIcon width={width} height={height} />,
    IDUSDT: <IDIcon width={width} height={height} />,
    IMXUSDT: <IMXIcon width={width} height={height} />,
    INJUSDT: <INJIcon width={width} height={height} />,
    IOSTUSDT: <IOSTIcon width={width} height={height} />,
    IOTAUSDT: <IOTAIcon width={width} height={height} />,
    IOTXUSDT: <IOTXIcon width={width} height={height} />,
    JTOUSDT: <JTOIcon width={width} height={height} />,
    KASUSDT: <KASIcon width={width} height={height} />,
    KAVAUSDT: <KAVAIcon width={width} height={height} />,
    KEYUSDT: <KEYIcon width={width} height={height} />,
    KLAYUSDT: <KLAYIcon width={width} height={height} />,
    KNCUSDT: <KNCIcon width={width} height={height} />,
    KSMUSDT: <KSMIcon width={width} height={height} />,
    LDOUSDT: <LDOIcon width={width} height={height} />,
    LINKUSDT: <LINKIcon width={width} height={height} />,
    LITUSDT: <LITIcon width={width} height={height} />,
    LOOKSUSDT: <LOOKSIcon width={width} height={height} />,
    LOOMUSDT: <LOOMIcon width={width} height={height} />,
    LPTUSDT: <LPTIcon width={width} height={height} />,
    LQTYUSDT: <LQTYIcon width={width} height={height} />,
    LRCUSDT: <LRCIcon width={width} height={height} />,
    LSKUSDT: <LSKIcon width={width} height={height} />,
    LTCUSDT: <LTCIcon width={width} height={height} />,
    LUNAUSDT: <LUNAIcon width={width} height={height} />,
    MAGICUSDT: <MAGICIcon width={width} height={height} />,
    MANAUSDT: <MANAIcon width={width} height={height} />,
    MANTAUSDT: <MANTAIcon width={width} height={height} />,
    MASKUSDT: <MASKIcon width={width} height={height} />,
    MAVUSDT: <MAVIcon width={width} height={height} />,
    MBOXUSDT: <MBOXIcon width={width} height={height} />,
    MEMEUSDT: <MEMEIcon width={width} height={height} />,
    METISUSDT: <METISIcon width={width} height={height} />,
    MINAUSDT: <MINAIcon width={width} height={height} />,
    MKRUSDT: <MKRIcon width={width} height={height} />,
    NEARUSDT: <NEARIcon width={width} height={height} />,
    NEOUSDT: <NEOIcon width={width} height={height} />,
    NKNUSDT: <NKNIcon width={width} height={height} />,
    NTRNUSDT: <NTRNIcon width={width} height={height} />,
    NULSUSDT: <NULSIcon width={width} height={height} />,
    OGNUSDT: <OGNIcon width={width} height={height} />,
    ONDOUSDT: <ONDOIcon width={width} height={height} />,
    ONEUSDT: <ONEIcon width={width} height={height} />,
    ONTUSDT: <ONTIcon width={width} height={height} />,
    OPUSDT: <OPIcon width={width} height={height} />,
    ORBSUSDT: <ORBSIcon width={width} height={height} />,
    PAXGUSDT: <PAXGIcon width={width} height={height} />,
    PENDLEUSDT: <PENDLEIcon width={width} height={height} />,
    PEPEUSDT: <PEPEIcon width={width} height={height} />,
    POLYXUSDT: <POLYXIcon width={width} height={height} />,
    PYTHUSDT: <PYTHIcon width={width} height={height} />,
    QNTUSDT: <QNTIcon width={width} height={height} />,
    QTUMUSDT: <QTUMIcon width={width} height={height} />,
    RDNTUSDT: <RDNTIcon width={width} height={height} />,
    ROSEUSDT: <ROSEIcon width={width} height={height} />,
    RSRUSDT: <RSRIcon width={width} height={height} />,
    RUNEUSDT: <RUNEIcon width={width} height={height} />,
    RVNUSDT: <RVNIcon width={width} height={height} />,
    SANDUSDT: <SANDIcon width={width} height={height} />,
    SEIUSDT: <SEIIcon width={width} height={height} />,
    SFPUSDT: <SFPIcon width={width} height={height} />,
    SHIBUSDT: <SHIBIcon width={width} height={height} />,
    SKLUSDT: <SKLIcon width={width} height={height} />,
    SNXUSDT: <SNXIcon width={width} height={height} />,
    SOLUSDT: <SOLIcon width={width} height={height} />,
    SSVUSDT: <SSVIcon width={width} height={height} />,
    STGUSDT: <STGIcon width={width} height={height} />,
    STMXUSDT: <STMXIcon width={width} height={height} />,
    STORJUSDT: <STORJIcon width={width} height={height} />,
    STRKUSDT: <STRKIcon width={width} height={height} />,
    STXUSDT: <STXIcon width={width} height={height} />,
    SUIUSDT: <SUIIcon width={width} height={height} />,
    SUNUSDT: <SUNIcon width={width} height={height} />,
    SUPERUSDT: <SUPERIcon width={width} height={height} />,
    SUSHIUSDT: <SUSHIIcon width={width} height={height} />,
    SXPUSDT: <SXPIcon width={width} height={height} />,
    SYNUSDT: <SYNIcon width={width} height={height} />,
    SYSUSDT: <SYSIcon width={width} height={height} />,
    THETAUSDT: <THETAIcon width={width} height={height} />,
    TIAUSDT: <TIAIcon width={width} height={height} />,
    TONUSDT: <TONIcon width={width} height={height} />,
    TRBUSDT: <TRBIcon width={width} height={height} />,
    TRUUSDT: <TRUIcon width={width} height={height} />,
    TRXUSDT: <TRXIcon width={width} height={height} />,
    TUSDT: <TIcon width={width} height={height} />,
    UNFIUSDT: <UNFIIcon width={width} height={height} />,
    UNIUSDT: <UNIIcon width={width} height={height} />,
    USDCUSDT: <USDCIcon width={width} height={height} />,
    VETUSDT: <VETIcon width={width} height={height} />,
    VGXUSDT: <VGXIcon width={width} height={height} />,
    VIDTUSDT: <VIDTIcon width={width} height={height} />,
    WLDUSDT: <WLDIcon width={width} height={height} />,
    WOOUSDT: <WOOIcon width={width} height={height} />,
    XAIUSDT: <XAIIcon width={width} height={height} />,
    XLMUSDT: <XLMIcon width={width} height={height} />,
    XRPUSDT: <XRPIcon width={width} height={height} />,
    XTZUSDT: <XTZIcon width={width} height={height} />,
    XVGUSDT: <XVGIcon width={width} height={height} />,
    ZILUSDT: <ZILIcon width={width} height={height} />,
    ZRXUSDT: <ZRXIcon width={width} height={height} />,
  };
};

export default createCoinIconTable;
