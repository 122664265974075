export function isArray<T = any>(value: any): value is T[] {
  return Object.prototype.toString.call(value) === "[object Array]";
}

export function isFunction<T = (...args: any) => any>(value: any): value is T {
  return typeof value === "function";
}

export function isObject(value: any): value is object {
  return typeof value === "object" && isValid(value);
}

export function isNumber(value: any): value is number {
  return typeof value === "number" && !isNaN(value);
}

export function isValid<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export function isBoolean(value: any): value is boolean {
  return typeof value === "boolean";
}

export function isString(value: any): value is string {
  return typeof value === "string";
}
