import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import cloneDeep from "lodash/cloneDeep";
import { v4 as uuidv4 } from "uuid";

import CustomCard from "../../components/CustomCard";
import CustomTable from "../../components/CustomTable";

//Title icons
import AddIcon from "@mui/icons-material/Add";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

import { createTableData } from "./DetailJournal/OpenTrade/OpenTradeJournal";
import { openTradeJournalHeaderList } from "./DetailJournal/OpenTrade/OpenTradeJournal";
import { useLocation, useNavigate } from "react-router-dom";

import { useAppDispatch } from "../../store/hooks"; // 경로는 맞게 수정
import {
  addBreadcrumb,
  removeBreadcrumb,
} from "../../store/reducers/breadcrumbs"; // 경로는 맞게 수정
//import BreadCrumb from '../../layout/Drawer/BreadCrumb';
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import {
  closedTradeJournalHeaderList,
  createClosedTableData,
} from "./DetailJournal/ClosedTrade/ClosedTradeJournal";
import {
  createCollapseTableData,
  expandedTradeJournalHeaderList,
} from "./DetailJournal/ExpandedTableData";
import React from "react";
import CustomEditDrawer from "../../components/CustomEditDrawer";
import EditTradeTemplate, {
  TagsRow,
} from "./DetailJournal/EditJournal/EditTradeTemplate";
import CustomEditField from "../../components/CustomEditField";
import dayjs, { Dayjs } from "dayjs";
import {
  FilterSet,
  IAllTags,
  IClosedTradeTableData,
  ICollapseTradeTableData,
  IHistoryStackData,
  IJournalTableData,
  IOpenTradeTableData,
  TagOptionType,
  TradeDataRecord,
  TradeType,
} from "./types";
import {
  ALLTAGS,
  CheckboxesTags,
  CssTextField,
  GenButton,
  TradeTypeForStyle,
  TradeTypeMap,
  getIconAndName,
  menuButtonNode,
} from "./CommonTableNode";

import ArrowCircleUpRoundedIcon from "@mui/icons-material/ArrowCircleUpRounded";
import ArrowCircleDownRoundedIcon from "@mui/icons-material/ArrowCircleDownRounded";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";

import { AppDispatch, RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import {
  addTrade,
  fetchTradeData,
  setTradeDatas,
} from "../../store/reducers/journal/data/tradeData";
import {
  setChangedGroupId,
  setAlertEditDialogMessage,
  setEditDialogAction,
  setEditDialogOpen,
  setEditDialogType,
  setEditDrawerOpen,
  setEditDrawerWidth,
  setSelectedJournalGroupId,
  setSelectedJournalRowIndex,
  setSelectedJournalType,
} from "../../store/reducers/journal/page/journalDetails";

import {
  selectAllTags,
  selectClosedTrades,
  selectOpenTrades,
  selectTradeDatas,
  selectTradeLoading,
} from "../../store/selector/tradeDataSelector";

import {
  resetSelectedJournal,
  setSelectedJournal,
} from "../../store/reducers/journal/page/journalOverview";
import CustomTooltipIcon from "../../components/CustomTooltipIcon";
import InfoIcon from "@mui/icons-material/Info";
import {
  allExchanges,
  allSymbols,
  CoinIconTable,
  ExchangeIconTable,
} from "../../components/CommonNodes";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import CustomProgressLine from "../../components/CustomProgressLine";
import { localeStringToNumber, round } from "../../utils/utils";
import {
  fetchJournals,
  setHistoryData,
  updateJournal,
} from "../../store/reducers/journal/data/journalData";
import {
  selectAddLoading,
  selectedJournalData,
  selectedJournalUpdatedAt,
  selectLoading,
  selectUpdateDateAt,
} from "../../store/selector/journalDataSelector";
import { applyFilter } from "../../store/api/tradeDataProcessing";
import { enqueueSnackbar } from "notistack";

import RadioButtonUncheckedRoundedIcon from "@mui/icons-material/RadioButtonUncheckedRounded";
import { UserState } from "../../store/reducers/user";
import { userData } from "../../store/selector/userSelector";
import { setIsShowAnalysis } from "../../store/reducers/journal/page/journal";

interface TradeSearchPanelProps {
  exchange: string;
  markeyType: string;
  position: string;
  leverage: string;
  allNoteOptions: string[];
  selectedNoteOptions: string[];
  allSymbolOptions: string[];
  selectedSymbolOptions: string[];
  tags: TagOptionType[] | undefined;
  winLose: string;
  allTags: IAllTags[];
  setExchange: React.Dispatch<React.SetStateAction<string>>;
  setMarketType: React.Dispatch<React.SetStateAction<string>>;
  setPosition: React.Dispatch<React.SetStateAction<string>>;
  setLeverage: React.Dispatch<React.SetStateAction<string>>;
  setAllNoteOptions: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedNoteOptions: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedSymbolOptions: React.Dispatch<React.SetStateAction<string[]>>;
  setTags: React.Dispatch<React.SetStateAction<TagOptionType[] | undefined>>;
  setWinLose: React.Dispatch<React.SetStateAction<string>>;
  setFilters: React.Dispatch<React.SetStateAction<FilterSet>>;
}

export const TradeSearchPanel: React.FC<TradeSearchPanelProps> = ({
  exchange,
  markeyType,
  position,
  leverage,
  allNoteOptions,
  selectedNoteOptions,
  allSymbolOptions,
  selectedSymbolOptions,
  tags,
  winLose,
  allTags,
  setExchange,
  setMarketType,
  setPosition,
  setLeverage,
  setAllNoteOptions,
  setSelectedNoteOptions,
  setSelectedSymbolOptions,
  setTags,
  setWinLose,
  setFilters,
}) => {
  const theme = useTheme();

  const [inputValue, setInputValue] = useState("");
  const [inputSymbolValue, setInputSymbolValue] = useState("");

  // 필터를 설정하는 함수
  const handleSetFilter = useCallback((key: string, value: any) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  }, []);

  const handleRemoveFilter = useCallback((key: string) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters };
      delete newFilters[key];
      return newFilters;
    });
  }, []);

  // 새로운 옵션 추가 및 선택
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      const inputValue = (event.target as HTMLInputElement).value;
      if (
        inputValue &&
        !allNoteOptions!.some((option) => option === inputValue)
      ) {
        setAllNoteOptions([...allNoteOptions!, inputValue]);
        setSelectedNoteOptions([...selectedNoteOptions, inputValue]);
      }
      setInputValue(""); // 입력 필드를 비웁니다.
    }
  };

  const handleDeleteOption = (deletedOption: string) => {
    setAllNoteOptions((prevOptions) =>
      prevOptions.filter((option) => option !== deletedOption)
    );
  };

  useEffect(() => {
    setSelectedNoteOptions(selectedNoteOptions);
  }, [selectedNoteOptions]);

  useEffect(() => {
    setSelectedSymbolOptions(selectedSymbolOptions);
  }, [selectedSymbolOptions]);

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 1 }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
        <Box sx={{ display: "flex", flexDirection: "row", flex: 1, gap: 0.6 }}>
          <CssTextField
            placeholder="비어있음"
            value={exchange || null}
            select
            inputProps={{
              sx: { minWidth: "80px" },
            }}
            onChange={(e) => {
              if (e.target.value) {
                const newExchange = e.target.value as string;
                setExchange(newExchange);

                if (newExchange === "ALL") handleRemoveFilter("exchange");
                else handleSetFilter("exchange", newExchange);
              }
            }}
          >
            <MenuItem key={"ALL"} value={"ALL"}>
              거래소
            </MenuItem>
            {Object.entries(allExchanges).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </CssTextField>
          <CssTextField
            placeholder="비어있음"
            value={winLose || null}
            select
            inputProps={{
              sx: { minWidth: "30px" },
            }}
            onChange={(e) => {
              if (e.target.value) {
                const newWinLose = e.target.value as string;
                setWinLose(newWinLose);

                if (newWinLose === "ALL") handleRemoveFilter("allProfit");
                else if (newWinLose === "승")
                  handleSetFilter(
                    "allProfit",
                    (allProfit: number) => allProfit >= 0
                  );
                else if (newWinLose === "패")
                  handleSetFilter(
                    "allProfit",
                    (allProfit: number) => allProfit < 0
                  );
              }
            }}
          >
            <MenuItem key={"ALL"} value={"ALL"}>
              승패
            </MenuItem>
            <MenuItem key={"승"} value={"승"}>
              승
            </MenuItem>
            <MenuItem key={"패"} value={"패"}>
              패
            </MenuItem>
          </CssTextField>

          <CssTextField
            placeholder="비어있음"
            value={markeyType || null}
            select
            inputProps={{
              sx: { minWidth: "30px" },
            }}
            onChange={(e) => {
              if (e.target.value) {
                const newMarketType = e.target.value as string;
                setMarketType(newMarketType);

                if (newMarketType === "ALL") handleRemoveFilter("marketType");
                else handleSetFilter("marketType", newMarketType);
              }
            }}
          >
            <MenuItem key={"ALL"} value={"ALL"}>
              시장
            </MenuItem>
            <MenuItem key={"선물"} value={"USDT-FUTURES"}>
              선물
            </MenuItem>
            <MenuItem key={"현물"} value={"SPOT"}>
              현물
            </MenuItem>
          </CssTextField>
          <CssTextField
            placeholder="비어있음"
            value={position || null}
            select
            inputProps={{
              sx: { minWidth: "60px" },
            }}
            onChange={(e) => {
              if (e.target.value) {
                const newPosition = e.target.value as string;
                setPosition(newPosition);

                if (newPosition === "ALL") handleRemoveFilter("position");
                else handleSetFilter("position", newPosition);
              }
            }}
          >
            <MenuItem key={"ALL"} value={"ALL"}>
              포지션
            </MenuItem>
            <MenuItem key={"LONG"} value={"LONG"}>
              LONG
            </MenuItem>
            <MenuItem key={"SHORT"} value={"SHORT"}>
              SHORT
            </MenuItem>
            <MenuItem key={"BUY"} value={"BUY"}>
              BUY
            </MenuItem>
            <MenuItem key={"SELL"} value={"SELL"}>
              SELL
            </MenuItem>
          </CssTextField>
          <CssTextField
            placeholder="비어있음"
            value={leverage || null}
            select
            inputProps={{
              sx: { minWidth: "60px" },
            }}
            onChange={(e) => {
              if (e.target.value) {
                const newLeverage = e.target.value as string;
                setLeverage(newLeverage);

                if (newLeverage === "ALL") handleRemoveFilter("leverage");
                else handleSetFilter("leverage", Number(newLeverage));
              }
            }}
          >
            <MenuItem key={"ALL"} value={"ALL"}>
              레버리지
            </MenuItem>
            {Array.from({ length: 100 }, (_, i) => (
              <MenuItem key={i + 1} value={i + 1}>
                {i + 1}
              </MenuItem>
            ))}
          </CssTextField>
        </Box>

        <Grid item xs={2} md={0.1}></Grid>
        <Grid item xs={4} md={11}>
          <CheckboxesTags
            mode={"EDIT"}
            options={allTags}
            tags={tags || []}
            setTags={setTags}
            handleSetFilter={handleSetFilter}
            handleRemoveFilter={handleRemoveFilter}
          />
        </Grid>
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "row", width: "100%", gap: 1 }}
      >
        <React.Fragment>
          <Box sx={{ flex: 0.6 }}>
            <Autocomplete
              multiple
              fullWidth
              id="symbol-filters"
              limitTags={4}
              isOptionEqualToValue={(option, value) => option === value}
              options={allSymbolOptions || []}
              disableCloseOnSelect
              value={selectedSymbolOptions}
              inputValue={inputSymbolValue}
              onChange={(event, newValue) => {
                setSelectedSymbolOptions(newValue);
              }}
              getOptionLabel={(option) => option}
              renderTags={(value: string[], getTagProps) =>
                value.map((option: string, index: number) => {
                  const tagProps = getTagProps({ index });
                  return (
                    <Chip
                      variant="outlined"
                      size="small"
                      label={getIconAndName(option, CoinIconTable[option])}
                      {...tagProps}
                      key={option} // 명시적으로 key를 지정
                    />
                  );
                })
              }
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option}>
                  <Checkbox
                    size="small"
                    icon={<RadioButtonUncheckedRoundedIcon />}
                    checkedIcon={<CheckCircleRoundedIcon />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {getIconAndName(option, CoinIconTable[option])}
                </li>
              )}
              style={{ padding: 0, margin: 0 }}
              onInputChange={(event, newInputValue) => {
                setInputSymbolValue(newInputValue);
              }}
              renderInput={(params) => (
                <CssTextField
                  {...params}
                  fullWidth
                  placeholder="종목 필터"
                  InputProps={{
                    ...params.InputProps,
                    sx: {
                      padding: "0 !important", // 패딩 제거
                    },
                  }}
                />
              )}
            />
          </Box>
          <Box sx={{ flex: 0.6 }}>
            <Autocomplete
              multiple
              id="closedtrades-filter-options"
              limitTags={4}
              isOptionEqualToValue={(option, value) => option === value}
              options={allNoteOptions || []}
              disableCloseOnSelect
              value={selectedNoteOptions}
              onChange={(event, newValue) => {
                setSelectedNoteOptions(newValue);
              }}
              getOptionLabel={(option) => option}
              renderOption={(props, option, { selected }) => (
                <li
                  {...props}
                  key={option}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {option}

                  <IconButton onClick={() => handleDeleteOption(option)}>
                    <ClearRoundedIcon />
                  </IconButton>
                </li>
              )}
              renderTags={(value: string[], getTagProps) =>
                value.map((option: string, index: number) => {
                  const tagProps = getTagProps({ index });
                  return (
                    <Chip
                      variant="outlined"
                      size="small"
                      label={option}
                      {...tagProps}
                      key={option} // 명시적으로 key를 지정
                    />
                  );
                })
              }
              style={{ padding: 0, margin: 0 }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              renderInput={(params) => (
                <CssTextField
                  {...params}
                  fullWidth
                  placeholder="메모에서 검색..."
                  onKeyDown={handleKeyDown}
                  //customVariant="customBorder"
                  InputProps={{
                    ...params.InputProps,
                    sx: {
                      padding: "0 !important", // 패딩 제거
                    },
                  }}
                />
              )}
            />
          </Box>
        </React.Fragment>
      </Box>
    </Box>
  );
};

const JournalDetailPage = (): JSX.Element => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const hasMounted = useRef(false);
  const editDrawerMinWidth = 560;
  const location = useLocation();
  const { selectedJournalParam } = location.state || {};

  const dispatch = useAppDispatch();

  const selectedJournal = useSelector((state: RootState) =>
    selectedJournalData(state)
  );

  const addLoading: boolean = useSelector((state: RootState) =>
    selectAddLoading(state)
  );

  const updateDateAt: Dayjs = useSelector((state: RootState) =>
    selectUpdateDateAt(state)
  );

  const journalLastUpdateAt: number = useSelector((state: RootState) =>
    selectedJournalUpdatedAt(state)
  );

  const user: UserState = useSelector((state: RootState) => userData(state));

  const tradeDatas: TradeDataRecord[] = useSelector((state: RootState) =>
    selectTradeDatas(state, selectedJournal.Jid)
  );
  const openTrades: IOpenTradeTableData[] = useSelector((state: RootState) =>
    selectOpenTrades(state, selectedJournal.Jid)
  );
  const closedTrades: IClosedTradeTableData[] = useSelector(
    (state: RootState) => selectClosedTrades(state, selectedJournal.Jid)
  );

  const tradeLoading: boolean = useSelector((state: RootState) =>
    selectTradeLoading(state, selectedJournal.Jid)
  );

  const allTags: IAllTags[] = useSelector((state: RootState) =>
    selectAllTags(state, selectedJournal.Jid)
  );

  const changedGroupId = useSelector(
    (state: RootState) => state.journalDetails.changedGroupId
  );

  const selectedJournalGroupId = useSelector(
    (state: RootState) => state.journalDetails.selectedJournalGroupId
  );
  const selectedJournalRowIndex = useSelector(
    (state: RootState) => state.journalDetails.selectedJournalRowIndex
  );
  const selectedJournalType = useSelector(
    (state: RootState) => state.journalDetails.selectedJournalType
  );
  const editDrawerOpen = useSelector(
    (state: RootState) => state.journalDetails.editDrawerOpen
  );
  const editDrawerWidth = useSelector(
    (state: RootState) => state.journalDetails.editDrawerWidth
  );

  const editDialogOpen = useSelector(
    (state: RootState) => state.journalDetails.editDialogOpen
  );
  const editDialogType = useSelector(
    (state: RootState) => state.journalDetails.editDialogType
  );
  const editDialogAction = useSelector(
    (state: RootState) => state.journalDetails.editDialogAction
  );
  const alertEditDialogMessage = useSelector(
    (state: RootState) => state.journalDetails.alertEditDialogMessage
  );

  const breadcrumbs = useSelector((state: RootState) => state.breadcrumbs);

  const [loading, setLoading] = useState(false);

  const [exchangeState, setExchangeState] = useState("ALL");
  const [marketTypeState, setMarketTypeState] = useState("ALL");
  const [positionState, setPositionState] = useState("ALL");
  const [leverageState, setLeverageState] = useState("ALL");
  const [winLoseState, setWinLoseState] = useState("ALL");

  const [allNoteOptions, setAllNoteOptions] = useState<string[]>([]);
  const [selectedNoteOptions, setSelectedNoteOptions] = useState<string[]>([]);

  const [allSymbolOptions, setAllSymbolOptions] = useState<string[]>(
    Object.keys(CoinIconTable)
  );
  const [selectedSymbolOptions, setSelectedSymbolOptions] = useState<string[]>(
    []
  );

  const [tagsState, setTagsState] = useState<TagOptionType[] | undefined>([]);

  const [openTradesNodeList, setOpenTradesNodeList] = useState<ReactNode[][]>(
    []
  );
  const [closedTradesNodeList, setClosedTradesNodeList] = useState<
    ReactNode[][]
  >([]);

  const [filters, setFilters] = useState<FilterSet>({});
  const [totalProfit, setTotalProfit] = useState(0);

  const [challengeDialogOpen, setChallengeDialogOpen] = useState(false);
  const [tempChallengeAmount, setTempChallengeAmount] = useState(
    (selectedJournal.challengeAmount &&
      selectedJournal.challengeAmount.toLocaleString()) ||
      ""
  );

  const [addButtonNode, setAddButtonNode] = useState<any>();

  useEffect(() => {
    const isBreadcrumbExist = breadcrumbs.some(
      (breadcrumb: any) => breadcrumb.href === location.pathname
    );

    // 새로고침 감지용 sessionStorage 키 설정
    const reloadKey = "isPageReloaded";

    // 세션 스토리지에 값이 없으면 새로고침이 아님 (즉, 첫 로드거나 탭 이동임)
    const isPageReloaded = sessionStorage.getItem(reloadKey);
    // 새로고침인 경우
    if (isPageReloaded) {
      if (selectedJournal.Jid === "") {
        if (user.journalIds.length === 0) return;
        if (breadcrumbs && breadcrumbs.length !== 0) {
          const localState = breadcrumbs[breadcrumbs.length - 1].state;
          if (localState && "selectedJournalParam" in localState) {
            const selectedJournalParam = localState["selectedJournalParam"];
            setLoading(true);
            const fetchAllData = async () => {
              try {
                const journalDatas = await dispatch(
                  fetchJournals({ journalIds: user.journalIds })
                ).unwrap();

                //console.log("Journal data fetched successfully:", journalDatas);

                let allDatas: Array<IJournalTableData & TradeDataRecord> = [];

                // 비동기 작업을 순차적으로 처리하기 위해 for...of 사용
                for (const journalData of journalDatas) {
                  try {
                    const result = await dispatch(
                      fetchTradeData(journalData.Jid)
                    ).unwrap();

                    result.tradeDatas.forEach((tradeData) => {
                      allDatas.push({ ...tradeData, ...journalData });
                    });
                  } catch (error) {
                    console.error("Failed to fetch trade data:", error);
                  }
                }

                // 모든 비동기 작업이 완료된 후 슬라이스 실행
                const recentDataList = allDatas
                  .sort((a, b) => b.tradeTime - a.tradeTime) // 내림차순으로 정렬
                  .slice(0, 5);

                const initialHistoryData: IHistoryStackData[] = [];
                recentDataList.forEach((trade) => {
                  initialHistoryData.push({
                    title: trade.symbol || "",
                    subTitle: `일지 - ${trade.title}`,
                    logo: trade.symbol || "",
                    content: `${trade.entryPrice?.toLocaleString()} USDT 에서 ${trade.position?.toUpperCase()} 포지션\n${
                      TradeTypeMap[trade.tradeType as TradeType]
                    }`,
                    tradeTime: trade.tradeTime || 0,
                  });
                });

                // 최종 데이터를 Redux state에 저장
                dispatch(setHistoryData(initialHistoryData));

                await dispatch(
                  setSelectedJournal(selectedJournalParam as IJournalTableData)
                );
              } catch (error) {
                console.error("Failed to fetch journal data:", error);
                enqueueSnackbar(
                  `데이터를 가져오는 과정에서 에러가 발생하였습니다. ${error}`,
                  {
                    variant: "error",
                    autoHideDuration: 3000,
                  }
                );
              }
              setLoading(false);
            };

            // 비동기 함수 호출
            fetchAllData();
          }
        }
      }

      sessionStorage.removeItem(reloadKey); // 한번 감지 후에는 다시 새로고침 여부 초기화
    }

    if (selectedJournal.Jid !== "") {
      if (selectedJournal.title) {
        if (!isBreadcrumbExist) {
          dispatch(
            addBreadcrumb({
              label: selectedJournal.title,
              href: location.pathname,
              state: location.state,
            })
          );
        }
      }

      let profitSum = 0.0;

      tradeDatas.forEach((tradeData: TradeDataRecord) => {
        profitSum += tradeData.profit || 0.0;
      });

      setTotalProfit(round(profitSum, 2));

      dispatch(setIsShowAnalysis(true));
    }

    // 새로고침할 때를 대비해 새로고침 여부를 세션 스토리지에 저장
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem(reloadKey, "true");
    });

    return () => {
      window.removeEventListener("beforeunload", () => {
        sessionStorage.setItem(reloadKey, "true");
      });
    };
  }, [
    dispatch,
    selectedJournal,
    location.pathname,
    location.state,
    navigate,
    user,
  ]);

  useEffect(() => {
    if (hasMounted.current) {
      // 컴포넌트가 처음 마운트된 이후에만 실행됨
      if (user.journalIds.length === 0) return;
      if (breadcrumbs && breadcrumbs.length !== 0) {
        const localState = breadcrumbs[breadcrumbs.length - 1].state;
        if (localState && "selectedJournalParam" in localState) {
          const selectedJournalParam = localState["selectedJournalParam"];
          setLoading(true);
          const fetchAllData = async () => {
            try {
              const journalDatas = await dispatch(
                fetchJournals({ journalIds: user.journalIds })
              ).unwrap();

              //console.log("Journal data fetched successfully:", journalDatas);

              let allDatas: Array<IJournalTableData & TradeDataRecord> = [];

              // 비동기 작업을 순차적으로 처리하기 위해 for...of 사용
              for (const journalData of journalDatas) {
                try {
                  const result = await dispatch(
                    fetchTradeData(journalData.Jid)
                  ).unwrap();

                  result.tradeDatas.forEach((tradeData) => {
                    allDatas.push({ ...tradeData, ...journalData });
                  });
                } catch (error) {
                  console.error("Failed to fetch trade data:", error);
                }
              }

              // 모든 비동기 작업이 완료된 후 슬라이스 실행
              const recentDataList = allDatas
                .sort((a, b) => b.tradeTime - a.tradeTime) // 내림차순으로 정렬
                .slice(0, 5);

              const initialHistoryData: IHistoryStackData[] = [];
              recentDataList.forEach((trade) => {
                initialHistoryData.push({
                  title: trade.symbol || "",
                  subTitle: `일지 - ${trade.title}`,
                  logo: trade.symbol || "",
                  content: `${trade.entryPrice?.toLocaleString()} USDT 에서 ${trade.position?.toUpperCase()} 포지션\n${
                    TradeTypeMap[trade.tradeType as TradeType]
                  }`,
                  tradeTime: trade.tradeTime || 0,
                });
              });

              // 최종 데이터를 Redux state에 저장
              dispatch(setHistoryData(initialHistoryData));

              await dispatch(
                setSelectedJournal(selectedJournalParam as IJournalTableData)
              );
            } catch (error) {
              console.error("Failed to fetch journal data:", error);
              enqueueSnackbar(
                `데이터를 가져오는 과정에서 에러가 발생하였습니다. ${error}`,
                {
                  variant: "error",
                  autoHideDuration: 3000,
                }
              );
            }
            setLoading(false);
          };

          // 비동기 함수 호출
          fetchAllData();
        }
      }
    } else {
      // 처음 마운트되었음을 설정
      hasMounted.current = true;
    }
  }, [updateDateAt]);
  //console.log(Jid);

  const handleTempChallengeAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTempChallengeAmount(event.target.value);
  };

  const handleTempChallengeAmountBlur = () => {
    const value = tempChallengeAmount.replace(/,/g, "");
    if (value === "") {
      setTempChallengeAmount("");
    } else if (!isNaN(Number(value))) {
      setTempChallengeAmount(localeStringToNumber(value).toLocaleString());
    }
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement>,
    onBlurCallback: () => void
  ) => {
    if (event.key === "Enter") {
      onBlurCallback();
      event.currentTarget.blur();
    }
  };

  const handleSaveClick = () => {
    const value = tempChallengeAmount.replace(/,/g, "");
    if (value !== "") {
      dispatch(
        updateJournal({
          updateJournalData: {
            ...selectedJournal,
            challengeAmount: localeStringToNumber(tempChallengeAmount),
          },
        })
      )
        .then((result) => {
          if (result.meta.requestStatus === "fulfilled") {
            dispatch(
              setSelectedJournal({
                ...selectedJournal,
                challengeAmount: localeStringToNumber(tempChallengeAmount),
              })
            );
            enqueueSnackbar("챌린지 목표 가격이 변경되었습니다.", {
              variant: "info",
              autoHideDuration: 2000,
            });
          } else {
            enqueueSnackbar(
              `챌린지 목표 가격이 변경 중 에러발생. : ${result.payload}`,
              {
                variant: "error",
                autoHideDuration: 3000,
              }
            );
          }

          setChallengeDialogOpen(false);
        })
        .catch((error) => {
          enqueueSnackbar(`챌린지 목표 가격이 변경 중 에러발생. : ${error}`, {
            variant: "error",
            autoHideDuration: 3000,
          });
          setChallengeDialogOpen(false);
        });
    }
  };

  useEffect(() => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters };
      if (selectedNoteOptions.length > 0) {
        newFilters.notes = selectedNoteOptions;
      } else {
        delete newFilters.notes; // 필터 조건이 없으면 필터링 조건을 제거합니다.
      }
      return newFilters;
    });
  }, [selectedNoteOptions]);

  useEffect(() => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters };
      if (selectedSymbolOptions.length > 0) {
        newFilters.symbol = selectedSymbolOptions;
      } else {
        delete newFilters.symbol; // 필터 조건이 없으면 필터링 조건을 제거합니다.
      }
      return newFilters;
    });
  }, [selectedSymbolOptions]);

  useEffect(() => {
    let profitSum = 0.0;

    tradeDatas.forEach((tradeData: TradeDataRecord) => {
      profitSum += tradeData.profit || 0.0;
    });

    setTotalProfit(round(profitSum, 2));
  }, [tradeDatas]);

  useEffect(() => {
    setOpenTradesNodeList(
      openTrades.map((trade: IOpenTradeTableData) => createTableData(trade))
    );

    if (changedGroupId !== "") {
      if (selectedJournalType === "OPEN") {
        const newTradeIndex = openTrades.findIndex(
          (trade: IOpenTradeTableData) => trade.groupId === changedGroupId
        );

        dispatch(setSelectedJournalRowIndex(newTradeIndex));
      }
      dispatch(setChangedGroupId(""));
    }
  }, [openTrades, changedGroupId]);

  useEffect(() => {
    const deepCopiedClosedTrades = cloneDeep(closedTrades);
    const filteredClosedTrades =
      Object.keys(filters).length === 0
        ? deepCopiedClosedTrades
        : deepCopiedClosedTrades.filter((trade: IClosedTradeTableData) => {
            return Object.entries(filters).every(([key, value]) =>
              applyFilter(trade, key, value)
            );
          });

    setClosedTradesNodeList(
      filteredClosedTrades.map((trade: IClosedTradeTableData) =>
        createClosedTableData(trade)
      )
    );

    if (changedGroupId !== "") {
      if (selectedJournalType === "CLOSED") {
        const newTradeIndex = closedTrades.findIndex(
          (trade: IClosedTradeTableData) => trade.groupId === changedGroupId
        );

        dispatch(setSelectedJournalRowIndex(newTradeIndex));
      }
      dispatch(setChangedGroupId(""));
    }

    const uniqueSymbols = Array.from(
      new Set(closedTrades.map((data) => data.symbol).filter(Boolean)) // undefined 값 제거
    );

    if (uniqueSymbols.length > 0) {
      setAllSymbolOptions(uniqueSymbols as string[]); // 타입을 string[]으로 명시적 변환
    }
  }, [closedTrades, changedGroupId, filters]);

  useEffect(() => {
    const addTradeButton = (
      <Button
        variant="outlined"
        sx={{
          borderWidth: "1.4px", // 보더 두께
          borderColor: theme.palette.primary.light, // 보더 색상
          borderRadius: 12,
          "&:hover": {
            borderWidth: "1.4px", // 보더 두께
            borderColor: theme.palette.primary.dark, // 호버 시 보더 색상 변경
            "& .MuiTypography-root": {
              color: `${theme.palette.primary.dark} !important`,
            },
          },
        }}
      >
        <Typography
          sx={{
            color: `${theme.palette.primary.main} !important`,
            fontWeight: "bold",
            fontSize: 12,
          }}
        >
          직접 추가
        </Typography>
      </Button>
    );

    // 메뉴 항목 데이터
    const addMenuItems = [
      {
        content: (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 0.4,
            }}
          >
            <ArrowCircleUpRoundedIcon />
            매수
          </Box>
        ),
        onClick: () =>
          handleNewTrade({
            initGroupId: selectedJournalGroupId,
            initTradeType: "Buy",
          }),
      },
      {
        content: (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 0.4,
            }}
          >
            <ArrowCircleDownRoundedIcon />
            매도
          </Box>
        ),
        onClick: () => {
          handleNewTrade({
            initGroupId: selectedJournalGroupId,
            initTradeType: "Sell",
          });
        },
      },
      {
        content: (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 0.4,
            }}
          >
            <CheckCircleOutlineRoundedIcon />
            최종 매도
          </Box>
        ),
        onClick: () =>
          handleNewTrade({
            initGroupId: selectedJournalGroupId,
            initTradeType: "PositionEnd",
          }),
      },
    ];

    setAddButtonNode(
      menuButtonNode({
        buttonContent: addTradeButton,
        menuItems: addMenuItems,
      })
    );
  }, [selectedJournalGroupId, selectedJournalRowIndex, selectedJournalType]);

  const handleResize = (width: number) => {
    if (width !== editDrawerWidth) {
      dispatch(setEditDrawerWidth(width));
    }
  };

  interface HandleNewTradeProps {
    initGroupId?: string;
    initTradeType: TradeType;
  }

  useEffect(() => {
    if (editDialogOpen && alertEditDialogMessage === "") {
      if (editDialogType === "confirm") {
        dispatch(
          setAlertEditDialogMessage(
            "최종 매도 기록을 생성하면 해당 거래 기록들은 종료된 거래 기록으로 자동으로 넘어갑니다.\n 생성하시겠습니까?"
          )
        );
      } else if (editDialogType === "alert") {
        dispatch(
          setAlertEditDialogMessage(
            "거래 시간 순서가 잘못되었습니다.\n 순서는 다음과 같이 작성되어야 합니다.\n\n 최초 진입 -> 매소 또는 매수 -> 최종 매도"
          )
        );
      }
    }
  }, [editDialogOpen, editDialogType, alertEditDialogMessage]);

  const handleNewTrade = async ({
    initGroupId,
    initTradeType,
  }: HandleNewTradeProps) => {
    let newTradeTime = dayjs().toISOString();
    let positionEndSize = 0;
    let prevTrade = undefined;

    if (initTradeType !== "PositionStart") {
      if (openTrades.length > selectedJournalRowIndex) {
        const collapsedTrades: ICollapseTradeTableData[] =
          openTrades[selectedJournalRowIndex]?.collapsedTrades;
        console.log(openTrades);
        if (collapsedTrades !== undefined && collapsedTrades.length !== 0) {
          prevTrade = collapsedTrades[0];
          console.log(prevTrade);
        } else {
          if (closedTrades.length > selectedJournalRowIndex) {
            const collapsedTrades: ICollapseTradeTableData[] =
              closedTrades[selectedJournalRowIndex]?.collapsedTrades;
            console.log(closedTrades);
            if (collapsedTrades !== undefined && collapsedTrades.length !== 0) {
              prevTrade = collapsedTrades[0];
              console.log(prevTrade);
            }
          }
        }
      }
    }

    if (initTradeType === "Buy" || initTradeType === "Sell") {
      if (selectedJournalRowIndex === -1 || selectedJournalGroupId === "")
        return;

      const collapsedTrades =
        closedTrades[selectedJournalRowIndex]?.collapsedTrades;

      if (selectedJournalType === "CLOSED") {
        const secondLastTrade = collapsedTrades
          ? collapsedTrades[collapsedTrades.length - 2]
          : null;
        const lastTrade = collapsedTrades
          ? collapsedTrades[collapsedTrades.length - 1]
          : null;

        if (secondLastTrade && lastTrade) {
          const secondLastTradeTime = dayjs(secondLastTrade.tradeTime);
          const lastTradeTime = dayjs(lastTrade.tradeTime);

          const middleTimeInMilliseconds =
            (secondLastTradeTime.valueOf() + lastTradeTime.valueOf()) / 2;
          newTradeTime = dayjs(middleTimeInMilliseconds).toISOString();
        }
      }
    }

    if (initTradeType === "PositionEnd") {
      if (selectedJournalRowIndex === -1 || selectedJournalGroupId === "")
        return;
      const collapsedTrades: ICollapseTradeTableData[] =
        closedTrades[selectedJournalRowIndex]?.collapsedTrades;

      if (selectedJournalType === "CLOSED") {
        const hasPositionEnd = collapsedTrades.some(
          (trade) => trade.tradeType === "PositionEnd"
        );

        if (hasPositionEnd) {
          enqueueSnackbar("최종 매도는 중복 생성이 불가합니다.", {
            variant: "error",
            autoHideDuration: 3000,
          });
          return;
        }
      } else {
        const collapsedTrades: ICollapseTradeTableData[] =
          openTrades[selectedJournalRowIndex]?.collapsedTrades;
        if (collapsedTrades !== undefined && collapsedTrades.length !== 0) {
          positionEndSize = collapsedTrades[collapsedTrades.length - 1].allSize;
        }
      }
    }

    const newTrade: TradeDataRecord = {
      id: uuidv4(), // 고유 ID
      tradeType: initTradeType,
      groupId: initGroupId || `new_${Date.now()}`,
      orderId: `new_${Date.now()}`, // initOrderId가 없을 때만 새로운 orderId 생성
      tradeTime: dayjs(newTradeTime).valueOf(),
      exchange: prevTrade !== undefined ? prevTrade.exchange : "비트겟",
      symbol: prevTrade !== undefined ? prevTrade.symbol : "BTCUSDT",
      position: prevTrade !== undefined ? prevTrade.position : "LONG",
      leverage: prevTrade !== undefined ? prevTrade.leverage : 1,
      marketType:
        prevTrade !== undefined ? prevTrade.marketType : "USDT-FUTURES",
      entryAmount: 0,
      entryPrice: 0,
      stopLossPrice: 0,
      takeProfitPrice: 0,
      profit: 0,
      commission: 0,
      profitLossRatio: "",
      tags: [],
      notes: "",
      link: "",
      size: initTradeType === "PositionEnd" ? positionEndSize : 0,
    };

    await dispatch(
      addTrade({
        journalId: selectedJournal.Jid,
        trade: newTrade,
        journalType: selectedJournalType,
        shouldUpdateJournal: true,
      })
    )
      .then((result) => {
        if (result.meta.requestStatus === "fulfilled") {
          enqueueSnackbar(
            `새로운 "${TradeTypeMap[initTradeType]}" 데이터를 추가하였습니다.`,
            {
              variant: "info",
              autoHideDuration: 2000,
            }
          );
          dispatch(setSelectedJournalGroupId(newTrade.groupId!));
          // addTrade 가능여부 체크 및 업데이트 성공
          if (initTradeType === "PositionStart") {
            dispatch(setSelectedJournalType("OPEN"));
            dispatch(setEditDrawerOpen(true));
          } else if (initTradeType === "PositionEnd") {
            // 새로운 거래가 포함된 openTrades의 첫 번째 collapsedTrade의 인덱스를 설정
            dispatch(setSelectedJournalType("CLOSED"));
            enqueueSnackbar(`해당 거래 내역을 "CLOSED" 로 이동합니다.`, {
              variant: "info",
              autoHideDuration: 2000,
            });
          }
          dispatch(setChangedGroupId(newTrade.groupId!));
        } else {
          dispatch(setChangedGroupId(""));
          enqueueSnackbar(
            `새로운 "${TradeTypeMap[initTradeType]}" 데이터를 추가에 실패하였습니다. : ${result.payload}`,
            {
              variant: "error",
              autoHideDuration: 3000,
            }
          );
        }
      })
      .catch((error) => {
        // 업데이트 실패, 경고 메시지 표시 및 백업
        dispatch(setChangedGroupId(""));
        enqueueSnackbar(
          `새로운 "${TradeTypeMap[initTradeType]}" 데이터를 추가에 실패하였습니다. : ${error}`,
          {
            variant: "error",
            autoHideDuration: 3000,
          }
        );
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        component={"main"}
        sx={{
          flexGrow: editDrawerOpen ? 0.8 : 1,
          transition: "flex-grow 0.3s",
          overflow: "hidden",
          maxWidth: matches ? "100%" : "90%",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Stack spacing={3}>
              <CustomCard
                key="challenge_card"
                titleText={`⭐ 챌린지 진행도`}
                isInfoButton={true}
                infoText={
                  " 해당 일지에서 목표로 한 수익금 달성도를 보여줍니다."
                }
                infoPlacement="top"
                titlePanel={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <CustomTooltipIcon
                        placement="top"
                        title={
                          (selectedJournal.challengeAmount &&
                            totalProfit &&
                            `${Math.round(
                              (totalProfit / selectedJournal.challengeAmount) *
                                100
                            )}% 달성`) ||
                          "0% 달성"
                        }
                        children={
                          <CustomProgressLine
                            variant="determinate"
                            value={
                              (selectedJournal.challengeAmount &&
                                totalProfit &&
                                ((totalProfit - 0) * 100) /
                                  (selectedJournal.challengeAmount - 0)) ||
                              0
                            }
                          />
                        }
                      ></CustomTooltipIcon>
                    </Box>
                    <Box sx={{ marginLeft: 1, flexShrink: 0 }}>
                      <Typography
                        color={theme.palette.secondary.main}
                        fontSize={14}
                        fontWeight={"semi-bold"}
                        textAlign={"center"}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        {`${
                          (totalProfit && totalProfit.toLocaleString()) || 0
                        }`}{" "}
                        /{" "}
                        {(selectedJournal &&
                          selectedJournal.challengeAmount &&
                          selectedJournal.challengeAmount.toLocaleString()) ||
                          0}{" "}
                        USDT
                      </Typography>
                    </Box>

                    <Box sx={{ marginLeft: 1, flexShrink: 0 }}>
                      {matches ? null : (
                        <Button
                          size="small"
                          variant="outlined"
                          sx={{ borderRadius: 4, borderWidth: 1.4 }}
                          onClick={() => {
                            setChallengeDialogOpen(true);
                            setTempChallengeAmount(
                              (selectedJournal.challengeAmount &&
                                selectedJournal.challengeAmount.toLocaleString()) ||
                                ""
                            );
                          }}
                        >
                          변경
                        </Button>
                      )}

                      <Dialog
                        open={challengeDialogOpen}
                        onClose={() => {
                          setChallengeDialogOpen(false);
                        }}
                      >
                        <DialogTitle>챌린지 진행도</DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                            변경할 챌린지 목표가를 입력해주세요.
                          </DialogContentText>
                          <CssTextField
                            placeholder="비어있음"
                            value={tempChallengeAmount}
                            onChange={handleTempChallengeAmountChange}
                            onBlur={handleTempChallengeAmountBlur}
                            onKeyDown={(e) =>
                              handleKeyDown(e, handleTempChallengeAmountBlur)
                            }
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  USDT
                                </InputAdornment>
                              ),
                            }}
                          ></CssTextField>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={() => {
                              setChallengeDialogOpen(false);
                            }}
                          >
                            취소
                          </Button>
                          <Button onClick={handleSaveClick} color="primary">
                            변경
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Box>
                  </Box>
                }
              />
              <CustomCard
                key="OpenTradeJouranl"
                titleText={`🔥 진행 중인 거래 (${openTradesNodeList.length})`}
                subTitleText={`마지막 수정 일자 ${dayjs(
                  journalLastUpdateAt
                ).format("YYYY-MM-DD HH:mm:ss")}`}
                subTitleIcon={<CheckCircleRoundedIcon />}
                isActionButton={
                  selectedJournal.exchangeMap &&
                  Object.keys(selectedJournal.exchangeMap).length === 0
                    ? true
                    : false
                }
                actionButtionIcon={<AddIcon />}
                actionButtionText="새로운 거래 작성"
                isInfoButton={true}
                infoText={
                  " 현재 진행 중인 거래 기록들을 보여줍니다.\n상세 과정의 화살표를 클릭하면 상세 거래 기록을 볼 수 있습니다.\n더블 클릭해서 편집창을 열어 기록할 수 있습니다."
                }
                onClick={() => {
                  handleNewTrade({ initTradeType: "PositionStart" });
                }}
                child={
                  (loading && loading) || tradeLoading ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: 10,
                      }}
                    >
                      <CircularProgress size="30px" />
                    </Box>
                  ) : (
                    <CustomTable
                      rows={openTradesNodeList}
                      headerNames={openTradeJournalHeaderList}
                      isExpanded={true}
                      expandedTableHeaderNames={expandedTradeJournalHeaderList}
                      expandedTableData={openTrades.map(
                        (trade: IOpenTradeTableData) =>
                          trade.collapsedTrades.map(
                            (collapsedTrade: ICollapseTradeTableData) =>
                              createCollapseTableData(collapsedTrade)
                          )
                      )}
                      onRowDoubleClick={(rowIndex) => {
                        dispatch(setEditDrawerOpen(true));
                        dispatch(setSelectedJournalRowIndex(rowIndex));
                        dispatch(
                          setSelectedJournalGroupId(
                            openTrades[rowIndex].groupId || ""
                          )
                        );
                        dispatch(setSelectedJournalType("OPEN"));
                      }}
                    />
                  )
                }
                isExpanded={false}
              />
              <CustomCard
                key="ClosedTradeJouranl"
                titleText={`🎉 종료된 거래 (${closedTradesNodeList.length})`}
                subTitleText={`마지막 수정 일자 ${dayjs(
                  journalLastUpdateAt
                ).format("YYYY-MM-DD HH:mm:ss")}`}
                subTitleIcon={<CheckCircleRoundedIcon />}
                isInfoButton={true}
                infoText={
                  "종료된 거래 기록들을 보여줍니다.\n상세 과정의 화살표를 클릭하면 상세 거래 기록을 볼 수 있습니다.\n더블 클릭해서 편집창을 열어 기록할 수 있습니다."
                }
                isActionPanel={matches ? false : true}
                actionPanel={
                  <TradeSearchPanel
                    exchange={exchangeState}
                    markeyType={marketTypeState}
                    position={positionState}
                    leverage={leverageState}
                    allNoteOptions={allNoteOptions}
                    selectedNoteOptions={selectedNoteOptions}
                    allSymbolOptions={allSymbolOptions}
                    selectedSymbolOptions={selectedSymbolOptions}
                    tags={tagsState}
                    winLose={winLoseState}
                    allTags={allTags}
                    setExchange={setExchangeState}
                    setMarketType={setMarketTypeState}
                    setPosition={setPositionState}
                    setLeverage={setLeverageState}
                    setAllNoteOptions={setAllNoteOptions}
                    setSelectedNoteOptions={setSelectedNoteOptions}
                    setSelectedSymbolOptions={setSelectedSymbolOptions}
                    setTags={setTagsState}
                    setWinLose={setWinLoseState}
                    setFilters={setFilters}
                  />
                }
                actionWidth={"80%"}
                child={
                  (loading && loading) || tradeLoading ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: 10,
                      }}
                    >
                      <CircularProgress size="30px" />
                    </Box>
                  ) : (
                    <CustomTable
                      rows={closedTradesNodeList}
                      headerNames={closedTradeJournalHeaderList}
                      isExpanded={true}
                      expandedTableHeaderNames={expandedTradeJournalHeaderList}
                      expandedTableData={closedTrades.map(
                        (trade: IClosedTradeTableData) =>
                          trade.collapsedTrades.map(
                            (collapsedTrade: ICollapseTradeTableData) =>
                              createCollapseTableData(collapsedTrade)
                          )
                      )}
                      onRowDoubleClick={(rowIndex) => {
                        dispatch(setEditDrawerOpen(true));
                        dispatch(setSelectedJournalRowIndex(rowIndex));
                        dispatch(
                          setSelectedJournalGroupId(
                            closedTrades[rowIndex].groupId || ""
                          )
                        );
                        dispatch(setSelectedJournalType("CLOSED"));
                      }}
                    />
                  )
                }
                isActionButton={false}
                isExpanded={false}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <CustomEditDrawer
        key="EditDrawer"
        title={`새 거래 기록 작성 ${selectedJournalType}`}
        anchor="right"
        titleButtons={[
          selectedJournal.exchangeMap &&
          Object.keys(selectedJournal.exchangeMap).length === 0
            ? addButtonNode
            : null,
          <CustomTooltipIcon
            children={
              <IconButton
                aria-label="info"
                size="small"
                sx={{ color: theme.palette.primary.light }}
              >
                <InfoIcon />
              </IconButton>
            }
            title={[
              "거래 기록을 편집하는 곳입니다.\n",
              "\n",
              "거래 기록을 구분하는 타입의 종류는 4 가지 입니다.\n",
              "\n",
              "1. 최초 진입\n",
              "거래 중인 기록 우측에 있는 새로운 거래 작성 클릭 자동 생성됩니다.\n",
              "\n",
              "2. 매수 (선택) : 우측 버튼으로 추가\n",
              "추가적으로 매수하여 진입 비중을 늘렸을 경우 작성합니다.\n",
              "\n",
              "3. 매도 (선택) : 우측 버튼으로 추가\n",
              "추가적으로 매도하여 진입 비중을 줄였을 경우 작성합니다.\n",
              "\n",
              "4. 최종 매도 : 우측 버튼으로 추가\n",
              "거래가 종료되었을 때 작성합니다.\n",
              "최종 매도가 있는 거래기록은 자동으로 'CLOSED' 로 넘어갑니다.\n",
              "\n",
              "추가된 거래기록은 읽기모드이며 수정 시,\n",
              "카드 우측 상단 수정 아이콘 버튼을 클릭해주세요.\n",
              "\n",
              "※ 모든 거래기록은 거래시간이 순차적으로 작성되어져야 합니다.\n",
              "최초 진입 → 매수 또는 매도 → 최종 매도 \n",
            ]}
            placement="bottom"
          />,
        ]}
        children={
          selectedJournalRowIndex !== -1
            ? selectedJournalType === "OPEN"
              ? openTrades[selectedJournalRowIndex]?.collapsedTrades.map(
                  (collapseData: ICollapseTradeTableData, index: number) => (
                    <CustomCard
                      key={`${collapseData.groupId}_${collapseData.num}_${collapseData.id}_edit_collapse_open`}
                      isBookmark={true}
                      bookmarkText={`#${index + 1} ${
                        TradeTypeMap[collapseData.tradeType]
                      }`}
                      bookmarkStyle={{
                        backgroundColor:
                          TradeTypeForStyle[collapseData.tradeType],
                      }}
                      child={<EditTradeTemplate {...collapseData} />}
                    />
                  )
                )
              : selectedJournalType === "CLOSED"
              ? closedTrades[selectedJournalRowIndex]?.collapsedTrades.map(
                  (collapseData: ICollapseTradeTableData, index: number) => (
                    <CustomCard
                      key={`${collapseData.groupId}_${collapseData.num}_${collapseData.id}_edit_collapse_closed`}
                      isBookmark={true}
                      bookmarkText={`#${index + 1} ${
                        TradeTypeMap[collapseData.tradeType]
                      }`}
                      bookmarkStyle={{
                        backgroundColor:
                          TradeTypeForStyle[collapseData.tradeType],
                      }}
                      child={<EditTradeTemplate {...collapseData} />}
                    />
                  )
                )
              : []
            : []
        }
        //rowIndex={selectedjournal}
        open={editDrawerOpen}
        onClose={(open: boolean) => {
          dispatch(setEditDrawerOpen(open));
        }}
        initialWidth={editDrawerMinWidth}
        minWidth={editDrawerMinWidth}
        onResize={handleResize} // onResize 콜백 전달
      />
      <Box
        sx={{
          display: "flex",
          width: editDrawerOpen ? editDrawerWidth : 0, // drawerWidth 사용
          transition: "width 0.3s",
        }}
      ></Box>
      {/* 다이얼로그 추가 */}
      <Dialog
        open={editDialogOpen}
        onClose={() => dispatch(setEditDialogOpen(false))}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {editDialogType === "confirm" ? "확인" : "경고"}
        </DialogTitle>
        <DialogContent>
          <Typography style={{ whiteSpace: "pre-line" }}>
            {alertEditDialogMessage}
          </Typography>
        </DialogContent>
        <DialogActions>
          {editDialogType === "confirm" ? (
            <>
              <Button
                onClick={() => dispatch(setEditDialogOpen(false))}
                color="primary"
              >
                취소
              </Button>
              <Button
                onClick={() => {
                  if (editDialogAction) editDialogAction();
                  dispatch(setEditDialogOpen(false));
                }}
                color="primary"
                autoFocus
              >
                생성
              </Button>
            </>
          ) : (
            <Button
              onClick={() => dispatch(setEditDialogOpen(false))}
              color="primary"
              autoFocus
            >
              확인
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default JournalDetailPage;
