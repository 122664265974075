import { ReactNode } from "react";
import { getIconAndName } from "../pages/Journal/CommonTableNode";
import createCoinIconTable from "./Icons/CoinSymbols/IconMapTable";
import createExchangeIconTable from "./Icons/Exchanges/IconMapTable";

export interface AllExchangeNodeTableType {
  [key: string]: ReactNode;
}

export interface AllSymbolNodeTableType {
  [key: string]: ReactNode;
}

export const iconWidth = 18;
export const iconHeight = 18;

export const ExchangeIconTable = createExchangeIconTable(iconWidth, iconHeight);

export const ExchangeIconTable40 = createExchangeIconTable(40, 40);

export const allExchanges: AllExchangeNodeTableType = Object.keys(
  ExchangeIconTable
).reduce((acc, exchangeName) => {
  acc[exchangeName] = getIconAndName(
    exchangeName,
    ExchangeIconTable[exchangeName]
  );
  return acc;
}, {} as AllExchangeNodeTableType);

export const CoinIconTable = createCoinIconTable(iconWidth, iconHeight);

export const allSymbols: AllSymbolNodeTableType = Object.keys(
  CoinIconTable
).reduce((acc, coinName) => {
  acc[coinName] = getIconAndName(coinName, CoinIconTable[coinName]);
  return acc;
}, {} as AllSymbolNodeTableType);
