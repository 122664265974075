import { IconButton } from "@mui/material";
import { round, TIME_FORMAT } from "../../../utils/utils";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  genProfitNode,
  genTypoNode,
  menuButtonNode,
  Tags,
  TradeTypeMap,
} from "../CommonTableNode";

import dayjs, { Dayjs } from "dayjs";
import { ICollapseTradeTableData } from "../types";

// interface ICollapseTradeTableData {
//   orderId: string;
//   num: string;
//   tradeType: TradeType;
//   tradeTime: Dayjs;
//   allEntryAmount: number;
//   entryAmount: number;
//   entryPrice: number;
//   profit: number;
//   commission?: number;
//   tags?: string[];
// }

export function createCollapseTableData(props: ICollapseTradeTableData) {
  const LongTextColor = "#1FCA66";
  const ShortTextColor = "#F56565";
  const LongColor = "#68D391";
  const ShortColor = "#F56565";

  const isBuyOrSell =
    props.tradeType === "PositionStart" || props.tradeType === "Buy"
      ? true
      : props.tradeType === "PositionEnd" || props.tradeType === "Sell"
      ? false
      : null;

  const color =
    isBuyOrSell === null ? undefined : isBuyOrSell ? "#1FCA66" : "#F56565";

  const changeExtraAmount = isBuyOrSell
    ? props.allEntryAmount + (props.entryAmount || 0)
    : props.allEntryAmount - (props.entryAmount || 0);

  const changeAmountRatio = isBuyOrSell
    ? props.size === props.allSize
      ? props.size === 0
        ? 0
        : 100
      : Math.abs(
          round(((props.size || 0) / (props.allSize - props.size!)) * 100.0, 1)
        )
    : props.size === props.allSize
    ? props.size === 0
      ? 0
      : Math.abs(
          round(((props.size || 0) / (props.allSize + props.size!)) * 100.0, 1)
        )
    : Math.abs(
        round(((props.size || 0) / (props.allSize + props.size!)) * 100.0, 1)
      );
  const numNodeNode = genTypoNode({ data: props.num || 0, fontWeight: "600" });

  const tradeTimeNode = genTypoNode({
    data:
      (props.tradeTime &&
        dayjs(props.tradeTime).isValid() &&
        dayjs(props.tradeTime).format(TIME_FORMAT)) ||
      dayjs().format(TIME_FORMAT),
  });

  // const allEntryAmountNode = genTypoNode({
  //   data: props.allEntryAmount,
  //   leadingText: "\nUSDT",
  //   isPrice: true,
  // });

  const allQuantityNode = genTypoNode({
    data: props.allSize,
    isPrice: true,
  });

  const changeAmountNode = genTypoNode({
    data: props.entryAmount || 0,
    suffixText: isBuyOrSell && isBuyOrSell ? "+" : "-",
    leadingText: "\nUSDT",
    isPrice: true,
  });

  const changeAmountRatioNode = genTypoNode({
    data: changeAmountRatio,
    suffixText: isBuyOrSell && isBuyOrSell ? "+" : "-",
    leadingText: " %",
    isPrice: true,
  });

  const buyPriceNode = genTypoNode({
    data: isBuyOrSell && isBuyOrSell ? props.entryPrice! : 0,
    leadingText: "\nUSDT",
    isPrice: true,
  });

  const sellPriceNode = genTypoNode({
    data: isBuyOrSell && isBuyOrSell ? 0 : props.entryPrice!,
    leadingText: "\nUSDT",
    isPrice: true,
  });

  const profitNode = genProfitNode({
    profit: props.profit || 0,
    entryAmount:
      props.allEntryAmount === 0
        ? props.entryAmount || 0
        : props.allEntryAmount,
  });

  const buyOrSellNode = genTypoNode({
    data: TradeTypeMap[props.tradeType],
    color: color,
  });

  const commissionNode = genTypoNode({
    data: props.commission ?? 0,
    //suffixText: props.commission ? "-" : "",
    suffixText: props.commission ? "" : "",
    leadingText: "\nUSDT",
    isPrice: true,
  });

  const tagsNode = props.tags ? <Tags tags={props.tags} /> : "";

  // 메뉴 항목 데이터
  const menuItems = [
    {
      content: "✏️ 수정",
      onClick: () => alert("수정"),
    },
    {
      content: "🗑️ 삭제",
      onClick: () => alert("삭제"),
    },
  ];

  // 다양한 버튼 내용 데이터
  const buttonContent = (
    <IconButton>
      <MoreVertIcon />
    </IconButton>
  );

  const menuNode = menuButtonNode({ buttonContent, menuItems });

  return [
    numNodeNode,
    tradeTimeNode,
    allQuantityNode,
    changeAmountNode,
    changeAmountRatioNode,
    buyPriceNode,
    sellPriceNode,
    profitNode,
    buyOrSellNode,
    commissionNode,
    tagsNode,
    //menuNode,
  ];
}

export const expandedTradeJournalHeaderList: Array<string> = [
  "거래 순서",
  "거래 시간",
  "전체 보유량\n(코인 갯수)",
  "비중변화\n(진입대비)",
  "비중변화율\n(이전대비)",
  "매수가",
  "매도가",
  "수익",
  "매수/매도",
  "수수료",
  "세부 태그",
  //"", //icon을 위한 빈공간
];
