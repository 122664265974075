import {
  GenDetailsNode,
  genProfitNode,
  genTypoNode,
  getIconAndName,
  menuButtonNode,
  PositionLeverageText,
  Tags,
} from "../../CommonTableNode";
import { ExchangeIconTable } from "../../../../components/Icons/Exchanges";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton } from "@mui/material";
import createExchangeIconTable from "../../../../components/Icons/Exchanges/IconMapTable";
import dayjs from "dayjs";
import { IconHeight, IconWidth, TIME_FORMAT } from "../../../../utils/utils";
import { IClosedTradeTableData } from "../../types";
import createCoinIconTable from "../../../../components/Icons/CoinSymbols/IconMapTable";

export function createClosedTableData(props: IClosedTradeTableData) {
  const LongColor = "#68D391";
  const ShortColor = "#F56565";

  //console.log(props.allEntryAmount);
  const posColor =
    props.position === "LONG" || props.position === "BUY"
      ? LongColor
      : ShortColor;

  const buyCount = props.buyCount ?? 1;
  const sellCount = props.sellCount ?? 1;

  const buySellCountText = `${buyCount} / ${sellCount}`;

  const numNodeNode = genTypoNode({
    data: props.num || 1,
    suffixText: "#",
    fontWeight: "600",
  });

  const tradeTimeNode = genTypoNode({
    data:
      (props.tradeTime &&
        dayjs(props.tradeTime).isValid() &&
        dayjs(props.tradeTime).format(TIME_FORMAT)) ||
      dayjs().format(TIME_FORMAT),
  });

  const ExchangeIconTable = createExchangeIconTable(IconWidth, IconHeight);

  const exchangeNode = getIconAndName(
    props.exchange || "바이낸스",
    ExchangeIconTable[props.exchange || "바이낸스"]
  );

  const marketTypeNode = genTypoNode({ data: props.marketType || "" });

  const positionLeverageNode = (
    <PositionLeverageText
      position={{ text: props.position || "", backgroundColor: posColor }}
      leverage={{ text: `X${props.leverage}`, backgroundColor: posColor }}
    />
  );

  const CoinIconTable = createCoinIconTable(IconWidth, IconHeight);

  const symbolNode = getIconAndName(
    props.symbol || "BTCUSDT",
    CoinIconTable[props.symbol || "BTCUSDT"]
  );

  const entryAmountNode = genTypoNode({
    data: props.allEntryAmount || 0,
    leadingText: "\nUSDT",
    isPrice: true,
  });

  const entryPriceNode = genTypoNode({
    data: props.avgEntryPrice,
    leadingText: "\nUSDT",
    isPrice: true,
  });

  const exitPriceNode = genTypoNode({
    data: props.avgExitPrice,
    leadingText: "\nUSDT",
    isPrice: true,
  });

  const profitNode = genProfitNode({
    profit: props.allProfit || 0,
    entryAmount: props.allEntryAmount || 0,
  });

  const detailsNode = (
    <GenDetailsNode text={buySellCountText} open={false} setOpen={() => {}} />
  );

  const profitLossRatioNode = genTypoNode({
    data: props.profitLossRatio || 0,
  });

  const tagsNode = props.allTags ? <Tags tags={props.allTags} /> : "";

  // 메뉴 항목 데이터
  const menuItems = [
    {
      content: "✏️ 수정",
      onClick: () => alert("수정"),
    },
    {
      content: "🗑️ 삭제",
      onClick: () => alert("삭제"),
    },
  ];

  // 다양한 버튼 내용 데이터
  const buttonContent = (
    <IconButton>
      <MoreVertIcon />
    </IconButton>
  );

  const menuNode = menuButtonNode({ buttonContent, menuItems });

  return [
    //menuNode,
    numNodeNode,
    tradeTimeNode,
    exchangeNode,
    symbolNode,
    //marketTypeNode,
    positionLeverageNode,
    entryAmountNode,
    entryPriceNode,
    exitPriceNode,
    profitNode,
    detailsNode,
    profitLossRatioNode,
    tagsNode,
  ];
}

export const closedTradeJournalHeaderList: Array<string> = [
  //"", //icon을 위한 빈공간
  "거래 번호",
  "종료 시간",
  "거래소",
  "종목",
  //"시장",
  "포지션",
  "진입 비중",
  "진입가\n(평단가)",
  "매도가\n(평단가)",
  "수익",
  "상세 과정\n(매수 / 매도)",
  "손익비",
  "태그",
];
