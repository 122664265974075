import { keyframes } from "@mui/material";
import dayjs from "dayjs";
import React from "react";

// undefined 값을 제거하는 함수
export const removeUndefinedFields = (data: { [key: string]: any }) => {
  return Object.fromEntries(
    Object.entries(data).filter(([_, value]) => value !== undefined)
  );
};

// 객체를 { [x: string]: any } 형태로 변환하는 함수
export const convertToFirestoreData = (
  data: object
): { [key: string]: any } => {
  return Object.entries(data).reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {} as { [key: string]: any });
};

export const renderWithLineBreaks = (text: string | number) => {
  if (typeof text === "string") {
    if (text.includes("\n")) {
      return text.split("\n").map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ));
    }
  }

  return text;
};

export function round(value: number, precision: number): number {
  if (precision === null) {
    precision = 10;
  }
  precision = Math.min(Math.max(0, precision), 20);
  const v = (+value).toFixed(precision);
  return +v;
}

export function localeStringToNumber(localeString: string) {
  // 모든 지역 구분 문자를 제거합니다 (예: 천 단위 구분 기호, 공백 등)
  const numberString = localeString.replace(/[^\d.-]/g, "");

  return round(parseFloat(numberString), 2);
}

export const formatNumber = (num: number): string => {
  return num % 1 === 0 ? num.toString() : num.toFixed(2);
};

export const TIME_FORMAT = "YYYY/MM/DD HH:mm:ss";

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const fadeInDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-14px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const fadeOutUp = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-14px);
  }
`;

// 직접 min과 max 함수 구현
export function minDayjs(dates: dayjs.Dayjs[]): dayjs.Dayjs {
  return dates.reduce(
    (min, curr) => (curr.isBefore(min) ? curr : min),
    dates[0]
  );
}

export function maxDayjs(dates: dayjs.Dayjs[]): dayjs.Dayjs {
  return dates.reduce(
    (max, curr) => (curr.isAfter(max) ? curr : max),
    dates[0]
  );
}

export const IconWidth = 24;
export const IconHeight = 24;
