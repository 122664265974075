// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { initializeFirestore } from "firebase/firestore";
import { setLogLevel as setFirestoreLogLevel } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// setFirestoreLogLevel('debug');
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyD2xA0BnqcyxCw-nkqAR6AYqeeVGEXuJb4",
//   authDomain: "tradememotest.firebaseapp.com",
//   projectId: "tradememotest",
//   storageBucket: "tradememotest.appspot.com",
//   messagingSenderId: "805790792436",
//   appId: "1:805790792436:web:fd1c434a9d209f4a613ca1",
// };

//memememo.official 계정 config
const firebaseConfig = {
  apiKey: "AIzaSyBTM7L1FA8zDISCIYsRo2tOwRmMapZpafk",
  authDomain: "memememo-650da.firebaseapp.com",
  projectId: "memememo-650da",
  storageBucket: "memememo-650da.appspot.com",
  messagingSenderId: "896825818239",
  appId: "1:896825818239:web:ff9250b62434ac9811d34a",
  measurementId: "G-X18E8LREZ9"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
// export const db = initializeFirestore(app, { experimentalForceLongPolling: true })
// export const db = initializeFirestore(app, {
//   experimentalForceLongPolling: true,

// });
