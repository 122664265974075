// overviewSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { AppDispatch, RootState } from "../../../store";

import {
  IHistoryStackData,
  IJournalTableData,
  IProgressTableData,
} from "../../../../pages/Journal/types";
import {
  sortHistoryTradeDataByTradeTime,
  sortJournalsByCreateAt,
  sortProgressingTradeDataByTradeTime,
} from "../../../api/journalDataProcessing";

import {
  convertToFirestoreData,
  removeUndefinedFields,
  TIME_FORMAT,
} from "../../../../utils/utils";
import dayjs from "dayjs";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../../../firebase";
import { UserState } from "../../user";

interface JournalState {
  journals: IJournalTableData[];
  progressingTrades: IProgressTableData[];
  historyData: IHistoryStackData[];
  addLoading: boolean;
  journalUpdatedAt: number;
  loading: boolean;
  error: string | null;
}

const initialState: JournalState = {
  journals: [],
  progressingTrades: [],
  historyData: [],
  journalUpdatedAt: dayjs().valueOf(),
  addLoading: false,
  loading: false,
  error: null,
};

// fetchJournals 비동기 작업 정의
export const fetchJournals = createAsyncThunk(
  "journalData/fetchJournals",
  async (
    { journalIds }: { journalIds: string[] }, // 특정 문서 ID들을 받기 위한 인자
    { rejectWithValue }
  ) => {
    try {
      // 10개 이하의 문서 ID들만 in 연산자로 한 번에 가져올 수 있음
      const batchSize = 10;
      const promises = [];

      for (let i = 0; i < journalIds.length; i += batchSize) {
        const batchIds = journalIds.slice(i, i + batchSize); // ID를 batchSize 크기로 나눔
        const q = query(
          collection(db, "journals"),
          where("Jid", "in", batchIds) // 'in' 연산자로 문서 조회
        );

        promises.push(getDocs(q)); // 각 쿼리의 Promise를 저장
      }

      const snapshots = await Promise.all(promises); // 모든 쿼리 실행 후 결과를 기다림
      const journals: IJournalTableData[] = [];

      snapshots.forEach((snapshot) => {
        snapshot.forEach((doc) => {
          const journal = doc.data();
          journals.push({
            Jid: doc.id, // 문서 ID를 Jid로 사용x
            title: journal.title,
            totalTradeCount: Number(journal.totalTradeCount),
            winRate: Number(journal.winRate),
            profitRate: Number(journal.profitRate),
            challengeAmount: journal.challengeAmount || 0,
            exchangeMap: journal.exchangeMap || {},
            createdAt: journal.createdAt,
            updatedAt: journal.updatedAt,
            loseTradeCount: journal.loseTradeCount,
            winTradeCount: journal.winTradeCount,
            totalOrderCount: journal.totalOrderCount,
            totalProfit: journal.totalProfit,
            marketType: journal.marketType,
          });
        });
      });

      return journals;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// addJournal을 위한 createAsyncThunk
export const addJournal = createAsyncThunk(
  "journalData/addJournal",
  async (
    {
      newJournalData,
      user,
    }: { newJournalData: IJournalTableData; user: UserState },
    { getState, rejectWithValue }
  ) => {
    const state = getState() as RootState;
    const allJournalData: IJournalTableData[] =
      state.journalData.journals || [];

    // 제목이 중복되는지 확인
    const index = allJournalData.findIndex(
      (journalData) => journalData.title === newJournalData.title
    );

    // 제목이 중복되지 않는 경우
    if (index === -1) {
      try {
        // Firestore에 추가 작업 수행
        const docRef = doc(db, "journals", newJournalData.Jid); // newJournalData.Jid를 문서 ID로 사용
        await setDoc(
          docRef,
          convertToFirestoreData(removeUndefinedFields(newJournalData))
        ); // newJournalData를 Firestore에 저장

        // user 정보에 따른 users 서브컬렉션의 journals에 문서 추가
        const userJournalsDocRef = doc(
          db,
          `users/${user.uid}/journals`,
          newJournalData.Jid
        );
        await setDoc(userJournalsDocRef, {});

        return newJournalData; // 기존 로직 그대로 성공시 newJournalData 반환
      } catch (error: any) {
        // Firestore 저장 중 오류 발생 시 rejectWithValue 반환
        return rejectWithValue("Firestore Error");
      }
    } else {
      // 중복된 제목이 있을 경우 rejectWithValue 반환
      return rejectWithValue("duplicate");
    }
  }
);

// updateJournal을 위한 createAsyncThunk
export const updateJournal = createAsyncThunk(
  "journalData/updateJournal",
  async (
    {
      updateJournalData,
      exchangeUpdateDate,
    }: {
      updateJournalData: Partial<IJournalTableData>;
      exchangeUpdateDate?: number;
    },


    { getState, rejectWithValue }
  ) => {
    const state = getState() as RootState;
    const allJournalData: IJournalTableData[] =
      state.journalData.journals || [];

    // 저널이 존재하는지 확인
    const index = allJournalData.findIndex(
      (journalData) => journalData.Jid === updateJournalData.Jid
    );

    if (index !== -1 && updateJournalData.Jid) {
      try {
        // Firestore에서 문서 업데이트 수행
        const docRef = doc(db, "journals", updateJournalData.Jid);

        if (
          exchangeUpdateDate &&
          exchangeUpdateDate !== undefined &&
          exchangeUpdateDate !== 0
        ) {
          updateJournalData.updatedAt = exchangeUpdateDate;
          console.log("exchangeUpdateDate= ", updateJournalData.updatedAt);
          console.log(
            "exchangeUpdateDate=",
            dayjs(updateJournalData.updatedAt).format("YYYY-MM-DD HH:mm:ss")
          );
        }

        // 변환된 데이터를 updateDoc에 전달
        const updateData = convertToFirestoreData(
          removeUndefinedFields(updateJournalData)
        );

        await updateDoc(docRef, updateData);

        return updateJournalData; // 기존 로직 그대로 성공 시 updateJournalData 반환
      } catch (error: any) {
        // Firestore 업데이트 중 오류 발생 시 rejectWithValue 반환
        return rejectWithValue("Firestore 업데이트 중 오류가 발생했습니다.");
      }
    } else {
      // 저널이 없는 경우 rejectWithValue 반환
      return rejectWithValue("저널이 없습니다.");
    }
  }
);

// deleteJournal을 위한 createAsyncThunk
export const deleteJournal = createAsyncThunk(
  "journalData/deleteJournal",
  async (
    { journalId, user }: { journalId: string; user: UserState },
    { getState, rejectWithValue }
  ) => {
    try {
      // Firestore에서 해당 저널을 삭제
      const docRef = doc(db, "journals", journalId);
      await deleteDoc(docRef); // Firestore에서 문서 삭제

      // user 정보에 따른 users 서브컬렉션의 journals에 문서 추가
      const userJournalsDocRef = doc(
        db,
        `users/${user.uid}/journals`,
        journalId
      );
      await deleteDoc(userJournalsDocRef);

      return journalId; // 삭제된 저널의 ID를 반환
    } catch (error: any) {
      // Firestore 삭제 중 오류 발생 시 rejectWithValue 반환
      return rejectWithValue("Firestore에서 저널 삭제 중 오류가 발생했습니다.");
    }
  }
);

const journalDataSlice = createSlice({
  name: "journalData",
  initialState,
  reducers: {
    setJournals(state, action: PayloadAction<IJournalTableData[]>) {
      state.journals = sortJournalsByCreateAt(action.payload);
    },
    setProgressingTrades(state, action: PayloadAction<IProgressTableData[]>) {
      state.progressingTrades = sortProgressingTradeDataByTradeTime(
        action.payload
      );
    },
    setHistoryData(state, action: PayloadAction<IHistoryStackData[]>) {
      state.historyData = sortHistoryTradeDataByTradeTime(action.payload);
    },
    setJournalUpdatedAt(state, action: PayloadAction<number>) {
      state.journalUpdatedAt = action.payload;
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchJournals.pending, (state) => {
        // 로딩 상태를 처리할 수 있습니다.
        state.journals = []; // 로딩 시 기존 데이터를 초기화할 수도 있습니다.
        state.loading = true; // 로딩 상태 시작
        state.error = null; // 기존 오류 초기화
      })
      .addCase(fetchJournals.fulfilled, (state, action) => {
        // Fetch 성공 시 상태 업데이트
        const sortedJournals = sortJournalsByCreateAt(action.payload);
        state.journals = sortedJournals;

        let updatedAt = 0;

        sortedJournals.forEach((journal) => {
          if (
            journal.exchangeMap &&
            Object.keys(journal.exchangeMap).length !== 0
          ) {
            if (journal.updatedAt !== 0) {
              updatedAt = journal.updatedAt;
            }
          }
        });

        if (updatedAt !== 0) {
          state.journalUpdatedAt = updatedAt;

        }

        state.loading = false; // 로딩 상태 시작
        state.error = null; // 기존 오류 초기화
      })
      .addCase(fetchJournals.rejected, (state, action) => {
        state.loading = false; // 로딩 상태 시작
        state.error = action.payload as string; // 기존 오류 초기화
        console.error(action.payload); // 에러 메시지 출력
      })
      .addCase(addJournal.pending, (state, action) => {
        state.addLoading = true;
        state.error = null;
      })
      .addCase(addJournal.fulfilled, (state, action) => {
        state.journals.unshift(action.payload);
        console.log(state.journals);
        state.addLoading = false;
        state.error = null;
      })
      .addCase(addJournal.rejected, (state, action) => {
        state.addLoading = false;
        state.error = action.payload as string;
        console.error(action.payload); // 에러 메시지 출력
      })
      .addCase(updateJournal.fulfilled, (state, action) => {
        const updatedJournal = action.payload;

        // Jid가 존재하는지 확인
        if (updatedJournal.Jid) {
          const index = state.journals.findIndex(
            (journal) => journal.Jid === updatedJournal.Jid
          );

          // 해당 저널이 존재할 경우 상태 업데이트
          if (index !== -1) {
            // 개별 필드만 업데이트 (Partial이기 때문에 일부 필드만 업데이트될 수 있음)
            state.journals[index] = {
              ...state.journals[index], // 기존 상태 유지
              ...updatedJournal, // 업데이트된 필드만 병합
            };
          }
        }
        state.loading = false;
        state.error = null;
      })
      .addCase(updateJournal.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
        console.error(action.payload); // 에러 메시지 출력
      })
      .addCase(deleteJournal.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteJournal.fulfilled, (state, action) => {
        // Firestore에서 삭제 성공 시 Redux 상태에서 저널 제거
        state.journals = state.journals.filter(
          (journal) => journal.Jid !== action.payload
        );

        state.loading = false;
        state.error = null;
      })
      .addCase(deleteJournal.rejected, (state, action) => {
        console.error(action.payload); // 에러 메시지 출력
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const {
  setJournals,
  setProgressingTrades,
  setHistoryData,
  setJournalUpdatedAt,
} = journalDataSlice.actions;

export default journalDataSlice.reducer;
