import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  IconButton,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactNode } from "react";
import { renderWithLineBreaks, round } from "../../utils/utils";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import CustomPopupMenu, {
  MenuPopupStateProps,
} from "../../components/CustomPopupMenu";

import RoundedTextBox, {
  RoundedTextBoxProps,
} from "../../components/RoundedTextBox";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { IAllTags, TagOptionType, TradeType } from "./types";
import theme from "../../theme";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { EditTradeMode } from "./DetailJournal/EditJournal/EditTradeTemplate";
import { NavigateOptions, useNavigate } from "react-router-dom";
import { paths } from "../../paths";
import dayjs from "dayjs";

export const StyledBox = styled(Box)(({ theme }) => ({
  borderColor: "transparent", // 기본적으로 border를 투명하게 설정
  borderWidth: "1.2px", // 기본 border 두께 설정
  borderRadius: "2px", // 기본 borderRadius 설정
  transition: "border-color 0.3s ease, border-width 0.3s ease", // transition 추가
  "&:hover": {
    borderColor: "#B2BAC2", // 호버 시 border 색상 설정
    borderWidth: "1.2px", // 호버 시 border 두께 설정
    borderRadius: "2px", // 기본 borderRadius 설정
  },
  "&.Mui-focused": {
    borderColor: "#6F7E8C", // 포커스 시 border 색상 설정
    borderWidth: "1.2px", // 포커스 시 border 두께 설정
    borderRadius: "2px", // 기본 borderRadius 설정
  },
}));

// 확장된 TextFieldProps 정의
type CustomTextFieldProps = TextFieldProps & {
  customVariant?: "default" | "customBorder";
};

// 스타일 확장
export const CssTextField = styled(
  ({ customVariant, ...props }: CustomTextFieldProps) => (
    <TextField {...props} />
  )
)(({ theme, customVariant }) => ({
  "& label.Mui-focused": {
    color: "#A0AAB4",
    transition: "color 0.3s ease", // transition 추가
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
    transition: "border-bottom-color 0.3s ease", // transition 추가
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: customVariant === "customBorder" ? "#B2BAC2" : "transparent", // 기본적으로 border를 투명하게 설정
      borderWidth: "1.2px", // 기본 border 두께 설정
      borderRadius: "2px", // 기본 borderRadius 설정
      color: theme.palette.secondary.main,
      transition: "border-color 0.3s ease, border-width 0.3s ease", // transition 추가
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2", // 호버 시 border 색상 설정
      borderWidth: "1.2px", // 호버 시 border 두께 설정
      borderRadius: "2px", // 기본 borderRadius 설정
    },
    "&.Mui-focused fieldset": {
      borderColor: "#6F7E8C", // 포커스 시 border 색상 설정
      borderWidth: "1.2px", // 포커스 시 border 두께 설정
      borderRadius: "2px", // 기본 borderRadius 설정
    },
    "&.Mui-disabled fieldset": {
      borderColor: "transparent", // disabled 상태에서 테두리 보이지 않도록 설정
      backgroundColor: "rgba(160, 170, 180, 0.1)",
    },
  },
  "& .MuiInputBase-input": {
    fontSize: "14px", // 폰트 크기 변경
    padding: 5,
    color: theme.palette.secondary.main,
  },
  "& .MuiInputLabel-root": {
    fontSize: "14px", // 라벨 폰트 크기 변경
    padding: 5,
    color: theme.palette.secondary.main,
  },
}));

export interface IPosition {
  position: ["LONG", "SHORT", "BUY", "SELL"];
}

export interface IGenTypoNode {
  data: string | number;
  fontWeight?: string | number;
  fontSize?: string | number;
  color?: string;
  suffixText?: string;
  leadingText?: string;
  isPrice?: boolean;
}

export const genTypoNode = ({
  data,
  fontWeight,
  fontSize,
  color,
  suffixText,
  leadingText,
  isPrice,
}: IGenTypoNode): ReactNode => {
  if (isPrice && data === 0) {
    data = "-";
    isPrice = false;
    leadingText = "-";
    suffixText = "-";
  }

  return (
    <Typography
      sx={{
        fontWeight: fontWeight ? fontWeight : "500",
        fontSize: fontSize ? fontSize : 12,
        color: color ? color : "#2D3748", //theme.palette.secondary.main
      }}
    >
      {renderWithLineBreaks(suffixText ? ` ${suffixText}` : "")}
      {""}
      {renderWithLineBreaks(isPrice ? data.toLocaleString() : data)}
      {""}
      {renderWithLineBreaks(leadingText ? `${leadingText} ` : "")}
    </Typography>
  );
};

export function getIconAndName(name: string, icon: JSX.Element): ReactNode {
  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "100%",
      }}
    >
      {/* BitgetIcon과 BinanceIcon에 직접적으로 스타일을 전달합니다. */}
      <div style={{ display: "flex", marginRight: "4px" }}>{icon}</div>{" "}
      {/* 아이콘과 텍스트 사이에 간격을 설정합니다. */}
      <div>{name}</div>
    </Box>
  );
}

export function getIconsAndNames(
  exchangeDatas: { [key: string]: string }[],
  iconMap: any
): ReactNode {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        width: "100%",
      }}
    >
      {exchangeDatas.map((exchangeData, index) => (
        <React.Fragment key={`${exchangeData["exchangeId"]}}`}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: "2px",
            }}
          >
            <div style={{ display: "flex", marginRight: "4px" }}>
              {iconMap[exchangeData["exchangeName"]]}
            </div>{" "}
            <div>{exchangeData["exchangeId"]}</div>
          </div>
        </React.Fragment>
      ))}
    </Box>
  );
}

interface PositionLeverageProps {
  position: RoundedTextBoxProps;
  leverage?: RoundedTextBoxProps;
}

export const PositionLeverageText: React.FC<PositionLeverageProps> = ({
  position,
  leverage,
}) => {
  const LongColor = "#68D391";
  const ShortColor = "#F56565";

  const posColor =
    position.text === "LONG" || position.text === "BUY"
      ? LongColor
      : ShortColor;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "row", sm: "row" },
        gap: { xs: 1, sm: 1 }, // 화면 크기에 따라 간격 조절
        justifyContent: "center",
      }}
    >
      <RoundedTextBox
        text={position.text}
        borderRadius={2}
        backgroundColor={position.backgroundColor ?? posColor}
        bold
      ></RoundedTextBox>{" "}
      {leverage &&
      leverage.text !== "" &&
      leverage.text !== "x" &&
      leverage.text !== "X" ? (
        <RoundedTextBox
          text={leverage.text}
          borderRadius={2}
          backgroundColor={leverage.backgroundColor ?? posColor}
          bold
        ></RoundedTextBox>
      ) : null}
    </Box>
  );
};

export interface ITagCheckBox {
  handleTagSelect: (name: string) => void;
  tagName: string;
}

export interface TagsProps {
  tags: TagOptionType[];
}

export const Tags: React.FC<TagsProps> = ({ tags }) => {
  const maxtags = 3;
  const [showAll, setShowAll] = useState(false);
  const visibleTags = showAll ? tags : tags.slice(0, maxtags);

  const handleDelete = () => {
    alert("You clicked the delete icon.");
  };

  const handleToggleShow = () => {
    setShowAll(!showAll);
  };

  return (
    <Stack
      direction="row"
      //spacing={1}
      alignItems="center"
      justifyContent="center"
      flexWrap="wrap"
      rowGap={1} // 위아래 간격 조정
      columnGap={1} // 좌우 간격 조정
    >
      {visibleTags.map((tag, index) => (
        <Chip
          key={index}
          label={tag.name}
          variant="outlined"
          size="small"
          //onDelete={handleDelete}
        />
      ))}
      {tags.length > maxtags && (
        <Typography
          variant="body2"
          color="textSecondary"
          onClick={handleToggleShow}
          style={{ cursor: "pointer" }}
        >
          {showAll ? "숨기기" : `+${tags.length - maxtags}`}
        </Typography>
      )}
    </Stack>
  );
};

export function menuButtonNode({
  buttonContent,
  menuItems,
}: MenuPopupStateProps): ReactNode {
  return (
    <CustomPopupMenu buttonContent={buttonContent} menuItems={menuItems} />
  );
}

export interface IGenDetailsNode {
  text: string;
  open: boolean;
  setOpen: () => void;
}

export const GenDetailsNode: React.FC<IGenDetailsNode> = ({
  text,
  open,
  setOpen,
}) => {
  const color = "#2D3748";

  const icon = !open ? (
    <ExpandMoreRoundedIcon sx={{ color: color }} />
  ) : (
    <ExpandLessRoundedIcon sx={{ color: color }} />
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        sx={{
          fontWeight: "500",
          fontSize: 12,
          color: color, //theme.palette.secondary.main
        }}
      >
        {text}
      </Typography>
      <IconButton
        onClick={setOpen}
        aria-label="trade detail open"
        sx={{ p: 0 }}
      >
        {icon}
      </IconButton>
    </Box>
  );
};

interface GenButtonProps {
  text: string;
  onClick?: () => void;
  url?: string;
  param?: NavigateOptions;
}

export const GenButton: React.FC<GenButtonProps> = ({
  text,
  onClick,
  url,
  param,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Button
      variant="outlined"
      sx={{
        borderWidth: "1.4px", // 보더 두께
        borderColor: theme.palette.primary.light, // 보더 색상
        borderRadius: 12,
        "&:hover": {
          borderWidth: "1.4px", // 보더 두께
          borderColor: theme.palette.primary.dark, // 호버 시 보더 색상 변경
          "& .MuiTypography-root": {
            color: `${theme.palette.primary.dark} !important`,
          },
        },
      }}
      onClick={
        onClick &&
        (() => {
          onClick();
          url && navigate(url, param);
        })
      }
    >
      <Typography
        sx={{
          color: `${theme.palette.primary.main} !important`,
          fontWeight: "bold",
          fontSize: 12,
        }}
      >
        {text}
      </Typography>
    </Button>
  );
};

export interface IGenProfitNode {
  profit: number;
  entryAmount: number;
  unit?: string;
  fontWeight?: string | number;
  fontSize?: string | number;
}

export const genProfitNode = ({
  profit,
  entryAmount,
  unit = "USDT",
  fontWeight,
  fontSize,
}: IGenProfitNode): ReactNode => {
  const plusTextColor = "#1BB56C";
  const minusTextColor = "#F56565";
  const noProfitTextcolor = "#2D3748";

  let color = noProfitTextcolor;

  let profitTemp = 0;
  let profitRatio = "0.0";

  if (typeof profit !== "number" || typeof entryAmount !== "number") {
    profitTemp = 0;
    profitRatio = "0.0";
  } else {
    profitTemp = profit !== 0 ? round(profit, 2) : profit;
    const profitRatioTemp = Math.abs(
      round((profitTemp / entryAmount) * 100.0, 1)
    );

    if (profitTemp > 0) {
      color = plusTextColor;
      profitRatio = `+ ${profitRatioTemp} %`;
    } else if (profitTemp < 0) {
      color = minusTextColor;
      profitRatio = `- ${profitRatioTemp} %`;
    }
  }

  return (
    <Box>
      {/* <Typography
        sx={{
          fontWeight: fontWeight ? fontWeight : "600",
          fontSize: fontSize ? fontSize : 12,
          color: color, //theme.palette.secondary.main
        }}
      >
        
      </Typography> */}
      <Box
        sx={{
          border: "1px solid",
          borderColor: color,
          borderRadius: 1,
          padding: 0.5,
        }}
      >
        <Typography
          sx={{
            fontWeight: "600",
            fontSize: 12,
            color: color, //theme.palette.secondary.main
          }}
        >
          {`${profit.toLocaleString()} ${unit}`}
        </Typography>
      </Box>
    </Box>
  );
};

interface PositionLeverageDialogSelectProps {
  open: boolean;
  onClose: (result: {
    position: string | undefined;
    leverage: number | string | undefined;
  }) => void;
}

const PositionLeverageDialogSelect: React.FC<
  PositionLeverageDialogSelectProps
> = ({ open, onClose }) => {
  const [position, setposition] = React.useState<string>("");
  const [leverage, setleverage] = React.useState<number | string>("");

  const handleChange1 = (event: SelectChangeEvent<typeof position>) => {
    const value = event.target.value;
    setposition(value);

    // "BUY" 또는 "SELL" 선택 시 leverage를 빈 문자열로 고정
    if (value === "BUY" || value === "SELL") {
      setleverage("");
    }
  };

  const handleChange2 = (event: SelectChangeEvent<typeof leverage>) => {
    // position이 "BUY" 또는 "SELL"인 경우 leverage 변경 불가
    if (position !== "BUY" && position !== "SELL") {
      setleverage(event.target.value);
    }
  };

  const handleClose = (
    event: React.SyntheticEvent<unknown>,
    reason?: string
  ) => {
    if (reason !== "backdropClick") {
      onClose({ position: undefined, leverage: undefined });
    }
  };

  const handleOk = () => {
    onClose({ position, leverage });
  };

  return (
    <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
      <DialogTitle>포지션 & 레버리지</DialogTitle>
      <DialogContent>
        <Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel htmlFor="demo-dialog-native">포지션</InputLabel>
            <Select
              native
              value={position}
              onChange={handleChange1}
              input={<OutlinedInput label="포지션" id="demo-dialog-native" />}
            >
              <option aria-label="None" value="" />
              <option value="LONG">LONG</option>
              <option value="SHORT">SHORT</option>
              <option value="BUY">BUY</option>
              <option value="SELL">SELL</option>
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-dialog-select-label">레버리지</InputLabel>
            <Select
              labelId="demo-dialog-select-label"
              id="demo-dialog-select"
              value={leverage}
              onChange={handleChange2}
              input={<OutlinedInput label="레버리지" />}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {Array.from({ length: 100 }, (_, i) => (
                <MenuItem key={i + 1} value={i + 1}>
                  {i + 1}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOk}>선택</Button>
        <Button
          onClick={() => onClose({ position: undefined, leverage: undefined })}
        >
          취소
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PositionLeverageDialogSelect;

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export interface CheckboxesTagsProps {
  mode: EditTradeMode;
  options: IAllTags[];
  tags: TagOptionType[];
  setTags?: React.Dispatch<React.SetStateAction<TagOptionType[] | undefined>>;
  handleSetFilter?: (key: string, value: any) => void;
  handleRemoveFilter?: (key: string) => void;
}

export const CheckboxesTags: React.FC<CheckboxesTagsProps> = ({
  mode,
  options,
  tags,
  setTags,
  handleRemoveFilter,
  handleSetFilter,
}) => {
  const [localOptions, setLocalOptions] = useState<TagOptionType[]>(
    options.map((option) => option.tag)
  );
  const [selectedOptions, setSelectedOptions] = useState<TagOptionType[]>(tags);
  const [inputValue, setInputValue] = useState("");

  // 새로운 옵션 추가 및 선택
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      const inputValue = (event.target as HTMLInputElement).value;
      if (
        inputValue &&
        !localOptions!.some((option) => option.name === inputValue)
      ) {
        const newOption = { name: inputValue };
        setLocalOptions([...localOptions!, newOption]);
        setSelectedOptions([...selectedOptions, newOption]);
      }
      setInputValue(""); // 입력 필드를 비웁니다.
    }
  };

  useEffect(() => {
    if (selectedOptions.length === 0) {
      handleRemoveFilter && handleRemoveFilter("allTags");
    } else {
      handleSetFilter &&
        handleSetFilter("allTags", (allTags: TagOptionType[]) => {
          return selectedOptions.every((selectedOption: TagOptionType) =>
            allTags.some(
              (tags: TagOptionType) => tags.name === selectedOption.name
            )
          );
        });
    }

    setTags && setTags(selectedOptions);
  }, [selectedOptions]);

  return (
    <Autocomplete
      multiple
      fullWidth
      id="checkboxes-tags-demo"
      limitTags={4}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      options={localOptions || []}
      disableCloseOnSelect
      value={selectedOptions}
      readOnly={mode === "READONLY" ? true : false}
      disabled={mode === "READONLY" ? true : false}
      onChange={(event, newValue) => {
        setSelectedOptions(newValue);
      }}
      getOptionLabel={(option) => option.name}
      renderTags={(value: TagOptionType[], getTagProps) =>
        value.map((option: TagOptionType, index: number) => {
          const tagProps = getTagProps({ index });
          return (
            <Chip
              variant="outlined"
              size="small"
              label={option.name}
              {...tagProps}
              key={option.name} // 명시적으로 key를 지정
            />
          );
        })
      }
      renderOption={(props, option, { selected }) => (
        <li {...props} key={option.name}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      style={{ padding: 0, margin: 0 }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <CssTextField
          {...params}
          fullWidth
          placeholder={
            mode === "READONLY"
              ? "우측 상단 편집 버튼을 눌러 수정 가능합니다."
              : "태그 필터..."
          }
          onKeyDown={handleKeyDown}
          InputProps={{
            ...params.InputProps,
            sx: {
              padding: "0 !important", // 패딩 제거
            },
          }}
        />
      )}
    />
  );
};

export const TradeTypeMap: { [key in TradeType]: string } = {
  PositionStart: "최초 진입",
  Buy: "매수",
  Sell: "매도",
  PositionEnd: "최종 매도",
};

export const TradeTypeForStyle: { [key in TradeType]: string } = {
  PositionStart: theme.palette.primary.main,
  Buy: theme.palette.primary.main,
  Sell: theme.palette.error.main,
  PositionEnd: theme.palette.error.main,
};

export const ALLTAGS: TagOptionType[] = [
  { name: "1m" },
  { name: "5m" },
  { name: "15m" },
  { name: "30m" },
  { name: "1h" },
  { name: "4h" },
  { name: "삼각 수렴" },
  { name: "채널 돌파" },
  { name: "100일선 지지" },
  { name: "200일선 돌파" },
  { name: "피보나치" },
  { name: "이평선 터치" },
];
