import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { styled } from "@mui/material/styles";
import { ReactNode } from "react";

export interface MenuItemProps {
  content: ReactNode;
  onClick: () => void;
}

export interface MenuPopupStateProps {
  buttonContent: React.ReactNode;
  menuItems: MenuItemProps[];
}

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  "&:hover": {
    backgroundColor: `rgba(72,128,200, 0.1)`,
  },
}));

const CustomPopupMenu: React.FC<MenuPopupStateProps> = ({
  buttonContent,
  menuItems,
}) => {
  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          {React.cloneElement(buttonContent as React.ReactElement, {
            ...bindTrigger(popupState),
          })}
          <Menu
            {...bindMenu(popupState)}
            anchorOrigin={{
              vertical: "bottom", // anchor 위치 조정
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top", // transform 위치 조정
              horizontal: "left",
            }}
            PaperProps={{
              style: {
                marginTop: "10px", // 추가적인 위치 조정
              },
            }}
          >
            {menuItems.map((item, index) => (
              <CustomMenuItem
                key={index}
                onClick={() => {
                  item.onClick(); // 전달받은 함수 실행
                  popupState.close(); // 메뉴 닫기
                }}
              >
                {item.content}
              </CustomMenuItem>
            ))}
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
};

export default CustomPopupMenu;
