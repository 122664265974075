import { createSlice } from "@reduxjs/toolkit";
import { compose } from "redux";

export const composeEnhancers =
  (process.env.NODE_ENV === "development" &&
    window &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export interface UtilState {
  theme: "light" | "dark";
  isLoading: boolean;
  isSidebarOpen: boolean;
  isNotificationBarOpen: boolean;
  drawerWidth: number;
}

const initialState: UtilState = {
  theme: "light",
  isLoading: false,
  isSidebarOpen: window.innerWidth < 1024 ? false : true,
  isNotificationBarOpen: false,
  drawerWidth: 240,
};

const utilSliece = createSlice({
  name: "util",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSidebarOpen: (state, action) => {
      state.isSidebarOpen = action.payload;
    },
    setNotificationBarOpen: (state, action) => {
      state.isNotificationBarOpen = action.payload;
    },
    toggleTheme: (state) => {
      state.theme = state.theme === "light" ? "dark" : "light";
    },
  },
});

export const {
  setLoading,
  setSidebarOpen,
  setNotificationBarOpen,
  toggleTheme,
} = utilSliece.actions;

export default utilSliece.reducer;
