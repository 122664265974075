import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { styled, useTheme } from "@mui/material/styles";

import {
  AppBar,
  Button,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import useResize from "../hooks/useResize";
import { ReactNode, useEffect, useState } from "react";

type Anchor = "top" | "left" | "bottom" | "right";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  height: 48,
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "hover" && prop !== "dragging",
})<{ hover: boolean; dragging: boolean }>(({ theme, hover, dragging }) => ({
  boxShadow: theme.shadows[0],
  backgroundColor: "white",
  borderLeft:
    hover || dragging
      ? `1px solid rgba(0, 0, 0, 0.6)`
      : `1px solid rgba(0, 0, 0, 0.1)`,
  borderBottom: `1px solid rgba(0, 0, 0, 0.1)`,
  transition: "border-left 0.3s ease",
}));

const ResizableDiv = styled("div", {
  shouldForwardProp: (prop) => prop !== "hover" && prop !== "dragging",
})<{ hover: boolean; dragging: boolean }>(({ theme, hover, dragging }) => ({
  position: "absolute",
  width: "5px",
  top: "0",
  left: "0px",
  bottom: "0",
  cursor: "col-resize",
  borderLeft:
    hover || dragging
      ? `1px solid rgba(0, 0, 0, 0.6)`
      : `1px solid rgba(0, 0, 0, 0.1)`,
  transition: "border-left 0.3s ease",
}));

interface CustomEditDrawerProps {
  title: string;
  anchor: Anchor;
  titleButtons?: ReactNode[];
  children?: ReactNode[];
  open: boolean;
  initialWidth: number;
  minWidth: number;
  isEmptyHeader?: boolean;
  isFooter?: boolean;
  onClose: (open: boolean) => void;
  onResize: (width: number) => void; // width 상태를 전달하기 위한 콜백 함수
  onSave?: () => void;
  onCancel?: () => void;
}

const CustomEditDrawer: React.FC<CustomEditDrawerProps> = ({
  title,
  anchor,
  titleButtons,
  children,
  open,
  onClose,
  initialWidth,
  minWidth,
  onResize, // 콜백 함수 받기
  isEmptyHeader = true,
  isFooter = false,
  onSave,
  onCancel,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const initWidth = matches ? window.innerWidth : initialWidth;

  const { width, enableResize } = useResize({
    minWidth,
    initialWidth: initWidth,
  });
  const [isHover, setIsHover] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const handleMouseDown = (e: React.MouseEvent) => {
    setIsDragging(true);
    enableResize(e);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleDrawerClose = () => {
    onClose(false);
  };

  useEffect(() => {
    onResize(width);

    // 마우스 업 이벤트를 윈도우에 추가하여 드래그 종료를 감지
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [width, onResize]);

  return (
    <Drawer
      variant="persistent"
      anchor={anchor}
      open={open}
      onClose={handleDrawerClose}
      PaperProps={{ style: { width }, overflow: "hidden" }}
    >
      {isEmptyHeader && isEmptyHeader ? <DrawerHeader /> : null}

      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <StyledAppBar position="sticky" hover={isHover} dragging={isDragging}>
          <Toolbar style={{ paddingLeft: 6 }}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
            <Typography variant="h6" noWrap component="div" sx={{ mr: 2 }}>
              {title}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                flexGrow: 1,
              }}
            >
              {titleButtons &&
                titleButtons.map((button, index) => (
                  <Box key={`${index}_add_menu`}>{button}</Box>
                ))}
            </Box>
          </Toolbar>
        </StyledAppBar>

        <Box sx={{ flexGrow: 1, overflow: "auto", p: 2 }}>
          <Stack spacing={6} direction={"column"}>
            {children?.map((child) => child)}
          </Stack>
        </Box>

        {/* Footer */}
        {isFooter ? (
          <Box
            sx={{
              position: "sticky",
              bottom: 0,
              backgroundColor: "white",
              zIndex: 1000,
              padding: theme.spacing(2),
              borderTop: `1px solid rgba(0, 0, 0, 0.12)`,
              display: "flex",
              justifyContent: "flex-end",
              gap: theme.spacing(2),
            }}
          >
            <Button variant="outlined" onClick={onCancel} color="primary">
              저장
            </Button>
            <Button variant="contained" onClick={onSave} color="primary">
              취소
            </Button>
          </Box>
        ) : null}
      </Box>

      <ResizableDiv
        hover={isHover}
        dragging={isDragging}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onMouseDown={handleMouseDown}
      />
    </Drawer>
  );
};

export default CustomEditDrawer;
