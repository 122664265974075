import { ReactNode, useEffect, useState } from "react";
import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import { Theme } from "@mui/system";
import stringReplace from "react-string-replace";

import { isArray } from "../types/typescheck";
import createCoinIconTable from "./Icons/CoinSymbols/IconMapTable";
import { IHistoryStack } from "../pages/Journal/types";
import { motion } from "framer-motion";
import dayjs from "dayjs";
import { IconWidth, TIME_FORMAT } from "../utils/utils";
import { iconHeight } from "./CommonNodes";

const applyStylesToWords = (text: string | undefined): React.ReactNode[] => {
  const LongColor = "#1FCA66";
  const ShortColor = "#F56565";

  if (typeof text === undefined) return [<span></span>];

  let styledText = stringReplace(text, "LONG", (match, i) => (
    <span key={`long-${i}`} style={{ color: LongColor, fontWeight: 600 }}>
      {match}
    </span>
  ));

  styledText = stringReplace(styledText, "SHORT", (match, i) => (
    <span key={`short-${i}`} style={{ color: ShortColor, fontWeight: 600 }}>
      {match}
    </span>
  ));

  return styledText;
};

const HistoryStack = ({
  data,
  titleTextStyle = { fontSize: 14, fontWeight: 600, marginLeft: 1 },
  subTitleTextStyle = {
    fontSize: 14,
    fontWeight: 500,
    marginLeft: 1,
    marginBottom: 2,
  },
  contentTextStyle = {
    fontSize: 14,
    fontWeight: 500,
    marginLeft: 1,
    marginBottom: 1,
    whiteSpace: "pre-line",
  },
  timestampTextStyle = {
    fontSize: 14,
    fontWeight: 500,
    marginLeft: 1,
    color: "#A0AEC0",
  },
}: IHistoryStack): JSX.Element => {
  const theme = useTheme();
  const dataList = isArray(data) ? data : [data];

  const CoinIconTable = createCoinIconTable(IconWidth + 4, iconHeight + 4);

  return (
    <Stack
      id="scrollStack"
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={1}
    >
      {dataList.map((item, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 30 }}
          transition={{ duration: 0.5 }}
        >
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {CoinIconTable[item.logo]}

              <Typography sx={titleTextStyle}>{item.title}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{
                  borderRightWidth: 3,
                  ml: 1.6,
                  mr: 1.7,
                  borderColor: "#E2E8F0",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography sx={subTitleTextStyle}>
                  {applyStylesToWords(item.subTitle)}
                </Typography>
                <Typography sx={contentTextStyle}>
                  {applyStylesToWords(item.content)}
                </Typography>
                <Typography sx={timestampTextStyle}>
                  {(item.tradeTime &&
                    dayjs(item.tradeTime).isValid() &&
                    dayjs(item.tradeTime).format(TIME_FORMAT)) ||
                    dayjs().format(TIME_FORMAT)}
                </Typography>
                <Box sx={{ height: "20px" }} />
              </Box>
            </Box>
          </Box>
        </motion.div>
      ))}
    </Stack>
  );
};

export default HistoryStack;
