import {
  configureStore,
  ThunkAction,
  Action,
  applyMiddleware,
} from "@reduxjs/toolkit";
import rootReducer from "./reducers/root-reducer";
import { myLogger } from "../utils/logger-middleware";
import { composeEnhancers } from "../utils/redux-utils";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import createCompressor from "redux-persist-transform-compress";

// configure middlewares
const middlewares = [myLogger];

//const middlewares = [myLogger, routerMiddleware];
// // compose enhancers
// const enhancer = composeEnhancers(applyMiddleware(...middlewares));

const compressor = createCompressor();

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["breadcrumbs"],
  //transforms: [compressor], // 데이터를 압축하여 저장
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// configureStore 함수로 스토어 생성
const store = configureStore({
  reducer: persistedReducer,
  //reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }), //.concat(middlewares),
});

const persistor = persistStore(store);

export { store, persistor };
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
