import { Typography, Button, IconButton, Box } from "@mui/material";
import { ReactNode } from "react";
import { useTheme, styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { paths } from "../../../paths";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  genTypoNode,
  menuButtonNode,
  IGenTypoNode,
  PositionLeverageText,
  getIconAndName,
} from "../CommonTableNode";
import createExchangeIconTable from "../../../components/Icons/Exchanges/IconMapTable";
import { IProgressTableData } from "../types";
import dayjs from "dayjs";
import { TIME_FORMAT } from "../../../utils/utils";
import { CoinIconTable } from "../../../components/CommonNodes";

export function createProgressingTradeTableData(props: IProgressTableData) {
  const LongColor = "#68D391";
  const ShortColor = "#F56565";

  const posColor =
    props.position === "LONG" || props.position === "BUY"
      ? LongColor
      : ShortColor;

  const connectedNameNode = genTypoNode({
    data: props.connectedName,
    fontWeight: "700",
  });

  const iconWidth = 24;
  const iconHeight = 24;

  const ExchangeIconTable = createExchangeIconTable(iconWidth, iconHeight);

  const exchangeNode = getIconAndName(
    props.exchange || "바이낸스",
    ExchangeIconTable[props.exchange || "바이낸스"]
  );

  const symbolNode = getIconAndName(
    props.symbol || "BTCUSDT",
    CoinIconTable[props.symbol || "BTCUSDT"]
  );

  const positionLeverageNode = (
    <PositionLeverageText
      position={{ text: props.position, backgroundColor: posColor }}
      leverage={{ text: `X${props.leverage}`, backgroundColor: posColor }}
    />
  );

  const entryPriceNode = genTypoNode({
    data: props.entryPrice,
    leadingText: "\nUSDT",
    isPrice: true,
  });
  const takeProfitPriceNode = genTypoNode({
    data: props.takeProfitPrice,
    leadingText: "\nUSDT",
    isPrice: true,
  });

  const tradeTimeNode = genTypoNode({
    data:
      (props.tradeTime &&
        dayjs(props.tradeTime).isValid() &&
        dayjs(props.tradeTime).format(TIME_FORMAT)) ||
      dayjs().format(TIME_FORMAT),
  });

  // 메뉴 항목 데이터
  const menuItems = [
    {
      content: "✏️ 수정",
      onClick: () => alert("수정"),
    },
    {
      content: "🗑️ 삭제",
      onClick: () => alert("삭제"),
    },
  ];

  // 다양한 버튼 내용 데이터
  const buttonContent = (
    <IconButton>
      <MoreVertIcon />
    </IconButton>
  );

  const menuNode = menuButtonNode({ buttonContent, menuItems });

  return [
    connectedNameNode,
    exchangeNode,
    symbolNode,
    positionLeverageNode,
    entryPriceNode,
    takeProfitPriceNode,
    tradeTimeNode,
    //menuNode,
  ];
}

export const progressingTradeHeaderList: Array<string> = [
  "연결된 일지",
  "거래소",
  "종목",
  "포지션",
  "진입가",
  "목표가",
  "진입 날짜",
  //"", //icon을 위한 빈공간
];
