// overviewSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs, { Dayjs } from "dayjs";

interface JournalPageState {
  isShowAnalysis: boolean;
  selectedTabIndex: number;
  updateDateAt: Dayjs;
}

const initialState: JournalPageState = {
  isShowAnalysis: false,
  selectedTabIndex: 0,
  updateDateAt: dayjs(),
};

const journalPageSlice = createSlice({
  name: "journalPage",
  initialState,
  reducers: {
    setIsShowAnalysis(state, action: PayloadAction<boolean>) {
      state.isShowAnalysis = action.payload;
    },
    setSelectedTabIndex(state, action: PayloadAction<number>) {
      state.selectedTabIndex = action.payload;
    },
    setUpdateDateAt(state, action: PayloadAction<Dayjs>) {
      state.updateDateAt = action.payload;
    },
  },
});

export const { setIsShowAnalysis, setSelectedTabIndex, setUpdateDateAt } =
  journalPageSlice.actions;
export default journalPageSlice.reducer;
