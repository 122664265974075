import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TradeDataRecord } from "../../../../pages/Journal/types";

interface journalDetailsState {
  changedGroupId: string;
  selectedJournalGroupId: string;
  selectedJournalRowIndex: number;
  selectedJournalType: string;
  editDrawerOpen: boolean;
  editDrawerWidth: number;
  editDialogOpen: boolean;
  editDialogAction: (() => void) | null;
  alertEditDialogMessage: string;
  editDialogType: "confirm" | "alert";
}

const initialState: journalDetailsState = {
  changedGroupId: "",
  selectedJournalGroupId: "",
  selectedJournalRowIndex: -1,
  selectedJournalType: "OPEN",
  editDrawerOpen: false,
  editDrawerWidth: 560, // 기본 값으로 editDrawerMinWidth 사용
  editDialogOpen: false,
  editDialogAction: null,
  alertEditDialogMessage: "",
  editDialogType: "confirm",
};

const journalDetailsSlice = createSlice({
  name: "journalDetails",
  initialState,
  reducers: {
    setChangedGroupId(state, action: PayloadAction<string>) {
      state.changedGroupId = action.payload;
    },

    setSelectedJournalGroupId(state, action: PayloadAction<string>) {
      state.selectedJournalGroupId = action.payload;
    },
    setSelectedJournalRowIndex(state, action: PayloadAction<number>) {
      state.selectedJournalRowIndex = action.payload;
    },
    setSelectedJournalType(state, action: PayloadAction<string>) {
      state.selectedJournalType = action.payload;
    },
    setEditDrawerOpen(state, action: PayloadAction<boolean>) {
      state.editDrawerOpen = action.payload;
    },
    setEditDrawerWidth(state, action: PayloadAction<number>) {
      state.editDrawerWidth = action.payload;
    },

    setEditDialogOpen(state, action: PayloadAction<boolean>) {
      state.editDialogOpen = action.payload;
    },
    setEditDialogAction(state, action: PayloadAction<(() => void) | null>) {
      state.editDialogAction = action.payload;
    },
    setAlertEditDialogMessage(state, action: PayloadAction<string>) {
      state.alertEditDialogMessage = action.payload;
    },
    setEditDialogType(state, action: PayloadAction<"confirm" | "alert">) {
      state.editDialogType = action.payload;
    },
  },
});

export const {
  setChangedGroupId,
  setSelectedJournalGroupId,
  setSelectedJournalRowIndex,
  setSelectedJournalType,
  setEditDrawerOpen,
  setEditDrawerWidth,
  setEditDialogOpen,
  setEditDialogAction,
  setAlertEditDialogMessage,
  setEditDialogType,
} = journalDetailsSlice.actions;

export default journalDetailsSlice.reducer;
