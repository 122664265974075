import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { paths } from "../../paths";
import Breadcrumb from "../../types/breadcrumb";

const initialState: Breadcrumb[] = [
  //{ label: "매매 일지", href: paths.dashboard.journal.overview, state: {} },
];

const breadcrumbsSlice = createSlice({
  name: "breadcrumbs",
  initialState,
  reducers: {
    setBreadcrumbs: (state, action: PayloadAction<Breadcrumb[]>) => {
      return action.payload;
    },
    addBreadcrumb: (state, action: PayloadAction<Breadcrumb>) => {
      const breadcrumb = action.payload;

      const exists = state.some(
        (item) =>
          item.label === breadcrumb.label && item.href === breadcrumb.href
      );
      if (!exists) {
        state.push(breadcrumb);
      }
    },
    removeBreadcrumb: (state) => {
      state.pop();
    },
    resetBreadcrumbs: () => initialState,
  },
});

export const {
  setBreadcrumbs,
  addBreadcrumb,
  removeBreadcrumb,
  resetBreadcrumbs,
} = breadcrumbsSlice.actions;

export default breadcrumbsSlice.reducer;
