// overviewSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IJournalTableData } from "../../../../pages/Journal/types";

interface SelectedJournalState {
  selectedJournalData: IJournalTableData;
}

const initialState: SelectedJournalState = {
  selectedJournalData: {
    Jid: "",
    title: "",
    totalTradeCount: 0,
    winRate: 0,
    profitRate: 0,
    createdAt: 0,
    updatedAt: 0,
    marketType: "",
    loseTradeCount: 0,
    winTradeCount: 0,
    totalOrderCount: 0,
    totalProfit: 0,
  },
};

const journalOverviewSlice = createSlice({
  name: "journalOverview",
  initialState,
  reducers: {
    setSelectedJournal(state, action: PayloadAction<IJournalTableData>) {
      state.selectedJournalData = action.payload;
    },
    resetSelectedJournal: () => initialState,
  },
});

export const { setSelectedJournal, resetSelectedJournal } =
  journalOverviewSlice.actions;
export default journalOverviewSlice.reducer;
