import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { User } from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { convertToFirestoreData } from "../../utils/utils";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

// ConnectedExchange와 UserState 인터페이스 정의
export interface ConnectedExchange {
  id: string;
  exchangeName: string;
  exchangeId: string;
}

export interface UserState {
  uid: string;
  username: string;
  displayName: string;
  email: string;
  hasAvatar: boolean;
  profileImagePath: string;
  subscription: string;
  connectedExchanges: ConnectedExchange[];
  journalIds: string[];
  queryStatus: string;
  loading: boolean;
  error: string | null;
}

// 초기 상태 정의
const initialState: UserState = {
  uid: "",
  username: "",
  displayName: "",
  email: "",
  hasAvatar: false,
  profileImagePath: "",
  subscription: "free",
  connectedExchanges: [],
  journalIds: [],
  queryStatus: "idle",
  loading: false,
  error: null,
};

// 새로운 유저 문서를 Firestore에 추가하는 addUser 함수
export const addUser = createAsyncThunk<
  Omit<UserState, "loading" | "error">, // 성공 시 반환할 데이터
  { user: User; username: string }, // firebase User와 username을 매개변수로 받음
  { rejectValue: string }
>("user/addUser", async ({ user, username }, { rejectWithValue }) => {
  try {
    const newUserState = {
      uid: user.uid,
      username: username,
      displayName: user.displayName || "",
      email: user.email || "",
      hasAvatar: false,
      profileImagePath: "",
      subscription: "free",
      queryStatus: "idle",
      connectedExchanges: [],
      journalIds: [],
    };

    // Firestore에 사용자 정보 저장
    await setDoc(doc(db, "users", user.uid), newUserState);

    // 성공 시 반환할 데이터 (newUserState)
    return newUserState;
  } catch (error: any) {
    return rejectWithValue(
      "유저 문서를 생성하는 중 오류가 발생했습니다: " + error.message
    );
  }
});

// 유저 데이터를 업데이트하는 비동기 작업
export const updateUser = createAsyncThunk<
  Partial<UserState>, // 업데이트하려는 필드만 포함하므로 Partial 사용
  { uid: string; updateData: Partial<UserState>; file?: File }, // uid, 업데이트할 데이터 및 파일(옵션)을 전달
  { rejectValue: string }
>("user/updateUser", async ({ uid, updateData, file }, { rejectWithValue }) => {
  try {
    let updatedData = { ...updateData };

    // hasAvatar와 profileImagePath가 있을 경우 아바타 업로드 작업 수행
    if (
      file &&
      updateData.hasAvatar &&
      updateData.profileImagePath !== undefined
    ) {
      try {
        // Firebase storage 객체 가져오기
        const storage = getStorage();

        // Storage 참조 생성
        const avatarRef = ref(storage, `avatars/${uid}`);

        // 파일 업로드
        await uploadBytes(avatarRef, file);

        // 업로드된 파일의 다운로드 URL 가져오기
        const profileImagePath = await getDownloadURL(avatarRef);

        // 업데이트할 데이터에 새로운 profileImagePath 추가
        updatedData = {
          profileImagePath,
          hasAvatar: true,
        };
      } catch (error: any) {
        return rejectWithValue(
          `아바타 업로드 중 오류가 발생했습니다: ${error.message}`
        );
      }
    }

    // Firestore에서 유저 문서 업데이트
    const userDocRef = doc(db, "users", uid);
    await updateDoc(userDocRef, convertToFirestoreData(updatedData));

    // 성공 시 업데이트된 데이터를 반환 (updatedData)
    return updatedData;
  } catch (error: any) {
    return rejectWithValue(
      `유저 업데이트 중 오류가 발생했습니다: ${error.message}`
    );
  }
});

// Firestore에서 유저 데이터를 가져오는 비동기 작업 추가
export const fetchUserData = createAsyncThunk<
  Omit<UserState, "loading" | "error">,
  string, // uid를 파라미터로 받습니다.
  { rejectValue: string }
>("user/fetchUserData", async (uid, { rejectWithValue }) => {
  try {
    const userDocRef = doc(db, "users", uid);
    const userDocSnap = await getDoc(userDocRef);

    if (!userDocSnap.exists()) {
      throw new Error("User data not found");
    }

    let userState: Omit<UserState, "loading" | "error"> = {
      uid: userDocSnap.id,
      username: userDocSnap.data()?.username || "",
      displayName: userDocSnap.data()?.displayName || "",
      email: userDocSnap.data()?.email || "",
      hasAvatar: userDocSnap.data()?.hasAvatar || false,
      profileImagePath: userDocSnap.data()?.profileImagePath || "",
      subscription: userDocSnap.data()?.subscription || "free",
      queryStatus: userDocSnap.data()?.queryStatus || "idle",
      connectedExchanges: [],
      journalIds: [],
    };

    if (userState.uid === "") throw new Error("유저 데이터가 없습니다.");

    // Firestore에서 exchanges 서브컬렉션 가져오기
    const exchangesRef = collection(db, `users/${userState.uid}/exchanges`);
    const exchangesSnapshot = await getDocs(exchangesRef);

    if (!exchangesSnapshot.empty) {
      const formattedExchanges: ConnectedExchange[] =
        exchangesSnapshot.docs.map((doc) => ({
          id: doc.id || "",
          exchangeName: doc.data().exchangeName || "",
          exchangeId: doc.data().exchangeId || "",
        }));
      userState = { ...userState, connectedExchanges: formattedExchanges };
    }

    // Firestore에서 journals 서브컬렉션 가져오기
    const journalsRef = collection(db, `users/${userState.uid}/journals`);
    const journalsSnapshot = await getDocs(journalsRef);

    if (!journalsSnapshot.empty) {
      const formattedJournals: string[] = journalsSnapshot.docs.map(
        (doc) => doc.id
      );
      userState = { ...userState, journalIds: formattedJournals };
    }

    return userState;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

// Slice 생성
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // 필요한 동기 액션들을 추가할 수 있습니다.
    clearError(state) {
      state.error = null;
    },
    clearUser(state) {
      state = initialState;
    },
    setUserState(state, action: PayloadAction<{ user: UserState }>) {
      state = action.payload.user;
    },
    updateUserState(state, action: PayloadAction<Partial<UserState>>) {
      Object.assign(state, action.payload); // 전달된 필드만 업데이트, 나머지는 유지
    },
  },
  extraReducers: (builder) => {
    builder
      // updateUser 비동기 작업 처리
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        updateUser.fulfilled,
        (state, action: PayloadAction<Partial<UserState>>) => {
          state.loading = false;
          Object.assign(state, action.payload); // 업데이트된 데이터로 상태를 병합
        }
      )
      .addCase(updateUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Firestore에서 유저 데이터를 가져오는 비동기 작업 처리
      .addCase(fetchUserData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchUserData.fulfilled,
        (
          state,
          action: PayloadAction<Omit<UserState, "loading" | "error">>
        ) => {
          state.loading = false;
          state.uid = action.payload.uid;
          state.username = action.payload.username;
          state.displayName = action.payload.displayName;
          state.email = action.payload.email;
          state.hasAvatar = action.payload.hasAvatar;
          state.profileImagePath = action.payload.profileImagePath;
          state.subscription = action.payload.subscription;
          state.connectedExchanges = action.payload.connectedExchanges;
          state.journalIds = action.payload.journalIds;
        }
      )
      .addCase(fetchUserData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // addUser 비동기 작업 처리
      .addCase(addUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        addUser.fulfilled,
        (
          state,
          action: PayloadAction<Omit<UserState, "loading" | "error">>
        ) => {
          state.loading = false;
          Object.assign(state, action.payload); // 유저 상태 업데이트
        }
      )
      .addCase(addUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

// 액션과 리듀서를 내보냅니다.
export const { clearError, clearUser, setUserState, updateUserState } =
  userSlice.actions;
export default userSlice.reducer;
