import dayjs, { Dayjs } from "dayjs";
import {
  IAllTags,
  IClosedTradeTableData,
  ICollapseTradeTableData,
  IOpenTradeTableData,
  TagOptionType,
  TradeDataRecord,
} from "../../pages/Journal/types";
import { maxDayjs, minDayjs, round } from "../../utils/utils";
import {
  AnalyzeTradesResult,
  ICreateAnalysisTagTableData,
  PerformanceClassification,
  RankCriteria,
  SymbolAnalysis,
} from "../../pages/Journal/Analysis/types";

export const assignNumAndClassify = (
  data: TradeDataRecord[]
): {
  closedTrades: IClosedTradeTableData[];
  openTrades: IOpenTradeTableData[];
  allTags: IAllTags[]; // allTags 반환 추가
} => {
  const groupedData: { [key: string]: TradeDataRecord[] } = data
    .sort((a, b) => dayjs(a.tradeTime)?.diff(dayjs(b.tradeTime)) || 0)
    .reduce((acc, record) => {
      if (!acc[record.groupId || ""]) {
        acc[record.groupId || ""] = [];
      }
      acc[record.groupId || ""].push(record);
      return acc;
    }, {} as { [key: string]: TradeDataRecord[] });

  const closedTrades: IClosedTradeTableData[] = [];
  const openTrades: IOpenTradeTableData[] = [];
  const allTagsMap: { [tagName: string]: number } = {}; // 태그 이름을 키로 하는 맵

  Object.keys(groupedData).forEach((groupId) => {
    const records = groupedData[groupId];
    let buyCount = 0;
    let sellCount = 0;
    let allEntryAmount = 0;
    let allSize = 0;
    let maxEntryAmount = 0;
    let allProfit = 0;
    let allCommission = 0;
    let totalBuyAmount = 0;
    let totalBuyQuantity = 0;
    let totalSellAmount = 0;
    let totalSellQuantity = 0;
    let prevIndividualAvgEntryPrice = 0;
    let individualAvgEntryPrice = 0;
    const allTagsSet: Set<string> = new Set();
    let collapsedTrades: ICollapseTradeTableData[] = [];

    records.sort((a, b) => dayjs(a.tradeTime)?.diff(dayjs(b.tradeTime)) || 0); // records 정렬 추가

    records.forEach((record, index) => {
      let minDate: Dayjs | undefined = undefined;
      let maxDate: Dayjs | undefined = undefined;

      if (record.tradeType === "PositionStart") {
        if (index < records.length - 1) {
          maxDate = dayjs(records[index + 1].tradeTime);
        } else {
          maxDate = dayjs();
        }
      } else if (record.tradeType === "Buy" || record.tradeType === "Sell") {
        const positionStartRecord = records.find(
          (rec) => rec.tradeType === "PositionStart"
        );
        if (positionStartRecord) {
          minDate = dayjs(positionStartRecord.tradeTime);
        }
        const positionEndRecord = records.find(
          (rec) => rec.tradeType === "PositionEnd"
        );
        if (positionEndRecord) {
          maxDate = dayjs(positionEndRecord.tradeTime);
        } else {
          maxDate = dayjs();
        }
      } else if (record.tradeType === "PositionEnd") {
        if (index > 0) {
          minDate = dayjs(records[index - 1].tradeTime);
        }
      }

      if (record.tradeType === "PositionStart" || record.tradeType === "Buy") {
        if (individualAvgEntryPrice === 0) {
          individualAvgEntryPrice = record.entryPrice || 0;
          prevIndividualAvgEntryPrice = individualAvgEntryPrice;
        } else {
          prevIndividualAvgEntryPrice = individualAvgEntryPrice;
          individualAvgEntryPrice =
            (individualAvgEntryPrice * allSize +
              (record.size || 0) * (record.entryPrice || 0)) /
            (allSize + (record.size || 0));
        }

        buyCount += 1;
        allEntryAmount += record.entryAmount || 0;
        allSize += record.size || 0;
        totalBuyAmount += record.entryAmount || 0;
        totalBuyQuantity +=
          (record.entryAmount || 0) / (record.entryPrice || 1);
        maxEntryAmount += record.entryAmount || 0;
      } else if (
        record.tradeType === "Sell" ||
        record.tradeType === "PositionEnd"
      ) {
        sellCount += 1;
        allEntryAmount -= record.entryAmount || 0;
        allSize -= record.size || 0;
        totalSellAmount += record.entryAmount || 0;
        totalSellQuantity +=
          (record.entryAmount || 0) / (record.entryPrice || 1);
        prevIndividualAvgEntryPrice = individualAvgEntryPrice;
      }
      allProfit += record.profit || 0;
      allCommission += record.commission || 0;
      if (record.tags) {
        record.tags.forEach((tag) => {
          allTagsSet.add(tag.name);
          allTagsMap[tag.name] = (allTagsMap[tag.name] || 0) + 1; // 태그 사용 횟수 누적
        });
      }

      allEntryAmount = round(allEntryAmount, 5);
      allSize = round(allSize, 5);

      collapsedTrades.push({
        id: record.id,
        groupId: record.groupId!,
        orderId: record.orderId!,
        num: 0,
        tradeType: record.tradeType,
        tradeTime: record.tradeTime!,
        exchange: record.exchange!,
        marketType: record.marketType!,
        position: record.position!.toUpperCase(),
        leverage: record.leverage! as number,
        symbol: record.symbol!,
        allEntryAmount: allEntryAmount,
        allSize: allSize,
        size: record.size!,
        entryAmount: round(record.entryAmount!, 5),
        entryPrice: record.entryPrice!,
        takeProfitPrice: record.takeProfitPrice, // 포함시킴
        stopLossPrice: record.stopLossPrice, // 포함시킴
        profit: record.profit!,
        commission: record.commission!,
        tags: record.tags,
        notes: record.notes,
        link: record.link,
        minDate: minDate,
        maxDate: maxDate,
        individualAvgEntryPrice: individualAvgEntryPrice,
        prevIndividualAvgEntryPrice: prevIndividualAvgEntryPrice,
      });
    });

    allEntryAmount = round(allEntryAmount, 5);
    allSize = round(allSize, 5);
    collapsedTrades.sort(
      (a, b) => dayjs(a.tradeTime)?.diff(dayjs(b.tradeTime)) || 0
    );

    collapsedTrades = collapsedTrades.map((trade, index) => ({
      ...trade,
      num: index + 1,
    }));

    const avgEntryPrice = totalBuyQuantity
      ? round(totalBuyAmount / totalBuyQuantity, 2)
      : 0;
    const avgExitPrice = totalSellQuantity
      ? round(totalSellAmount / totalSellQuantity, 2)
      : 0;

    const hasPositionStart = records.some(
      (record) => record.tradeType === "PositionStart"
    );
    const hasPositionEnd = records.some(
      (record) => record.tradeType === "PositionEnd"
    );

    const sortedAllTags = Array.from(allTagsSet)
      .sort()
      .map((name) => ({ name }));

    if (hasPositionStart && hasPositionEnd) {
      const closedTrade: IClosedTradeTableData = {
        id: records[0].id,
        groupId: records[0].groupId,
        orderId: records[0].orderId,
        num: closedTrades.length + 1,
        tradeTime: records[records.length - 1].tradeTime!,
        exchange: records[0].exchange!,
        marketType: records[0].marketType!,
        position: records[0].position!.toUpperCase(),
        leverage: records[0].leverage! as number,
        symbol: records[0].symbol!,
        allEntryAmount: round(maxEntryAmount, 5),
        allSize: allSize,
        size: records[0].size,
        avgEntryPrice: avgEntryPrice,
        avgExitPrice: avgExitPrice,
        allProfit: allProfit,
        allCommission: allCommission,
        buyCount,
        sellCount,
        profitLossRatio: records[0].profitLossRatio!,
        allTags: sortedAllTags,
        collapsedTrades,
      };
      closedTrades.push(closedTrade);
    } else if (hasPositionStart) {
      const openTrade: IOpenTradeTableData = {
        id: records[0].id,
        groupId: records[0].groupId,
        orderId: records[0].orderId,
        num: openTrades.length + 1,
        tradeTime: records[0].tradeTime!,
        exchange: records[0].exchange!,
        marketType: records[0].marketType!,
        position: records[0].position!.toUpperCase(),
        leverage: records[0].leverage! as number,
        symbol: records[0].symbol!,
        avgEntryPrice: avgEntryPrice,
        entryAmount: round(allEntryAmount, 5),
        stopLossPrice: records[0].stopLossPrice!,
        takeProfitPrice: records[0].takeProfitPrice!,
        allSize: allSize,
        size: records[0].size,
        buyCount,
        sellCount,
        profitLossRatio: records[0].profitLossRatio!,
        allTags: sortedAllTags,
        collapsedTrades,
      };
      openTrades.push(openTrade);
    }
  });

  closedTrades.sort(
    (a, b) => dayjs(b.tradeTime)?.diff(dayjs(a.tradeTime)) || 0
  );
  openTrades.sort((a, b) => dayjs(b.tradeTime)?.diff(dayjs(a.tradeTime)) || 0);

  const allTags = Object.entries(allTagsMap)
    .map(([name, tradeCount]) => ({
      tag: { name },
      tradeCount,
    }))
    .sort((a, b) => a.tag.name.localeCompare(b.tag.name)); // 태그 이름 기준 정렬

  return { closedTrades, openTrades, allTags }; // allTags 반환 추가
};

export const validateTradeTimes = (trades: TradeDataRecord[]): boolean => {
  let positionStartTime: number = 0;
  let positionEndTime: number = 0;

  for (const trade of trades) {
    //console.log(trade);
    if (trade.tradeType === "PositionStart") {
      if (positionStartTime === 0 || trade.tradeTime < positionStartTime) {
        positionStartTime = trade.tradeTime;
      }
    } else if (trade.tradeType === "PositionEnd") {
      if (positionEndTime === 0 || trade.tradeTime > positionEndTime) {
        positionEndTime = trade.tradeTime;
      }
    }
  }

  if (
    positionStartTime !== 0 &&
    positionEndTime !== 0 &&
    positionStartTime > positionEndTime
  )
    return false;

  for (const trade of trades) {
    if (
      trade.tradeType !== "PositionStart" &&
      trade.tradeType !== "PositionEnd"
    ) {
      if (
        (positionStartTime !== 0 && trade.tradeTime < positionStartTime) ||
        (positionEndTime !== 0 && trade.tradeTime > positionEndTime)
      ) {
        return false;
      }
    }
  }

  return true;
};

export const groupTradesByFeatures = (
  closedTrades: IClosedTradeTableData[]
) => {
  const groupMap: { [key: string]: IClosedTradeTableData[] } = {};

  closedTrades.forEach((trade) => {
    const key = `${trade.symbol}_${trade.position}_${
      trade.leverage
    }_${trade.allTags.map((tag) => tag.name).join(",")}`;
    if (!groupMap[key]) {
      groupMap[key] = [];
    }
    groupMap[key].push(trade);
  });

  return groupMap;
};

export const calculateGroupProfits = (groupMap: {
  [key: string]: IClosedTradeTableData[];
}) => {
  const groupProfits: { [key: string]: number } = {};

  Object.keys(groupMap).forEach((key) => {
    groupProfits[key] = groupMap[key].reduce(
      (acc, trade) => acc + trade.allProfit,
      0
    );
  });

  return groupProfits;
};

export const sortGroupsByProfit = (groupProfits: { [key: string]: number }) => {
  return Object.keys(groupProfits).sort(
    (a, b) => groupProfits[b] - groupProfits[a]
  );
};

// tradeTime 기준으로 정렬하는 함수
export function sortTradesByTradeTime(
  trades: IClosedTradeTableData[]
): IClosedTradeTableData[] {
  return trades
    .slice()
    .sort((a, b) => dayjs(a.tradeTime).diff(dayjs(b.tradeTime)));
}

export const calculateGroupMetrics = (
  groupMap: { [key: string]: IClosedTradeTableData[] },
  groupProfits: { [key: string]: number }
) => {
  const groupMetrics = Object.keys(groupMap).map((key) => {
    const trades = groupMap[key];
    const totalTrades = trades.length;
    const totalWins = trades.filter((trade) => trade.allProfit > 0).length;
    const totalBuys = trades.reduce((acc, trade) => acc + trade.buyCount, 0);
    const totalSells = trades.reduce((acc, trade) => acc + trade.sellCount, 0);
    const splitTrades = trades.filter(
      (trade) => trade.buyCount > 1 || trade.sellCount > 1
    ).length;

    return {
      key,
      totalTrades,
      winRate: (totalWins / totalTrades) * 100,
      splitTrades,
      totalProfit: groupProfits[key],
      commonTags: trades[0].allTags.map((tag) => tag.name),
    };
  });

  return groupMetrics;
};

export const findCommonTags = (
  groups: { key: string; commonTags: string[] }[]
) => {
  if (groups.length === 0) return [];

  let commonTags = groups[0].commonTags;

  groups.slice(1).forEach((group) => {
    commonTags = commonTags.filter((tag) => group.commonTags.includes(tag));
  });

  return commonTags;
};

export const applyFilter = (trade: any, key: string, value: any) => {
  if (key === "notes") {
    return trade.collapsedTrades.some(
      (collapsedTrade: ICollapseTradeTableData) =>
        value.every(
          (note: string) =>
            collapsedTrade &&
            collapsedTrade.notes &&
            collapsedTrade.notes.includes(note)
        )
    );
  }
  if (key === "startTradeTime" || key === "endTradeTime") {
    return value(trade["tradeTime"]);
  }
  if (typeof value === "function") {
    return value(trade[key]);
  } else if (Array.isArray(value)) {
    return value.includes(trade[key]);
  } else {
    return trade[key] === value;
  }
};

export interface CalculatedData {
  totalTradeCount: number;
  totalAllProfit: number;
  totalAllCommission: number;
  winCount: number;
  loseCount: number;
  winRate: number;
  successLongCount: number;
  successShortCount: number;
  successLongAllProfit: number;
  successShortAllProfit: number;
  failLongCount: number;
  failShortCount: number;
  failLongAllProfit: number;
  failShortAllProfit: number;
}

export function calculateClosedData(data: IClosedTradeTableData[]) {
  return data.reduce(
    (acc: CalculatedData, record) => {
      const allProfit = record.allProfit || 0;
      acc.totalAllProfit += allProfit;

      const allCommission = record.allCommission || 0;
      acc.totalAllCommission += allCommission;

      if (allProfit > 0) {
        acc.winCount++;
      } else if (allProfit < 0) {
        acc.loseCount++;
      }

      acc.winRate = round((acc.winCount / acc.totalTradeCount) * 100, 2);

      if (record.position === "LONG") {
        if (allProfit >= 0) {
          acc.successLongCount++;
          acc.successLongAllProfit += allProfit;
        } else {
          acc.failLongCount++;
          acc.failLongAllProfit += allProfit;
        }
      } else if (record.position === "SHORT") {
        if (allProfit >= 0) {
          acc.successShortCount++;
          acc.successShortAllProfit += allProfit;
        } else {
          acc.failShortCount++;
          acc.failShortAllProfit += allProfit;
        }
      }

      return acc;
    },
    {
      totalTradeCount: data.length,
      totalAllProfit: 0,
      totalAllCommission: 0,
      winCount: 0,
      loseCount: 0,
      winRate: 0,
      successLongCount: 0,
      successShortCount: 0,
      successLongAllProfit: 0,
      successShortAllProfit: 0,
      failLongCount: 0,
      failShortCount: 0,
      failLongAllProfit: 0,
      failShortAllProfit: 0,
    }
  );
}

// 날짜 범위를 생성하는 함수
function generateDateRange(startDate: Dayjs, endDate: Dayjs): string[] {
  const dateArray: string[] = [];

  const adjustedEndDate = endDate.endOf("day");

  let currentDate = startDate;
  while (
    currentDate.isBefore(adjustedEndDate) ||
    currentDate.isSame(adjustedEndDate)
  ) {
    dateArray.push(currentDate.format("YYYY-MM-DD"));
    currentDate = currentDate.add(1, "day");
  }

  return dateArray;
}

// 날짜별 allProfit을 모아서 새로운 형태의 객체로 변환하는 함수
export function aggregateProfitsByDate(
  trades: IClosedTradeTableData[],
  startTradeTime: Dayjs,
  endTradeTime: Dayjs
) {
  const aggregatedProfits: { [date: string]: number } = {};

  trades.forEach((trade) => {
    const date = dayjs(trade.tradeTime).format("YYYY-MM-DD");
    if (!aggregatedProfits[date]) {
      aggregatedProfits[date] = 0;
    }
    aggregatedProfits[date] += trade.allProfit || 0;
  });

  const dateRange = generateDateRange(startTradeTime, endTradeTime);

  return dateRange.map((date) => ({
    date,
    profit: aggregatedProfits[date] || 0,
  }));
}

export function aggregateProfitsBySymbol(trades: IClosedTradeTableData[]) {
  const aggregatedProfits: {
    [symbol: string]: { profit: number; winCount: number; loseCount: number };
  } = {};

  trades.forEach((trade) => {
    const symbol = trade.symbol || "unknown";
    if (!aggregatedProfits[symbol]) {
      aggregatedProfits[symbol] = { profit: 0, winCount: 0, loseCount: 0 };
    }
    aggregatedProfits[symbol].profit += trade.allProfit || 0;
    if (trade.allProfit > 0) {
      aggregatedProfits[symbol].winCount += 1;
    } else {
      aggregatedProfits[symbol].loseCount += 1;
    }
  });

  return Object.entries(aggregatedProfits).map(([symbol, data]) => ({
    symbol,
    profit: data.profit,
    winCount: data.winCount,
    loseCount: data.loseCount,
  }));
}

export function getMinMaxTradeTime(trades: IClosedTradeTableData[]): {
  minDateTime: Dayjs | null;
  maxDateTime: Dayjs | null;
} {
  if (trades.length === 0) {
    return { minDateTime: null, maxDateTime: null };
  }

  return trades.reduce(
    (acc, trade) => {
      const tradeTime = dayjs(trade.tradeTime);
      if (tradeTime.isBefore(acc.minDateTime)) {
        acc.minDateTime = tradeTime;
      }
      if (tradeTime.isAfter(acc.maxDateTime)) {
        acc.maxDateTime = tradeTime;
      }
      return acc;
    },
    {
      minDateTime: dayjs(trades[0].tradeTime),
      maxDateTime: dayjs(trades[0].tradeTime),
    }
  );
}

export function createTagRankTableData(
  trades: IClosedTradeTableData[],
  criteria: RankCriteria = "totalProfit",
  limit: number = 5
): ICreateAnalysisTagTableData[] {
  const tagStats: {
    [key: string]: {
      tags: TagOptionType[];
      winCount: number;
      loseCount: number;
      totalProfit: number;
    };
  } = {};

  trades.forEach((trade) => {
    const tagKey = trade.allTags
      .map((tag) => tag.name)
      .sort()
      .join(", ");
    if (!tagStats[tagKey]) {
      tagStats[tagKey] = {
        tags: trade.allTags,
        winCount: 0,
        loseCount: 0,
        totalProfit: 0,
      };
    }
    if (trade.allProfit > 0) {
      tagStats[tagKey].winCount += 1;
    } else {
      tagStats[tagKey].loseCount += 1;
    }
    tagStats[tagKey].totalProfit += trade.allProfit || 0;
  });

  const sortedTagStats = Object.entries(tagStats)
    .map(([_, stats]) => ({
      tags: stats.tags,
      winCount: stats.winCount,
      loseCount: stats.loseCount,
      totalProfit: stats.totalProfit,
      winLoseDifference: stats.winCount - stats.loseCount, // 승패 차이 계산
    }))
    .sort((a, b) => {
      if (criteria === "winLoseDifference") {
        const diff = b.winLoseDifference - a.winLoseDifference;
        if (diff !== 0) {
          return diff;
        } else {
          return b.totalProfit - a.totalProfit; // 승패 차이가 동일할 경우 총 수익 기준으로 정렬
        }
      } else {
        return b.totalProfit - a.totalProfit;
      }
    })
    .slice(0, limit); // 최대 limit 개수만큼 자르기;

  return sortedTagStats.map((stats, index) => ({
    rank: index + 1,
    tags: stats.tags,
    winCount: stats.winCount,
    loseCount: stats.loseCount,
    totalProfit: stats.totalProfit,
  }));
}

// 전체 투입금 대비 수익 비율을 계산하고 성과를 분류
export function classifyOverallPerformance(
  trades: IClosedTradeTableData[]
): PerformanceClassification {
  if (trades.length === 0) {
    return {
      category: "데이터 없음",
      profitPercentage: 0,
      totalReturn: 0,
      trades: [],
      totalTradeCount: 0,
      winCount: 0,
      splitWinCount: 0,
      loseCount: 0,
      splitLoseCount: 0,
      winRate: 0,
      tradePeriodDays: 0,
      totalProfit: 0,
      totalCommission: 0,
    };
  }

  const totalEntryAmount = trades.reduce(
    (sum, trade) => sum + trade.allEntryAmount,
    0
  );
  const totalProfit = trades.reduce((sum, trade) => sum + trade.allProfit, 0);
  const totalCommission = trades.reduce(
    (sum, trade) => sum + (trade.allCommission || 0),
    0
  ); // 수수료 합 계산
  const profitPercentage = (totalProfit / totalEntryAmount) * 100;

  // 거래 기간 계산
  const tradeTimes = trades.map((trade) => dayjs(trade.tradeTime));
  const minTradeTime = minDayjs(tradeTimes);
  const maxTradeTime = maxDayjs(tradeTimes);
  const tradePeriodDays = maxTradeTime.diff(minTradeTime, "day") + 1; // 총 거래 기간 (일)

  // 총 매매 횟수, 승리 횟수, 승리 중 분할 매수 횟수 계산
  const totalTradeCount = trades.length;
  let winCount = 0;
  let splitWinCount = 0;
  let loseCount = 0;
  let splitLoseCount = 0;

  trades.forEach((trade) => {
    if (trade.allProfit > 0) {
      winCount += 1;
      if (trade.collapsedTrades.length > 1) {
        splitWinCount += 1;
      }
    } else {
      loseCount += 1;
      if (trade.collapsedTrades.length > 1) {
        splitLoseCount += 1;
      }
    }
  });

  const winRate = round((winCount / totalTradeCount) * 100, 2);
  const totalReturn = round((totalProfit / totalEntryAmount) * 100, 2);

  let category = "";

  // 성과 기준 분류
  if (profitPercentage <= -30) {
    category = "매우 저조한 성과";
  } else if (profitPercentage > -30 && profitPercentage <= -10) {
    category = "저조한 성과";
  } else if (profitPercentage > -10 && profitPercentage <= 0) {
    category = "조금 저조한 성과";
  } else if (profitPercentage > 0 && profitPercentage <= 10) {
    category = "미비한 성과";
  } else if (profitPercentage > 10 && profitPercentage <= 30) {
    category = "좋은 성과";
  } else if (profitPercentage > 30 && profitPercentage <= 50) {
    category = "매우 좋은 성과";
  } else if (profitPercentage > 50 && profitPercentage <= 100) {
    category = "우수한 성과";
  } else if (profitPercentage > 100 && profitPercentage <= 200) {
    category = "매우 우수한 성과";
  } else if (profitPercentage > 200) {
    category = "대단히 우수한 성과";
  }

  return {
    category,
    profitPercentage,
    totalReturn, // 추가
    trades,
    totalTradeCount,
    winCount,
    splitWinCount,
    loseCount,
    splitLoseCount,
    winRate,
    tradePeriodDays,
    totalProfit,
    totalCommission,
  };
}

// 심볼별 분석 수행
export function analyzeSymbolPerformance(
  trades: IClosedTradeTableData[],
  totalProfit: number
): SymbolAnalysis[] {
  const symbolStats: { [symbol: string]: SymbolAnalysis } = {};

  trades.forEach((trade) => {
    const symbol = trade.symbol || "unknown";
    if (!symbolStats[symbol]) {
      symbolStats[symbol] = {
        symbol: symbol,
        profitPercentage: 0,
        totalProfit: 0,
        totalProfitPercentage: 0,
        totalLoss: 0,
        winCount: 0,
        loseCount: 0,
        totalTradeCount: 0,
        longPositionWinCount: 0,
        shortPositionWinCount: 0,
        longPositionLoseCount: 0,
        shortPositionLoseCount: 0,
        tagsUsage: {},
        commonWinTags: [],
        commonLoseTags: [],
        splitTradeCount: 0,
        winSplitTradeCount: 0,
        loseSplitTradeCount: 0,
        avgWinProfit: 0,
        avgLoseProfit: 0,
        profitLossRatio: 0,
        leastProfitableTags: undefined,
      };
    }

    const symbolStat = symbolStats[symbol];
    symbolStat.totalProfit += trade.allProfit;
    symbolStat.profitPercentage +=
      (trade.allProfit / trade.allEntryAmount) * 100;
    symbolStat.totalTradeCount += 1;

    if (trade.allProfit > 0) {
      symbolStat.winCount += 1;
      symbolStat.avgWinProfit += trade.allProfit;
      if (trade.position === "LONG") {
        symbolStat.longPositionWinCount += 1;
      } else if (trade.position === "SHORT") {
        symbolStat.shortPositionWinCount += 1;
      }
    } else {
      symbolStat.loseCount += 1;
      symbolStat.avgLoseProfit += trade.allProfit;
      if (trade.position === "LONG") {
        symbolStat.longPositionLoseCount += 1;
      } else if (trade.position === "SHORT") {
        symbolStat.shortPositionLoseCount += 1;
      }
    }

    // 태그 조합을 문자열로 변환하여 하나의 세트로 저장
    const tagCombo = trade.allTags
      .map((tag) => tag.name)
      .sort()
      .join(", ");

    if (!symbolStat.tagsUsage[tagCombo]) {
      symbolStat.tagsUsage[tagCombo] = { count: 0, totalProfit: 0 };
    }
    symbolStat.tagsUsage[tagCombo].count += 1;
    symbolStat.tagsUsage[tagCombo].totalProfit += trade.allProfit;

    if (trade.collapsedTrades.length > 1) {
      symbolStat.splitTradeCount += 1;
      if (trade.allProfit > 0) {
        symbolStat.winSplitTradeCount += 1;
      } else {
        symbolStat.loseSplitTradeCount += 1;
      }
    }

    symbolStat.totalProfitPercentage =
      (symbolStat.totalProfit / totalProfit) * 100;
  });

  // 공통 태그 계산 및 가장 많이 사용된 태그와 가장 수익을 많이 얻어낸 태그 계산
  for (const symbol in symbolStats) {
    const symbolStat = symbolStats[symbol];
    symbolStat.avgWinProfit = symbolStat.winCount
      ? symbolStat.avgWinProfit / symbolStat.winCount
      : 0;
    symbolStat.avgLoseProfit = symbolStat.loseCount
      ? symbolStat.avgLoseProfit / symbolStat.loseCount
      : 0;
    symbolStat.profitLossRatio = round(
      symbolStat.avgWinProfit /
        Math.abs(symbolStat.avgLoseProfit === 0 ? 1 : symbolStat.avgLoseProfit),
      2
    );

    const winTagsSets = trades
      .filter((trade) => trade.symbol === symbol && trade.allProfit > 0)
      .map(
        (trade) =>
          new Set(
            trade.collapsedTrades.flatMap(
              (subTrade) =>
                subTrade.tags &&
                subTrade.tags.map((tag) => tag.name).filter(Boolean)
            )
          )
      );
    const loseTagsSets = trades
      .filter((trade) => trade.symbol === symbol && trade.allProfit <= 0)
      .map(
        (trade) =>
          new Set(
            trade.collapsedTrades.flatMap(
              (subTrade) =>
                subTrade.tags &&
                subTrade.tags.map((tag) => tag.name).filter(Boolean)
            )
          )
      );

    const commonWinTags = winTagsSets.reduce((commonTags, tagsSet) => {
      return new Set(Array.from(commonTags).filter((tag) => tagsSet.has(tag)));
    }, winTagsSets[0] || new Set());

    const commonLoseTags = loseTagsSets.reduce((commonTags, tagsSet) => {
      return new Set(Array.from(commonTags).filter((tag) => tagsSet.has(tag)));
    }, loseTagsSets[0] || new Set());

    symbolStat.commonWinTags = Array.from(commonWinTags)
      .filter(Boolean)
      .map((tag) => ({ name: tag as string }));
    symbolStat.commonLoseTags = Array.from(commonLoseTags)
      .filter(Boolean)
      .map((tag) => ({ name: tag as string }));

    const tagsArray = Object.entries(symbolStat.tagsUsage).map(
      ([tag, { count, totalProfit }]) => ({ tag, count, totalProfit })
    );

    const mostUsedTag = tagsArray.reduce(
      (max, tag) => (tag.count > max.count ? tag : max),
      tagsArray[0]
    );
    const mostProfitableTag = tagsArray.reduce(
      (max, tag) => (tag.totalProfit > max.totalProfit ? tag : max),
      tagsArray[0]
    );

    const leastProfitableTag = tagsArray.reduce(
      (min, tag) => (tag.totalProfit < min.totalProfit ? tag : min),
      tagsArray[0]
    );

    symbolStat.mostUsedTags = {
      tags: mostUsedTag.tag.split(", ").map((tag) => ({ name: tag })),
      count: mostUsedTag.count,
      totalProfit: mostUsedTag.totalProfit,
    };

    symbolStat.mostProfitableTags = {
      tags: mostProfitableTag.tag.split(", ").map((tag) => ({ name: tag })),
      count: mostProfitableTag.count,
      totalProfit: mostProfitableTag.totalProfit,
    };

    symbolStat.leastProfitableTags = {
      tags: leastProfitableTag.tag.split(", ").map((tag) => ({ name: tag })),
      count: leastProfitableTag.count,
      totalProfit: leastProfitableTag.totalProfit,
    };
  }

  return Object.values(symbolStats);
}

// 태그 사용 정보를 사용된 수익대로 정렬된 배열로 변환하는 함수 추가
export function sortTagsUsage(tagsUsage: {
  [tag: string]: { count: number; totalProfit: number };
}): { tag: string; count: number; totalProfit: number }[] {
  return Object.entries(tagsUsage)
    .map(([tag, { count, totalProfit }]) => ({ tag, count, totalProfit }))
    .sort((a, b) => b.totalProfit - a.totalProfit);
}

// 전체 분석 수행
export function analyzeTrades(
  trades: IClosedTradeTableData[]
): AnalyzeTradesResult {
  if (trades.length === 0) {
    return {
      category: "데이터 없음",
      profitPercentage: 0,
      totalTradeCount: 0,
      winCount: 0,
      splitWinCount: 0,
      loseCount: 0,
      splitLoseCount: 0,
      winRate: 0,
      tradePeriodDays: 0,
      totalProfit: 0,
      totalReturn: 0,
      totalCommission: 0,
      performance: {
        highestProfitSymbol: undefined,
        lowestProfitSymbol: undefined,
        highestLossSymbol: undefined,
      },
    };
  }

  const {
    category,
    profitPercentage,
    trades: classifiedTrades,
    totalTradeCount,
    winCount,
    splitWinCount,
    loseCount,
    splitLoseCount,
    winRate,
    tradePeriodDays,
    totalProfit,
    totalReturn,
    totalCommission,
  } = classifyOverallPerformance(trades);

  const performance = analyzeSymbolPerformance(classifiedTrades, totalProfit);

  // 2-1-1. 가장 높은 수익을 얻은 심볼 기준으로 정렬 및 분석
  const highestProfitSymbol = performance.reduce((a, b) =>
    a.totalProfit > b.totalProfit ? a : b
  );
  highestProfitSymbol.totalTradeCount = trades.filter(
    (trade) => trade.symbol === highestProfitSymbol.symbol
  ).length;

  // 가장 낮은 수익을 얻은 심볼 찾기 및 분석
  const lowestProfitSymbol = performance.reduce((a, b) =>
    a.totalProfit < b.totalProfit ? a : b
  );
  lowestProfitSymbol.totalTradeCount = trades.filter(
    (trade) => trade.symbol === lowestProfitSymbol.symbol
  ).length;

  // 가장 높은 손실을 발생한 심볼 찾기 및 분석
  const highestLossSymbol = performance.reduce(
    (acc, cur) => {
      const totalLoss = trades
        .filter((trade) => trade.symbol === cur.symbol && trade.allProfit < 0)
        .reduce((sum, trade) => sum + trade.allProfit, 0);
      return totalLoss < acc.totalProfit ? { ...cur, totalLoss } : acc;
    },
    { ...performance[0], totalLoss: 0 }
  );

  const highestLossSymbolData =
    highestLossSymbol.totalLoss < 0 ? highestLossSymbol : undefined;

  return {
    category,
    profitPercentage,
    totalTradeCount,
    winCount,
    splitWinCount,
    loseCount,
    splitLoseCount,
    winRate,
    tradePeriodDays,
    totalProfit,
    totalReturn,
    totalCommission,
    performance: {
      highestProfitSymbol,
      lowestProfitSymbol,
      highestLossSymbol: highestLossSymbolData,
    },
  };
}
