import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Snackbar,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import CustomCard from "../../components/CustomCard";
import CustomTable from "../../components/CustomTable";

//Title icons
import AddIcon from "@mui/icons-material/Add";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

import { createJournalTableData } from "./CreateJournal/CreatedJournalTableData";
import { createdJournalHeaderList } from "./CreateJournal/CreatedJournalTableData";
import {
  createProgressingTradeTableData,
  progressingTradeHeaderList,
} from "./CreateJournal/ProgressingJournalTableData";

import HistoryStack from "../../components/HistoryStack";

import { useAppDispatch } from "../../store/hooks"; // 경로는 맞게 수정

import { ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { store, RootState } from "../../store/store";
import {
  selectAddLoading,
  selectedJournalUpdatedAt,
  selectHistoryData,
  selectJournals,
  selectLoading,
  selectProgressingTrades,
  selectUpdateDateAt,
} from "../../store/selector/journalDataSelector";
import {
  IHistoryStackData,
  IJournalTableData,
  IOpenTradeTableData,
  IProgressTableData,
  TradeDataRecord,
  TradeType,
} from "./types";
import {
  addJournal,
  deleteJournal,
  fetchJournals,
  setHistoryData,
  setJournals,
  setProgressingTrades,
} from "../../store/reducers/journal/data/journalData";

import { v4 as uuidv4 } from "uuid";
import dayjs, { Dayjs } from "dayjs";
import { TIME_FORMAT } from "../../utils/utils";
import { useSnackbar } from "notistack";
import {
  fetchTradeData,
  setTradeDatas,
} from "../../store/reducers/journal/data/tradeData";
import {
  selectClosedTrades,
  selectOpenTrades,
  selectTradeDatas,
} from "../../store/selector/tradeDataSelector";
import { TradeTypeMap } from "./CommonTableNode";
import {
  updateUser,
  updateUserState,
  UserState,
} from "../../store/reducers/user";
import { userData } from "../../store/selector/userSelector";

const JournalOverViewPage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const journals: IJournalTableData[] = useSelector((state: RootState) =>
    selectJournals(state)
  );

  const historyData: IHistoryStackData[] = useSelector((state: RootState) =>
    selectHistoryData(state)
  );

  const progressingTradeData: IProgressTableData[] = useSelector(
    (state: RootState) => selectProgressingTrades(state)
  );

  const loading: boolean = useSelector((state: RootState) =>
    selectLoading(state)
  );

  const addLoading: boolean = useSelector((state: RootState) =>
    selectAddLoading(state)
  );

  const updateDateAt: Dayjs = useSelector((state: RootState) =>
    selectUpdateDateAt(state)
  );

  const journalLastUpdateAt: number = useSelector((state: RootState) =>
    selectedJournalUpdatedAt(state)
  );

  const user: UserState = useSelector((state: RootState) => userData(state));

  const [isFetchedTrades, setIsFetchedTrades] = useState(dayjs());

  useEffect(() => {
    if (user.journalIds.length === 0) return;

    const fetchAllData = async () => {
      try {
        const journalDatas = await dispatch(
          fetchJournals({ journalIds: user.journalIds })
        ).unwrap();

        //console.log("Journal data fetched successfully:", journalDatas);

        let allDatas: Array<IJournalTableData & TradeDataRecord> = [];

        // 비동기 작업을 순차적으로 처리하기 위해 for...of 사용
        for (const journalData of journalDatas) {
          try {
            const result = await dispatch(
              fetchTradeData(journalData.Jid)
            ).unwrap();

            result.tradeDatas.forEach((tradeData) => {
              allDatas.push({ ...tradeData, ...journalData });
            });
          } catch (error) {
            console.error("Failed to fetch trade data:", error);
            enqueueSnackbar(
              `데이터를 가져오는 과정에서 에러가 발생하였습니다. ${error}`,
              {
                variant: "error",
                autoHideDuration: 3000,
              }
            );
          }
        }
        // 모든 비동기 작업이 완료된 후 슬라이스 실행
        const recentDataList = allDatas
          .sort((a, b) => b.tradeTime - a.tradeTime) // 내림차순으로 정렬
          .slice(0, 5);

        const initialHistoryData: IHistoryStackData[] = [];
        recentDataList.forEach((trade) => {
          initialHistoryData.push({
            title: trade.symbol || "",
            subTitle: `일지 - ${trade.title}`,
            logo: trade.symbol || "",
            content: `${trade.entryPrice?.toLocaleString()} USDT 에서 ${trade.position?.toUpperCase()} 포지션\n${
              TradeTypeMap[trade.tradeType as TradeType]
            }`,
            tradeTime: trade.tradeTime || 0,
          });
        });

        // 최종 데이터를 Redux state에 저장
        dispatch(setHistoryData(initialHistoryData));

        setIsFetchedTrades(dayjs());
      } catch (error) {
        console.error("Failed to fetch journal data:", error);
        enqueueSnackbar(
          `데이터를 가져오는 과정에서 에러가 발생하였습니다. ${error}`,
          {
            variant: "error",
            autoHideDuration: 3000,
          }
        );
      }
    };

    // 비동기 함수 호출
    fetchAllData();
  }, [user, updateDateAt, dispatch]); // 의존성 배열에 user와 dispatch를 추가

  useEffect(() => {
    const fetchProgressingTrades = async () => {
      const progressingTrades: IProgressTableData[] = [];

      for (const journal of journals) {
        const state = store.getState(); // 상태를 가져옵니다.
        const openTrades: IOpenTradeTableData[] = selectOpenTrades(
          state,
          journal.Jid
        );

        for (const trade of openTrades) {
          progressingTrades.push({
            connectedName: journal.title,
            exchange: trade.exchange || "바이낸스",
            symbol: trade.symbol || "BTCUSDT",
            position: trade.position || "",
            leverage: trade.leverage || 0,
            entryPrice: trade.avgEntryPrice || 0,
            takeProfitPrice: trade.takeProfitPrice || 0,
            tradeTime: trade.tradeTime || 0,
          });
        }
      }

      // 모든 작업이 완료된 후 dispatch 실행
      dispatch(setProgressingTrades(progressingTrades));
    };

    // 비동기 함수 호출
    fetchProgressingTrades();
  }, [isFetchedTrades, dispatch]);

  const [journalsNodeList, setJournalsNodeList] = useState<ReactNode[][]>([]);
  const [progressingDataNodeList, setProgressingDataNodeList] = useState<
    ReactNode[][]
  >([]);

  const handleDeleteJournal = async ({
    journalId,
    user,
  }: {
    journalId: string;
    user: UserState;
  }) => {
    await dispatch(deleteJournal({ journalId: journalId, user: user }))
      .then(async (result) => {
        if (result.meta.requestStatus === "fulfilled") {
          const returnJid = result.payload as string;

          await dispatch(
            updateUser({
              uid: user.uid,
              updateData: {
                journalIds: user.journalIds.filter((Jid) => Jid !== returnJid),
              },
            })
          )
            .then((result2) => {
              if (result2.meta.requestStatus === "fulfilled") {
                const updatedData = result2.payload as Partial<UserState>;
                updateUserState(updatedData);
                enqueueSnackbar(`일지를 성공적으로 삭제하였습니다.`, {
                  variant: "info",
                  autoHideDuration: 2000,
                });
              } else {
                enqueueSnackbar(
                  `일지 삭제 중 에러 발생하였습니다. 잠시후 시도해주세요. : ${result.payload}`,
                  {
                    variant: "error",
                    autoHideDuration: 3000,
                  }
                );
              }
            })
            .catch((error) => {
              enqueueSnackbar(
                `일지 삭제 중 에러 발생하였습니다. 잠시후 시도해주세요. : ${error}`,
                {
                  variant: "error",
                  autoHideDuration: 3000,
                }
              );
            });
        } else {
          enqueueSnackbar(
            `일지를 삭제 중 에러가 발생하였습니다 : ${result.payload}`,
            {
              variant: "error",
              autoHideDuration: 3000,
            }
          );
        }
      })
      .catch((error) => {
        enqueueSnackbar(`일지를 삭제 중 에러가 발생하였습니다 : ${error}`, {
          variant: "error",
          autoHideDuration: 3000,
        });
      });
  };

  useEffect(() => {
    setJournalsNodeList(
      journals.map((journalData) =>
        createJournalTableData({
          props: journalData,
          menuCallback: () => {
            handleDeleteJournal({ journalId: journalData.Jid, user: user });
          },
        })
      )
    );
  }, [journals]);

  useEffect(() => {
    setProgressingDataNodeList(
      progressingTradeData.map((tradeData) =>
        createProgressingTradeTableData(tradeData)
      )
    );
  }, [progressingTradeData]);

  const [newJournalDialogOpen, setNewJournalDialogOpen] = useState(false);

  const [title, setTitle] = useState("");
  const [helperText, setHelperText] =
    useState("중복되지 않는 이름을 써주세요.");
  const [titleTextState, setTitleTextState] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleNewJournalClickOpen = () => {
    setNewJournalDialogOpen(true);
  };

  const handleNewJournalClose = () => {
    setNewJournalDialogOpen(false);
    setTitle(""); // Clear the title when dialog is closed
  };

  const handleJournalCreate = async () => {
    const newTitle = title.trim();

    if (newTitle !== "") {
      const newJournalData: IJournalTableData = {
        Jid: uuidv4(),
        updatedAt: dayjs().valueOf(),
        title: newTitle,
        winRate: 0,
        profitRate: 0,
        exchangeMap: {},
        createdAt: dayjs().valueOf(),
        loseTradeCount: 0,
        winTradeCount: 0,
        totalTradeCount: 0,
        totalOrderCount: 0,
        totalProfit: 0,
        challengeAmount: 10000,
        marketType: "USDT-FUTURES",
      };

      await dispatch(addJournal({ newJournalData: newJournalData, user: user }))
        .then(async (result) => {
          if (result.meta.requestStatus === "fulfilled") {
            setHelperText("중복되지 않는 이름을 써주세요.");
            setTitleTextState(false);
            handleNewJournalClose();
            await dispatch(
              updateUser({
                uid: user.uid,
                updateData: {
                  journalIds: [...user.journalIds, newJournalData.Jid],
                },
              })
            )
              .then((result2) => {
                if (result.meta.requestStatus === "fulfilled") {
                  const updatedUserData = result2 as Partial<UserState>;

                  updateUserState(updatedUserData);
                  enqueueSnackbar(`"${newTitle}" 일지가 추가되었습니다.`, {
                    variant: "info",
                    autoHideDuration: 2000,
                  });
                } else {
                  enqueueSnackbar(
                    `서버에 업로드 중 에러 발생하였습니다. 잠시후 시도해주세요.`,
                    {
                      variant: "error",
                      autoHideDuration: 3000,
                    }
                  );
                  setTitleTextState(false);
                  handleNewJournalClose();
                }
              })
              .catch((error) => {
                enqueueSnackbar(
                  `서버에 업로드 중 에러 발생하였습니다. 잠시후 시도해주세요.`,
                  {
                    variant: "error",
                    autoHideDuration: 3000,
                  }
                );
                setTitleTextState(false);
                handleNewJournalClose();
              });
          } else {
            const error = result.payload;
            if (error === "duplicate") {
              setHelperText("중복된 이름입니다.");
              setTitleTextState(true);
            } else if (error === "Firestore Error") {
              enqueueSnackbar(
                `서버에 업로드 중 에러 발생하였습니다. 잠시후 시도해주세요.`,
                {
                  variant: "error",
                  autoHideDuration: 3000,
                }
              );
              setTitleTextState(false);
              handleNewJournalClose();
            }
          }
        })
        .catch((error) => {
          if (error === "duplicate") {
            setHelperText("중복된 이름입니다.");
            setTitleTextState(true);
          } else if (error === "Firestore Error") {
            enqueueSnackbar(
              `서버에 업로드 중 에러 발생하였습니다. 잠시후 시도해주세요.`,
              {
                variant: "error",
                autoHideDuration: 3000,
              }
            );
            setTitleTextState(false);
            handleNewJournalClose();
          }
        });
    } else {
      setHelperText("이름을 작성해주세요.");
      setTitleTextState(true);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        component={"main"}
        sx={{
          flexGrow: 1,
          transition: "flex-grow 0.3s",
          overflow: "hidden",
          maxWidth: matches ? "100%" : "90%",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={9}>
            <Stack spacing={3}>
              <CustomCard
                titleText={`📗 일지 목록 (${journals.length})`}
                subTitleText={`마지막 수정 일자 ${dayjs(
                  journalLastUpdateAt
                ).format("YYYY-MM-DD HH:mm:ss")}`}
                subTitleIcon={<CheckCircleRoundedIcon />}
                isActionButton={true}
                isInfoButton={true}
                infoText={
                  "현재 작성 중인 일지들을 보여줍니다.\n일지에 작성된 거래 내역을 통해 간단한 통계를 볼 수 있으며 들어가기 버튼으로 상세 페이지로 넘어갑니다.\n\n연동하기 : 등록된 거래소 계정과 연결하여 상단 [거래소 데이터 업데이트] 버튼을 통해 자동으로 데이터를 가져올 수 있습니다.\n\n연동불가: 기존에 직접 데이터를 추가하였다면 연동이 불가합니다. 기존데이터를 지우거나 새로운 일지를 만들어주세요.\n"
                }
                actionButtionIcon={
                  addLoading && addLoading ? null : <AddIcon />
                }
                actionButtionText={
                  addLoading && addLoading ? (
                    <CircularProgress sx={{ color: "white", padding: 1 }} />
                  ) : matches ? (
                    "추가"
                  ) : (
                    "새 일지 만들기"
                  )
                }
                onClick={handleNewJournalClickOpen}
                child={
                  loading && loading ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: 10,
                      }}
                    >
                      <CircularProgress size="30px" />
                    </Box>
                  ) : (
                    <CustomTable
                      rows={journalsNodeList}
                      headerNames={createdJournalHeaderList}
                    />
                  )
                }
                isExpanded={false}
              />
              <CustomCard
                titleText={`🔥 진행 중인 거래 (${progressingTradeData.length})`}
                subTitleText={`마지막 수정 일자 ${dayjs(
                  journalLastUpdateAt
                ).format("YYYY-MM-DD HH:mm:ss")}`}
                subTitleIcon={<CheckCircleRoundedIcon />}
                child={
                  loading && loading ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: 10,
                      }}
                    >
                      <CircularProgress size="30px" />
                    </Box>
                  ) : (
                    <CustomTable
                      rows={progressingDataNodeList}
                      headerNames={progressingTradeHeaderList}
                    />
                  )
                }
                isActionButton={false}
                isExpanded={false}
              />
            </Stack>
          </Grid>
          {matches ? null : (
            <Grid item xs={4} md={3}>
              <Stack spacing={3}>
                <CustomCard
                  titleText="⚡ 최근 매매 내역"
                  isActionButton={false}
                  isExpanded={false}
                  minChildHeight={"580px"}
                  maxChildHeight={"580px"}
                  child={
                    loading && loading ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: 10,
                        }}
                      >
                        <CircularProgress size="30px" />
                      </Box>
                    ) : (
                      <HistoryStack data={historyData} />
                    )
                  }
                />
                {/* <CustomCard
                titleText="광고"
                isActionButton={false}
                isExpanded={false}
              />
              <CustomCard
                titleText="광고"
                isActionButton={false}
                isExpanded={false}
              /> */}
              </Stack>
            </Grid>
          )}

          <Dialog open={newJournalDialogOpen} onClose={handleNewJournalClose}>
            <DialogTitle>새 일지 만들기</DialogTitle>
            <DialogContent>
              <TextField
                error={titleTextState}
                autoFocus
                margin="dense"
                label="일지 이름"
                type="text"
                fullWidth
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                helperText={helperText}
                required
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleJournalCreate} color="primary">
                생성
              </Button>
              <Button onClick={handleNewJournalClose} color="primary">
                취소
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Box>
    </Box>
  );
};

export default JournalOverViewPage;
