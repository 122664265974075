import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  Auth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  UserCredential,
  signOut as firebaseSignOut,
  User,
} from "firebase/auth";

// Define the initial state for your slice
interface AuthState {
  user: User | null; // User로 수정
  loading: boolean;
  error: string | null;
  passwordResetSent: boolean;
  idToken: string | null; // 추가된 토큰 상태
}

const initialState: AuthState = {
  user: null,
  loading: false,
  error: null,
  passwordResetSent: false,
  idToken: null, // 초기값 null
};

// Define the async thunk for signing in
export const signIn = createAsyncThunk(
  "auth/signIn",
  async (
    { auth, email, password }: { auth: Auth; email: string; password: string },
    { rejectWithValue }
  ) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const idToken = await userCredential.user.getIdToken(); // 토큰 가져오기
      return { user: userCredential.user, idToken }; // User와 토큰을 반환
    } catch (error: any) {
      return rejectWithValue({ code: error.code, message: error.message });
    }
  }
);

// Define the async thunk for sending a password reset email
export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (
    { auth, resetEmail }: { auth: Auth; resetEmail: string },
    { rejectWithValue }
  ) => {
    try {
      await sendPasswordResetEmail(auth, resetEmail);
      return "Password reset email sent";
    } catch (error: any) {
      return rejectWithValue({ code: error.code, message: error.message });
    }
  }
);

// Define the async thunk for signing up
export const signUp = createAsyncThunk(
  "auth/signUp",
  async (
    { auth, email, password }: { auth: Auth; email: string; password: string },
    { rejectWithValue }
  ) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const idToken = await userCredential.user.getIdToken(); // 토큰 가져오기
      return { user: userCredential.user, idToken }; // User와 토큰을 반환
    } catch (error: any) {
      return rejectWithValue({ code: error.code, message: error.message });
    }
  }
);

// Define the async thunk for signing out
export const signOutUser = createAsyncThunk(
  "auth/signOut",
  async ({ auth }: { auth: Auth }, { rejectWithValue }) => {
    try {
      await firebaseSignOut(auth);
      return;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// Create the slice
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
    setUser(state, action: PayloadAction<{ user: User; idToken: string }>) {
      state.user = action.payload.user;
      state.idToken = action.payload.idToken;
    },
  },

  extraReducers: (builder) => {
    builder
      // signIn cases
      .addCase(signIn.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        signIn.fulfilled,
        (
          state,
          action: PayloadAction<{
            user: User;
            idToken: string;
          }>
        ) => {
          state.loading = false;
          state.user = action.payload.user;
          state.idToken = action.payload.idToken; // 토큰 저장
        }
      )
      .addCase(signIn.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // resetPassword cases
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.passwordResetSent = false;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
        state.passwordResetSent = true;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
        state.passwordResetSent = false;
      })
      // signUp cases
      .addCase(signUp.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        signUp.fulfilled,
        (
          state,
          action: PayloadAction<{
            user: User;
            idToken: string;
          }>
        ) => {
          state.loading = false;
          state.user = action.payload.user;
          state.idToken = action.payload.idToken; // 토큰 저장
        }
      )
      .addCase(signUp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // signOut cases
      .addCase(signOutUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signOutUser.fulfilled, (state) => {
        state.loading = false;
        state.user = null;
        state.idToken = null; // 토큰 초기화
      })
      .addCase(signOutUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

// Export the clearError action and the reducer
export const { clearError, setUser } = authSlice.actions;
export default authSlice.reducer;
