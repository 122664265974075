import BinanceIcon from "./BinanceIcon";
import BitgetIcon from "./BitgetIcon";
import { JSXElementConstructor } from "react";

interface ExchangeIconTableType {
  [key: string]: JSX.Element;
}

const createExchangeIconTable = (
  width: number,
  height: number
): ExchangeIconTableType => {
  return {
    //바이낸스: <BinanceIcon width={width} height={height} />,
    비트겟: <BitgetIcon width={width} height={height} />,
  };
};

export default createExchangeIconTable;
