import { Suspense, lazy, useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useAppSelector } from "./store/hooks";
import { paths } from "./paths";
import JournalOverViewPage from "./pages/Journal/JournalOverviewPage";
import JournalDetailPage from "./pages/Journal/JournalDetailPage";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase";
import { store } from "./store";
import { setUser } from "./store/reducers/auth";
import { fetchUserData } from "./store/reducers/user";
import { enqueueSnackbar } from "notistack";

const PublicOutlet = lazy(() => import("./layout/PublicOutlet"));
const PrivateOutlet = lazy(() => import("./layout/PrivateOutlet"));
const OverView = lazy(() => import("./pages/Overview"));
const Journal = lazy(() => import("./pages/Journal/Journal"));
const Analysis = lazy(() => import("./pages/Analysis"));
const Exchange = lazy(() => import("./pages/Exchange/ExchangeConnect"));
const LeaderBoard = lazy(() => import("./pages/LeaderBoard"));
const ApiManager = lazy(() => import("./pages/ApiManager"));
const Settings = lazy(() => import("./pages/Settings"));
const Home = lazy(() => import("./pages/Home"));
const Contact = lazy(() => import("./pages/Contact"));
const About = lazy(() => import("./pages/About"));
const SignIn = lazy(() => import("./pages/auth/SignIn"));
const SignUp = lazy(() => import("./pages/auth/SignUp"));
const HomeBeta = lazy(() => import("./pages/Home(Beta)"));

function App() {
  const theme = useAppSelector((state) => state.util.theme);

  useEffect(() => {
    if (theme === "light") {
      document.documentElement.classList.remove("dark");
    } else {
      document.documentElement.classList.add("dark");
    }
  }, [theme]);

  return (
    <div className="max-w-[1920px] h-screen m-auto animate-fade-in-up">
      {/*<Suspense fallback={<SplashPage />}> 필요할 경우, SplashPage 구현*/}
      <BrowserRouter>
        <Suspense>
          <Routes>
            {/* 홈 화면 Route */}
            <Route path={paths.home} element={<PublicOutlet />}>
              {/* <Route path={paths.home} element={<HomeBeta />} /> */}
              <Route path={paths.contact} element={<Contact />} />
              <Route path={paths.about} element={<About />} />
              <Route path={paths.home} element={<Home />} />
              {/* <Route path="contact" element={<ContactPage />} />
              <Route path="faq" element={<FAQPage />} />
              <Route path="pricing" element={<PricingPage />} /> */}
            </Route>
            {/* // 로그인 화면 Route */}
            <Route path="" element={<PublicOutlet />}>
              <Route path={paths.auth.signIn} element={<SignIn />} />
              <Route path={paths.auth.signUp} element={<SignUp />} />
              {/* <Route path="forget-password" element={<ForgetPasswordPage />} />
              <Route path="user/set-password" element={<SetPassword />} /> */}
            </Route>
            {/* 대시보드 화면 Route */}
            <Route path={paths.dashboard.overview} element={<PrivateOutlet />}>
              <Route
                path={paths.dashboard.overview}
                element={
                  <Navigate to={paths.dashboard.journal.layout} replace />
                }
              />

              <Route
                path={paths.dashboard.journal.layout}
                element={<Journal />}
              >
                <Route
                  path={paths.dashboard.journal.overview}
                  element={<JournalOverViewPage />}
                />
                <Route
                  path={`${paths.dashboard.journal.detail}/:name`}
                  element={<JournalDetailPage />}
                />
              </Route>
              <Route path={paths.dashboard.analysis} element={<Analysis />} />
              <Route
                path={paths.dashboard.leaderboard}
                element={<LeaderBoard />}
              />
              <Route
                path={paths.dashboard.exchange.connect}
                element={<Exchange />}
              />
              <Route
                path={paths.dashboard.apimanager}
                element={<ApiManager />}
              />
              <Route path={paths.dashboard.settings} element={<Settings />} />
            </Route>
            {/* <Route path="*" element={<Navigate to={"/"} />} /> */}
            {/*<Route path="*" element={<Navigate to={"/nothing"} />} /> 경우에 따라 nothing 페이지 구현*/}
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
