import { useCallback, useEffect, useState, useRef } from "react";

type UseResizeProps = {
  minWidth: number;
  initialWidth: number;
};

type UseResizeReturn = {
  width: number;
  enableResize: (e: React.MouseEvent) => void;
};

const useResize = ({
  minWidth,
  initialWidth,
}: UseResizeProps): UseResizeReturn => {
  const [isResizing, setIsResizing] = useState(false);
  const [width, setWidth] = useState(initialWidth);
  const [startX, setStartX] = useState(0);
  const [startWidth, setStartWidth] = useState(initialWidth);
  const animationFrameId = useRef<number | null>(null);

  const enableResize = useCallback(
    (e: React.MouseEvent) => {
      setIsResizing(true);
      setStartX(e.clientX);
      setStartWidth(width);
      document.body.style.userSelect = "none"; // 텍스트 선택 방지
      document.body.style.pointerEvents = "none"; // 포인터 이벤트 방지
      document.body.style.cursor = "ew-resize"; // 커서 모양 변경
    },
    [width]
  );

  const disableResize = useCallback(() => {
    setIsResizing(false);
    if (animationFrameId.current) {
      cancelAnimationFrame(animationFrameId.current);
    }
    document.body.style.userSelect = ""; // 원래 상태로 되돌림
    document.body.style.pointerEvents = ""; // 원래 상태로 되돌림
    document.body.style.cursor = ""; // 원래 상태로 되돌림
  }, []);

  const resize = useCallback(
    (e: MouseEvent) => {
      if (isResizing) {
        const deltaX = startX - e.clientX;
        const newWidth = startWidth + deltaX;
        if (newWidth >= minWidth) {
          setWidth(newWidth);
        }
      }
    },
    [isResizing, startX, startWidth, minWidth]
  );

  const resizeWithAnimationFrame = useCallback(
    (e: MouseEvent) => {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
      animationFrameId.current = requestAnimationFrame(() => resize(e));
    },
    [resize]
  );

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      resizeWithAnimationFrame(e);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", disableResize);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", disableResize);
    };
  }, [resizeWithAnimationFrame, disableResize]);

  return { width, enableResize };
};

export default useResize;
