import * as React from "react";
import { ReactNode, useEffect, useRef, useState } from "react";

import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader, { CardHeaderProps } from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";

import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

//Title icons
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import CloseIcon from "@mui/icons-material/Close";

import { Divider } from "@mui/material";
import { EditTradeMode } from "../pages/Journal/DetailJournal/EditJournal/EditTradeTemplate";
import InfoIcon from "@mui/icons-material/Info";

import CustomTooltipIcon from "./CustomTooltipIcon";
import { fadeInDown } from "../utils/utils";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  // border: "1px solid", // 테두리 추가
  // borderColor: theme.palette.divider, // 테두리 색상 설정
}));

interface CustomCardHeaderProps extends CardHeaderProps {
  actionWidth?: string; // 추가된 매개변수 예시
}

const CustomCardHeader = styled(
  ({ actionWidth, ...props }: CustomCardHeaderProps) => (
    <CardHeader {...props} />
  )
)(({ theme, actionWidth }) => ({
  //backgroundColor: theme.palette.primary.main,
  //color: theme.palette.primary.contrastText,
  sx: { display: "flex", alignItems: "center" },
  padding: "20px 30px", // Example padding
  "& .MuiCardHeader-title": {
    fontWeight: "bold",
    color: theme.palette.secondary.main,
    marginBottom: "6px",
    sx: { display: "flex", alignItems: "center" },
  },
  "& .MuiCardHeader-subheader": {
    display: "flex",
    alignItems: "center",
    color: theme.palette.secondary.light,
  },
  "& .MuiCardHeader-action": {
    display: "flex",
    width: actionWidth || "auto",
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "flex-end",
    margin: "0",
    "& .MuiButton-root": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    "& .MuiBox-root.css-0": {
      display: "flex",
      width: "100%",
      alignItems: "center",
      alignSelf: "center",
      justifyContent: "flex-end",
    },
  },
}));

// 스타일드 컴포넌트로 책갈피 아이콘 정의
const Bookmark = styled("div")(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(0),
  right: theme.spacing(7),
  color: "white",
  padding: theme.spacing(1),
  borderRadius: "0px 0px 8px 8px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1, // 다른 컴포넌트 위에 오버레이되도록 설정
  animation: `${fadeInDown} 0.5s ease-in`,
}));

const TopIcon = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(0),
  right: theme.spacing(1),
  "&:hover": {
    color: "black",
    backgroundColor: `rgba(72,128,200, 0.1)`,
  },
}));

export interface ICustomCard {
  titleText?: string;
  titleTextColor?: string;
  titleTextWeight?: string;
  titlePanel?: ReactNode;
  isContents?: boolean;
  subTitleIcon?: ReactNode;
  subTitleText?: string;
  subTitleSize?: number;
  subTitlePanel?: ReactNode;
  subTitleTextColor?: string;
  isActionButton?: boolean;
  actionButtionText?: any;
  actionButtionIcon?: ReactNode;
  onClick?: Function;
  isActionPanel?: boolean;
  actionPanel?: ReactNode;
  actionWidth?: string;
  isBookmark?: boolean;
  bookmarkText?: string;
  bookmarkStyle?: React.CSSProperties;
  child?: ReactNode;
  isExpanded?: boolean;
  expandedChild?: ReactNode;
  isChildScrolled?: boolean;
  minChildHeight?: string | number;
  maxChildHeight?: string | number;
  minChildWidth?: string | number;
  maxChildWidth?: string | number;
  isInfoButton?: boolean; //titleText 우측에 Info 아이콘 추가(해당 카드에 대한 설명)
  infoText?: string | Array<string>; // Info 아이콘에 마우스 올리면 나타나는 팝업 메시지
  infoPlacement?:
    | "bottom"
    | "left"
    | "right"
    | "top"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "top-end"
    | "top-start"
    | undefined;
  borderRadius?: number;
  marginBottom?: number;
}

const CustomCard: React.FC<ICustomCard> = ({
  titleText,
  titleTextColor,
  titleTextWeight,
  titlePanel,
  isContents = true,
  subTitleIcon,
  subTitleText,
  subTitleSize,
  subTitleTextColor,
  subTitlePanel,
  isActionButton,
  actionButtionText,
  actionButtionIcon,
  isActionPanel,
  actionPanel,
  actionWidth,
  isBookmark,
  bookmarkText,
  bookmarkStyle,
  onClick,
  child,
  isExpanded,
  expandedChild,
  isChildScrolled,
  minChildHeight,
  maxChildHeight,
  minChildWidth,
  maxChildWidth,
  isInfoButton,
  infoText,
  infoPlacement,
  borderRadius,
  marginBottom,
}) => {
  const theme = useTheme();

  const [expanded, setExpanded] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [mode, setMode] = useState("READONLY");
  const scrollTimeoutRef = useRef<number | null>(null);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleScroll = () => {
    setIsScrolling(true);
    if (scrollTimeoutRef.current) {
      clearTimeout(scrollTimeoutRef.current);
    }
    scrollTimeoutRef.current = window.setTimeout(() => {
      requestAnimationFrame(() => {
        setIsScrolling(false);
      });
    }, 800);
  };

  useEffect(() => {
    const boxElement = document.getElementById("scrollBox");
    if (boxElement) {
      boxElement.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (boxElement) {
        boxElement.removeEventListener("scroll", handleScroll);
      }
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
    };
  }, []);

  if (maxChildHeight) isChildScrolled = true;

  const additionalProps: Record<string, any> = {
    mode: mode,
  };

  if (
    isBookmark &&
    React.isValidElement(child) &&
    typeof child.type !== "string"
  ) {
    // child가 유효한 React 컴포넌트일 때만 setMode를 추가
    additionalProps.setMode = (modeParam: EditTradeMode) => {
      setMode(modeParam);
    };
  }

  const enhancedChild = React.isValidElement(child)
    ? React.cloneElement(child, { ...additionalProps })
    : child;

  const CustomCardContentStyles = {
    pl: 3,
    pr: 3,
    mb: marginBottom || 0,
    ml: 1,
    mr: 1,
    pt: 0,
    ...(minChildHeight && { minHeight: minChildHeight }),
    ...(maxChildHeight && { maxHeight: maxChildHeight }),
    ...(minChildWidth && { minWidth: minChildWidth }),
    ...(maxChildWidth && { maxWidth: maxChildWidth }),
    ...(isChildScrolled && {
      overflowY: "auto",
      "::-webkit-scrollbar": {
        width: isScrolling ? "8px" : "0px",
        mr: "2px",
        transition: "width 0.5s ease",
      },
      "::-webkit-scrollbar-thumb": {
        backgroundColor: isScrolling ? "#888" : "transparent",
        borderRadius: "8px",
      },
      "::-webkit-scrollbar-track": {
        backgroundColor: "transparent",
      },
    }),
  };

  return (
    <Card
      sx={{
        maxWidth: "100%",
        boxShadow: `0px 2px 3px rgba(0, 0, 0, 0.1)`,
        borderRadius: borderRadius,
      }}
    >
      <Box position={isBookmark ? "relative" : "static"}>
        {isBookmark ? (
          <React.Fragment>
            <Bookmark style={bookmarkStyle ?? undefined}>
              <Typography style={{ fontSize: 14 }}>{bookmarkText}</Typography>
            </Bookmark>
            <TopIcon
              onClick={() => {
                mode !== "EDIT" ? setMode("EDIT") : setMode("READONLY");
              }}
            >
              {mode !== "EDIT" ? <EditNoteRoundedIcon /> : <CloseIcon />}
            </TopIcon>
          </React.Fragment>
        ) : (
          <></>
        )}
      </Box>
      <CustomCardHeader
        style={{
          paddingBottom: !titleText && !subTitleText ? "0px" : "20px",
        }}
        titleTypographyProps={{ fontSize: 16, minWidth: "240px" }}
        title={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{
                color: titleTextColor
                  ? titleTextColor
                  : theme.palette.secondary.main,

                fontWeight: titleTextWeight ?? "bold",
              }}
            >
              {titleText ?? null}
            </Typography>
            {isInfoButton &&
              infoText && ( // infoText가 있고 isInfoButton이 true일 때만 Tooltip을 표시
                <CustomTooltipIcon
                  children={
                    <IconButton
                      aria-label="info"
                      size="small"
                      sx={{ color: theme.palette.primary.light }}
                    >
                      <InfoIcon />
                    </IconButton>
                  }
                  title={infoText}
                  placement={infoPlacement || "right"}
                />
              )}
            {titlePanel && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flex: 1,
                }}
              >
                {titlePanel}
              </Box>
            )}
          </Box>
        }
        subheader={
          <div
            style={{
              display: "flex",
              color: subTitleTextColor
                ? subTitleTextColor
                : theme.palette.secondary.light,
            }}
          >
            {subTitleIcon ? (
              <CheckCircleRoundedIcon
                style={{
                  marginRight: 4,
                  color: "#68D391",
                  fontSize: "1.1rem",
                }}
              />
            ) : null}

            {(subTitlePanel && subTitlePanel) || (
              <Typography
                sx={{ fontSize: subTitleSize || 12, whiteSpace: "pre-wrap" }}
              >
                {" "}
                {subTitleText ?? null}
              </Typography>
            )}
          </div>
        }
        actionWidth={actionWidth ? actionWidth : "auto"}
        action={
          <Box>
            {isActionButton ? (
              <Button
                variant="contained"
                disableElevation
                startIcon={actionButtionIcon ?? actionButtionIcon}
                onClick={() => {
                  onClick ? onClick() : alert("Hello");
                }}
              >
                {actionButtionText ? actionButtionText : "버 튼"}
              </Button>
            ) : (
              <></>
            )}
            {isActionPanel ? (
              actionPanel && (
                <Box sx={{ display: "flex", flex: 1 }}>{actionPanel}</Box>
              )
            ) : (
              <></>
            )}
          </Box>
        }
        sx={{ display: "flex", alignItems: "center" }}
      />
      {isContents === true ? (
        <CardContent
          id="cardContents"
          sx={
            titlePanel
              ? {
                  ...CustomCardContentStyles,
                  "&:last-child": {
                    paddingBottom: 0,
                  },
                }
              : {
                  ...CustomCardContentStyles,
                  "&:last-child": {
                    paddingBottom: 2,
                  },
                }
          }
          onScroll={isChildScrolled ? handleScroll : undefined}
        >
          {enhancedChild ? enhancedChild : null}
        </CardContent>
      ) : null}
      {isExpanded ? (
        <Box sx={{ padding: 0, margin: 0 }}>
          <CardActions
            style={{
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 0,
              paddingBottom: 2,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Divider style={{ flex: 1 }} />
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
            <Divider style={{ flex: 1 }} />
          </CardActions>

          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent
              style={{ paddingTop: 0, paddingLeft: 30, paddingRight: 30 }}
            >
              {expandedChild}
            </CardContent>
          </Collapse>
        </Box>
      ) : (
        <></>
      )}
    </Card>
  );
};

export default CustomCard;
