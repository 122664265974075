import { createSelector } from "reselect";
import { RootState } from "../store";
import dayjs from "dayjs";

// tradeDataSelectors.ts
export const selectJournalData = (state: RootState) => state.journalData;

export const journalOverView = (state: RootState) => state.journalOverView;

export const journalPage = (state: RootState) => state.journalPage;

export const selectedJournalUpdatedAt = createSelector(
  [selectJournalData],
  (journal) => journal?.journalUpdatedAt || 0
);

export const selectedJournalData = createSelector(
  [journalOverView],
  (journal) => journal?.selectedJournalData || []
);

export const selectJournals = createSelector(
  [selectJournalData],
  (journalData) => journalData?.journals || []
);

export const selectHistoryData = createSelector(
  [selectJournalData],
  (journalData) => journalData?.historyData || []
);

export const selectProgressingTrades = createSelector(
  [selectJournalData],
  (journalData) => journalData?.progressingTrades || []
);

export const selectLoading = createSelector(
  [selectJournalData],
  (journalData) => journalData?.loading || false
);

export const selectAddLoading = createSelector(
  [selectJournalData],
  (journalData) => journalData?.addLoading || false
);

export const selectIsShowAnalysis = createSelector(
  [journalPage],
  (journalPage) => journalPage?.isShowAnalysis || false
);

export const selectSelctedTabIndex = createSelector(
  [journalPage],
  (journalPage) => journalPage?.selectedTabIndex || 0
);

export const selectUpdateDateAt = createSelector(
  [journalPage],
  (journalPage) => journalPage?.updateDateAt || dayjs()
);
