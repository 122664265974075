import { combineReducers } from "redux";

import utilReducer from "../../utils/redux-utils";
import { routerReducer } from "./redux-router";
import authReducer from "./auth";
import userReducer from "./user";
import tradeDataReducer from "./journal/data/tradeData";
import journalPageReducer from "./journal/page/journal";
import journalDataReducer from "./journal/data/journalData";
import journalDetailsReducer from "./journal/page/journalDetails";
import journalOverViewReducer from "./journal/page/journalOverview";
import breadcrumbsReducer from "./breadcrumbs";

const rootReducer = combineReducers({
  router: routerReducer,
  auth: authReducer,
  user: userReducer,
  journalPage: journalPageReducer,
  journalDetails: journalDetailsReducer,
  journalOverView: journalOverViewReducer,
  journalData: journalDataReducer,
  tradeData: tradeDataReducer,
  breadcrumbs: breadcrumbsReducer,
  util: utilReducer,
});

export default rootReducer;
