import {
  Typography,
  Button,
  IconButton,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  Checkbox,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  ListItemText,
  DialogActions,
  Grid,
} from "@mui/material";
import { ReactNode, useState } from "react";
import { useTheme, styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { paths } from "../../../paths";
import {
  GenButton,
  genTypoNode,
  getIconAndName,
  getIconsAndNames,
  menuButtonNode,
} from "../CommonTableNode";
import { BinanceIcon, BitgetIcon } from "../../../components/Icons/Exchanges";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import createExchangeIconTable from "../../../components/Icons/Exchanges/IconMapTable";
import { connect } from "http2";
import { IJournalTableData } from "../types";
import { useAppDispatch } from "../../../store/hooks";
import {
  setEditDialogOpen,
  setEditDrawerOpen,
  setSelectedJournalGroupId,
  setSelectedJournalRowIndex,
} from "../../../store/reducers/journal/page/journalDetails";
import { setSelectedJournal } from "../../../store/reducers/journal/page/journalOverview";
import CloseIcon from "@mui/icons-material/Close";

import RadioButtonUncheckedRoundedIcon from "@mui/icons-material/RadioButtonUncheckedRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CurrencyExchangeRoundedIcon from "@mui/icons-material/CurrencyExchangeRounded";

import React from "react";
import { ExchangeIconTable40 } from "../../../components/CommonNodes";
import { motion } from "framer-motion";
import { enqueueSnackbar } from "notistack";
import dayjs from "dayjs";
import CustomDateTimeRangePicker from "../../../components/CustomDateTimeRangePicker";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import {
  IconHeight,
  IconWidth,
  round,
  TIME_FORMAT,
} from "../../../utils/utils";
import { ConnectedExchange, UserState } from "../../../store/reducers/user";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { userData } from "../../../store/selector/userSelector";
import {
  selectedJournalData,
  selectJournals,
} from "../../../store/selector/journalDataSelector";
import { updateJournal } from "../../../store/reducers/journal/data/journalData";

interface IGenIsExchangedNode {
  journalData: IJournalTableData;
}

const GenIsExchangedNode: React.FC<IGenIsExchangedNode> = ({ journalData }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [journalExchangeDialogOpen, setJournalExchangeDialogOpen] =
    useState(false);

  const user: UserState = useSelector((state: RootState) => userData(state));
  const allJournals = useSelector((state: RootState) => selectJournals(state));

  const [checked, setChecked] = useState<number[]>([]);
  const [connectButtonDisabled, setConnectButtonDisabled] = useState(true);
  const [showCheckedOnly, setShowCheckedOnly] = useState(false);
  const [startTradeTime, setStartTradeTime] = useState(dayjs());
  const [endTradeTime, setEndTradeTime] = useState(dayjs());
  const [connectedExchanges, setConnectedExchanged] = useState<
    ConnectedExchange[]
  >([]);

  const isEdit: boolean = journalData.totalTradeCount > 0;

  // 7일전 정오로 제한
  const minStartDate = dayjs()
    .subtract(7, "day")
    .set("hour", 12)
    .set("minute", 0)
    .set("second", 0)
    .set("millisecond", 0);

  const handleJournalExchangeClickOpen = () => {
    if (user && user.uid) {
      setJournalExchangeDialogOpen(true);
      setConnectedExchanged(user.connectedExchanges);
    } else {
      enqueueSnackbar(
        "연동된 거래소 계정이 없습니다. 거래소 연동에서 연동을 먼저 진행해주세요.",
        {
          variant: "warning",
          autoHideDuration: 3000,
        }
      );
    }
  };

  const handleJournalExchangeClickClose = () => {
    setJournalExchangeDialogOpen(false);
    setShowCheckedOnly(false); // 다이얼로그가 닫힐 때 체크된 항목만 보기 초기화
    setConnectButtonDisabled(true);
    setChecked([]);
  };

  const isConnectableCheck = (checkList: number[]) => {
    if (checkList.length > 0) {
      setConnectButtonDisabled(false);
    } else {
      setConnectButtonDisabled(true);
    }
  };

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    isConnectableCheck(newChecked);
    setChecked(newChecked);
  };

  const handleNext = () => {
    setShowCheckedOnly(true);
  };

  const handlePrev = () => {
    setShowCheckedOnly(false);
  };

  const handleDoConnection = () => {
    let newExchangeData: { [key: string]: { [key: string]: string } } = {};
    checked.forEach(
      (index) =>
        (newExchangeData[connectedExchanges[index].id] = {
          exchangeName: connectedExchanges[index].exchangeName,
          exchangeId: connectedExchanges[index].exchangeId,
        })
    );

    // 기존 exchangeMap과 새 데이터를 병합
    const updatedExchangeMap: { [key: string]: { [key: string]: string } } = {
      ...(journalData.exchangeMap || {}), // 기존 exchangeMap 유지
      ...newExchangeData, // 새 exchangeMap 데이터 병합
    };

    dispatch(
      updateJournal({
        updateJournalData: {
          Jid: journalData.Jid,
          exchangeMap: updatedExchangeMap,
        },
        exchangeUpdateDate: startTradeTime.valueOf(),
      })

    )
      .then((result) => {
        if (result.meta.requestStatus === "fulfilled") {
          enqueueSnackbar("해당 일지에 거래소를 성공적으로 연동하였습니다.", {
            variant: "info",
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar(
            `일지와 거래소 연동 중 에러가 발생하였습니다 : ${result.payload}`,
            {
              variant: "error",
              autoHideDuration: 2000,
            }
          );
        }
      })
      .catch((error) => {
        enqueueSnackbar(
          `일지와 거래소 연동 중 에러가 발생하였습니다 : ${error.message}`,
          {
            variant: "error",
            autoHideDuration: 3000,
          }
        );
      });
    handleJournalExchangeClickClose();
  };

  const checkExchangeKeyInJournals = (
    journals: IJournalTableData[],
    keyToCheck: string
  ): string | null => {
    for (let journal of journals) {
      const exchangeMap = journal.exchangeMap || {};

      // exchangeMap 안에 keyToCheck가 존재하는지 확인
      if (keyToCheck in exchangeMap) {
        return journal.title; // 해당 키가 있으면 journal.title을 반환
      }
    }

    return null; // 키가 없으면 null 반환
  };

  return (
    <React.Fragment>
      <Button
        variant="text"
        sx={{
          fontSize: 14,
          color: theme.palette.primary.light,
          fontWeight: "500",
          textDecoration: "underline",
        }}
        onClick={handleJournalExchangeClickOpen}
        disabled={isEdit}
      >
        {isEdit ? "연동불가" : "연동하기"}
      </Button>
      <Dialog
        open={journalExchangeDialogOpen}
        onClose={handleJournalExchangeClickClose}
      >
        <DialogTitle
          sx={{
            fontSize: 16,
            fontWeight: "bold",
            paddingRight: "48px", // 오른쪽 패딩을 추가하여 공간을 확보
          }}
        >
          🔗 거래소 연동
          <IconButton
            aria-label="close"
            onClick={handleJournalExchangeClickClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.secondary.main,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ width: "40vh" }}>
            <List
              dense
              sx={{
                width: "100%",
              }}
            >
              {connectedExchanges.map((connectedExchange, index) => {
                const labelId = `checkbox-list-secondary-label-${connectedExchange.exchangeId}_${index}`;
                const journalTitle = checkExchangeKeyInJournals(
                  allJournals,
                  connectedExchange.id
                );
                const secondaryText =
                  journalTitle !== null
                    ? `이미 "${journalTitle}" 에서 사용 중 입니다.`
                    : "이 계정으로 연동이 가능합니다.";
                const secondaryTextColor =
                  journalTitle !== null
                    ? theme.palette.error.main
                    : theme.palette.success.light;

                if (!showCheckedOnly) {
                  return (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, y: -30 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 30 }}
                      transition={{ duration: 0.5 }}
                    >
                      <ListItem
                        key={`${connectedExchange.exchangeId}_${index}`}
                        secondaryAction={
                          <Checkbox
                            size="small"
                            edge="end"
                            onChange={handleToggle(index)}
                            checked={checked.indexOf(index) !== -1}
                            icon={<RadioButtonUncheckedRoundedIcon />}
                            checkedIcon={<CheckCircleRoundedIcon />}
                            inputProps={{ "aria-labelledby": labelId }}
                            disabled={showCheckedOnly || journalTitle !== null} // 다음 버튼 이후 체크박스 비활성화
                          />
                        }
                        sx={{
                          backgroundColor: "white",
                          borderRadius: 6,
                          marginTop: 2,
                          boxShadow: `0px 2px 3px rgba(0, 0, 0, 0.1)`,
                          width: "100%",
                        }}
                        disablePadding
                      >
                        <ListItemButton
                          onClick={handleToggle(index)}
                          sx={{
                            borderRadius: 6,
                            "&:hover": {
                              backgroundColor: `rgba(72,128,200, 0.1)`,
                            },
                          }}
                          disabled={journalTitle !== null}
                        >
                          <ListItemAvatar
                            sx={{ marginTop: 1, marginBottom: 1 }}
                          >
                            <Avatar alt={`A`}>
                              {
                                ExchangeIconTable40[
                                  connectedExchange.exchangeName
                                ]
                              }
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            id={labelId}
                            primaryTypographyProps={{
                              fontSize: 14,
                              fontWeight: "600",
                            }}
                            secondaryTypographyProps={{
                              color: secondaryTextColor,
                            }}
                            primary={`${connectedExchange.exchangeId}`}
                            secondary={secondaryText}
                          />
                        </ListItemButton>
                      </ListItem>
                    </motion.div>
                  );
                } else {
                  if (checked.includes(index)) {
                    return (
                      <motion.div
                        key={index}
                        initial={{ opacity: 0, y: -30 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 30 }}
                        transition={{ duration: 0.5 }}
                      >
                        <ListItem
                          key={`${connectedExchange.exchangeId}_${index}`}
                          secondaryAction={
                            <Checkbox
                              size="small"
                              edge="end"
                              onChange={handleToggle(index)}
                              checked={checked.indexOf(index) !== -1}
                              icon={<RadioButtonUncheckedRoundedIcon />}
                              checkedIcon={<CheckCircleRoundedIcon />}
                              inputProps={{ "aria-labelledby": labelId }}
                              disabled={
                                showCheckedOnly || journalTitle !== null
                              } // 다음 버튼 이후 체크박스 비활성화
                            />
                          }
                          sx={{
                            backgroundColor: "white",
                            borderRadius: 6,
                            marginTop: 2,
                            boxShadow: `0px 2px 3px rgba(0, 0, 0, 0.1)`,
                            width: "100%",
                          }}
                          disablePadding
                        >
                          <ListItemButton
                            onClick={() => {}}
                            sx={{
                              borderRadius: 6,
                              "&:hover": {
                                backgroundColor: `rgba(72,128,200, 0.1)`,
                              },
                            }}
                            disabled={journalTitle !== null}
                          >
                            <ListItemAvatar
                              sx={{ marginTop: 1, marginBottom: 1 }}
                            >
                              <Avatar alt={`A`}>
                                {
                                  ExchangeIconTable40[
                                    connectedExchange.exchangeName
                                  ]
                                }
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              id={labelId}
                              primaryTypographyProps={{
                                fontSize: 14,
                                fontWeight: "600",
                              }}
                              secondaryTypographyProps={{
                                color: secondaryTextColor,
                              }}
                              primary={`${connectedExchange.exchangeId}`}
                              secondary={secondaryText}
                            />
                          </ListItemButton>
                        </ListItem>
                      </motion.div>
                    );
                  } else {
                    return null;
                  }
                }
              })}
            </List>
            {showCheckedOnly && (
              <Grid container spacing={1} sx={{ marginTop: 2, padding: 1 }}>
                <Grid container item spacing={1}>
                  <React.Fragment>
                    <Grid item xs={2} md={5}>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start", // 수평 중앙 정렬
                          alignItems: "center", // 수직 중앙 정렬
                          height: "100%", // Grid 아이템의 전체 높이 사용
                        }}
                      >
                        <AccessTimeOutlinedIcon
                          style={{
                            color: theme.palette.secondary.light,
                            fontSize: 18,
                            marginRight: 4,
                          }}
                        />
                        <Typography
                          style={{
                            color: theme.palette.secondary.light,
                            fontSize: 14,
                            fontWeight: "500",
                          }}
                        >
                          연동 시작 날짜
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4} md={6.8}>
                      <CustomDateTimeRangePicker
                        mode={"EDIT"}
                        theme={theme}
                        minDate={minStartDate}
                        maxDate={dayjs()}
                        tradeTime={startTradeTime}
                        setTradeTime={setStartTradeTime}
                        type="startTradeTime"
                      />
                    </Grid>
                  </React.Fragment>
                  {/* <React.Fragment>
                    <Grid item xs={2} md={5}>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start", // 수평 중앙 정렬
                          alignItems: "center", // 수직 중앙 정렬
                          height: "100%", // Grid 아이템의 전체 높이 사용
                        }}
                      >
                        <AccessTimeOutlinedIcon
                          style={{
                            color: theme.palette.secondary.light,
                            fontSize: 18,
                            marginRight: 4,
                          }}
                        />
                        <Typography
                          style={{
                            color: theme.palette.secondary.light,
                            fontSize: 14,
                            fontWeight: "500",
                          }}
                        >
                          연동 종료 날짜
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4} md={6.8}>
                      <CustomDateTimeRangePicker
                        mode={"EDIT"}
                        theme={theme}
                        maxDate={dayjs()}
                        tradeTime={endTradeTime}
                        setTradeTime={setEndTradeTime}
                        type="endTradeTime"
                      />
                    </Grid>
                  </React.Fragment> */}
                </Grid>
              </Grid>
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ marginBottom: 1, marginRight: 1 }}>
          {!showCheckedOnly && (
            <Button
              disabled={connectButtonDisabled}
              variant="contained"
              onClick={handleNext}
              sx={{
                color: "white",
                backgroundColor: theme.palette.primary.main,
              }}
            >
              다음
            </Button>
          )}
          {showCheckedOnly && (
            <Button
              disabled={false}
              variant="contained"
              onClick={handleDoConnection}
              sx={{
                color: "white",
                backgroundColor: theme.palette.primary.main,
              }}
            >
              연동하기
            </Button>
          )}
          {showCheckedOnly && (
            <Button
              disabled={false}
              variant="contained"
              onClick={handlePrev}
              sx={{
                color: "white",
                backgroundColor: theme.palette.primary.main,
              }}
            >
              뒤로
            </Button>
          )}
          <Button
            variant="contained"
            onClick={handleJournalExchangeClickClose}
            sx={{
              color: "white",
              backgroundColor: theme.palette.primary.main,
            }}
          >
            취소
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

interface GenEntryJournalButtonProps {
  text: string;
  url: string;
  param: {
    state: {
      selectedJournalParam: IJournalTableData;
    };
  };
}

const GenEntryJournalButton: React.FC<GenEntryJournalButtonProps> = ({
  text,
  url,
  param,
}) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const navigate = useNavigate();

  const handleClick = () => {
    dispatch(setEditDrawerOpen(false));
    dispatch(setSelectedJournalGroupId(""));
    dispatch(setSelectedJournalRowIndex(-1));
    param.state.selectedJournalParam &&
      dispatch(setSelectedJournal(param.state.selectedJournalParam));
    navigate(url, param);
  };

  return (
    <Button
      disabled={
        param.state.selectedJournalParam &&
        param.state.selectedJournalParam.exchangeMap &&
        Object.keys(param.state.selectedJournalParam.exchangeMap).length === 0
      }
      variant="outlined"
      onClick={handleClick}
      sx={{
        borderWidth: "1.4px", // 보더 두께
        borderColor: theme.palette.primary.light, // 보더 색상
        borderRadius: 12,
        "&:hover": {
          borderWidth: "1.4px", // 보더 두께
          borderColor: theme.palette.primary.dark, // 호버 시 보더 색상 변경
          "& .MuiTypography-root": {
            color: `${theme.palette.primary.dark} !important`,
          },
        },
      }}
    >
      <Typography
        sx={{
          color:
            param.state.selectedJournalParam &&
            param.state.selectedJournalParam.exchangeMap &&
            Object.keys(param.state.selectedJournalParam.exchangeMap).length ===
              0
              ? undefined
              : `${theme.palette.primary.main} !important`,
          fontWeight: "bold",
          fontSize: 12,
        }}
      >
        {param.state.selectedJournalParam &&
        param.state.selectedJournalParam.exchangeMap &&
        Object.keys(param.state.selectedJournalParam.exchangeMap).length === 0
          ? "연동 필요"
          : text}
      </Typography>
    </Button>
  );
};

// 거래소 두 개 아이콘 겹치기
function getIsExchangeIcons(): ReactNode {
  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        // 예시에서는 임의로 높이를 지정합니다.
        position: "relative",
      }}
    >
      {/* BitgetIcon과 BinanceIcon에 직접적으로 스타일을 전달합니다. */}
      <BitgetIcon
        width={IconWidth}
        height={IconHeight}
        style={{
          position: "absolute",
          right: "44%",
          zIndex: 1, // BitgetIcon을 BinanceIcon 위에 표시합니다.
        }}
      />
      <BinanceIcon
        width={IconWidth}
        height={IconHeight}
        style={{
          position: "absolute", // BinanceIcon의 위치를 조정하여 겹치는 부분을 조정합니다.
          left: "44%",
          zIndex: 2, // BinanceIcon을 BitgetIcon 위에 표시합니다.
        }}
      />
    </Box>
  );
}

export function createJournalTableData({
  props,
  menuCallback,
}: {
  props: IJournalTableData;
  menuCallback?: () => void;
}) {
  const LongTextColor = "#1BB56C";
  const ShortTextColor = "#F56565";

  const nameNode = genTypoNode({ data: props.title, fontWeight: "700" });
  const tradeCountNode = genTypoNode({ data: props.totalTradeCount });
  const winRateNode = genTypoNode({
    data: round(props.winRate * 100, 2),
    color: props.winRate > 0 ? LongTextColor : ShortTextColor,
    leadingText: "%",
  });

  const ExchangeIconTable = createExchangeIconTable(IconWidth, IconHeight);

  const profitRateNode = genTypoNode({
    data: round(props.totalProfit, 2),
    color: props.totalProfit > 0 ? LongTextColor : ShortTextColor,
    leadingText: "\nUSDT",
  });

  const isExchangedNode =
    props.exchangeMap &&
    Object.keys(props.exchangeMap).length !== 0 &&
    props.exchangeMap !== null ? (
      getIconsAndNames(Object.values(props.exchangeMap), ExchangeIconTable)
    ) : (
      <GenIsExchangedNode journalData={props} />
    );
  const createAtNode = genTypoNode({
    data: dayjs(props.createdAt).format(TIME_FORMAT),
  });

  const modifiedName = props.title.replace(/ /g, "_"); // 공백을 '_'로 대체

  const genButtonNodeNode = (
    <GenEntryJournalButton
      text="들어가기"
      url={`${paths.dashboard.journal.detail}/${modifiedName}`}
      param={{
        state: { selectedJournalParam: props },
      }}
    />
  );

  // 메뉴 항목 데이터
  const menuItems = [
    {
      content: "🗑️ 삭제",
      onClick: () => {
        menuCallback && menuCallback();
      },
    },
  ];

  // 다양한 버튼 내용 데이터
  const buttonContent = (
    <IconButton>
      <MoreVertIcon />
    </IconButton>
  );

  const menuNode = menuButtonNode({ buttonContent, menuItems });

  return [
    nameNode,
    tradeCountNode,
    winRateNode,
    profitRateNode,
    isExchangedNode,
    createAtNode,
    genButtonNodeNode,
    menuNode,
  ];
}

export const createdJournalHeaderList: Array<string> = [
  "일지 이름",
  "거래 횟수",
  "전체 승률",
  "수익",
  "거래소 연동",
  "생성된 날짜",
  "", //button을 위한 빈공간
  "", //icon을 위한 빈공간
];
