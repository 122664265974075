import { Typography, Button, IconButton, Box } from "@mui/material";
import { ReactNode } from "react";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import {
  GenDetailsNode,
  genTypoNode,
  getIconAndName,
  menuButtonNode,
  PositionLeverageText,
  Tags,
} from "../../CommonTableNode";
import { ExchangeIconTable } from "../../../../components/Icons/Exchanges";
import createExchangeIconTable from "../../../../components/Icons/Exchanges/IconMapTable";
import dayjs, { Dayjs } from "dayjs";
import { IconHeight, IconWidth, TIME_FORMAT } from "../../../../utils/utils";

import { IOpenTradeTableData } from "../../types";
import createCoinIconTable from "../../../../components/Icons/CoinSymbols/IconMapTable";

export function createTableData(props: IOpenTradeTableData) {
  const LongColor = "#68D391";
  const ShortColor = "#F56565";

  const posColor =
    props.position === "LONG" || props.position === "BUY"
      ? LongColor
      : ShortColor;

  const buyCount = props.buyCount ?? 1;
  const sellCount = props.sellCount ?? 0;

  const buySellCountText = `${buyCount} / ${sellCount}`;

  const ExchangeIconTable = createExchangeIconTable(IconWidth, IconHeight);

  const numNodeNode = genTypoNode({
    data: props.num || "",
    suffixText: "#",
    fontWeight: "600",
  });

  const tradeTimeNode = genTypoNode({
    data:
      (props.tradeTime &&
        dayjs(props.tradeTime).isValid() &&
        dayjs(props.tradeTime).format(TIME_FORMAT)) ||
      dayjs().format(TIME_FORMAT),
  });

  const exchangeNode = getIconAndName(
    props.exchange || "바이낸스",
    ExchangeIconTable[props.exchange || "바이낸스"]
  );

  const marketTypeNodeNode = genTypoNode({ data: props.marketType || "" });

  const positionLeverageNode = (
    <PositionLeverageText
      position={{ text: props.position || "", backgroundColor: posColor }}
      leverage={{ text: `X${props.leverage}`, backgroundColor: posColor }}
    />
  );

  const CoinIconTable = createCoinIconTable(IconWidth, IconHeight);

  const symbolNode = getIconAndName(
    props.symbol || "BTCUSDT",
    CoinIconTable[props.symbol || "BTCUSDT"]
  );
  const entryAmountNodeNode = genTypoNode({
    data: props.entryAmount || 0,
    leadingText: "\nUSDT",
    isPrice: true,
  });

  const entryPriceNode = genTypoNode({
    data: props.avgEntryPrice || 0,
    leadingText: "\nUSDT",
    isPrice: true,
  });

  const stopLossPriceNode = genTypoNode({
    data: props.stopLossPrice || 0,
    leadingText: "\nUSDT",
    isPrice: true,
  });

  const takeProfitPriceNode = genTypoNode({
    data: props.takeProfitPrice || 0,
    leadingText: "\nUSDT",
    isPrice: true,
  });

  // const liquidPriceNode = genTypoNode({
  //   data: props.liquidPrice,
  //   leadingText: "$",
  //   isPrice: true,
  // });

  const detailsNode = (
    <GenDetailsNode text={buySellCountText} open={false} setOpen={() => {}} />
  );

  const profitLossRatioNode = genTypoNode({
    data: props.profitLossRatio || 0,
  });

  const tagsNode = props.allTags ? <Tags tags={props.allTags} /> : "";

  // 메뉴 항목 데이터
  const menuItems = [
    {
      content: "✏️ 수정",
      onClick: () => alert("수정"),
    },
    {
      content: "🗑️ 삭제",
      onClick: () => alert("삭제"),
    },
  ];

  // 다양한 버튼 내용 데이터
  const buttonContent = (
    <IconButton>
      <MoreVertIcon />
    </IconButton>
  );

  const menuNode = menuButtonNode({ buttonContent, menuItems });

  return [
    //menuNode, 버튼
    numNodeNode,
    tradeTimeNode,
    exchangeNode,
    symbolNode,
    //marketTypeNodeNode, 시장 정보
    positionLeverageNode,
    entryAmountNodeNode,
    entryPriceNode,
    stopLossPriceNode,
    takeProfitPriceNode,
    //liquidPriceNode,
    detailsNode,
    profitLossRatioNode,
    tagsNode,
  ];
}

export const openTradeJournalHeaderList: Array<string> = [
  //"", //icon을 위한 빈공간
  "거래 번호",
  "진입 시간",
  "거래소",
  "종목",
  //"시장",
  "포지션",
  "진입 비중",
  "진입가\n(평단가)",
  "손절가",
  "익절가",
  //"청산가",
  "상세 과정\n(매수 / 매도)",
  "손익비",
  "태그",
];
