import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "../../../store";
import {
  validateTradeTimes,
  assignNumAndClassify,
} from "../../../api/tradeDataProcessing";
import {
  TagOptionType,
  TradeDataRecord,
  IAllTags,
  IClosedTradeTableData,
  IOpenTradeTableData,
  IJournalTableData,
} from "../../../../pages/Journal/types";
import {
  collection,
  deleteDoc,
  doc,
  DocumentData,
  getDocs,
  QueryDocumentSnapshot,
  setDoc,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import { db } from "../../../../firebase";
import {
  convertToFirestoreData,
  removeUndefinedFields,
} from "../../../../utils/utils";

interface TradeDataState {
  [journalId: string]: {
    tradeDatas: TradeDataRecord[];
    openTrades: IOpenTradeTableData[];
    closedTrades: IClosedTradeTableData[];
    allTags: IAllTags[];
    loading: boolean;
    error: string | null;
  };
}

const updateTradeDataState = (
  state: TradeDataState,
  journalId: string,
  tradeDatas: TradeDataRecord[],
  shouldUpdateJournal: boolean = false // journal 업데이트 여부를 판단하는 boolean 매개변수 추가
) => {
  // openTrades, closedTrades, allTags를 계산
  const { openTrades, closedTrades, allTags } =
    assignNumAndClassify(tradeDatas);

  // state에 업데이트
  state[journalId].openTrades = openTrades;
  state[journalId].closedTrades = closedTrades;
  state[journalId].allTags = allTags;

  // shouldUpdateJournal이 true이면 Firestore에 저널 통계 업데이트
  if (shouldUpdateJournal) {
    // openTrades와 closedTrades에서 통계 계산
    const totalTradeCount = openTrades.length + closedTrades.length;
    const totalProfit = closedTrades.reduce(
      (acc, trade) => acc + (trade.allProfit || 0),
      0
    );
    const winTradeCount = closedTrades.filter(
      (trade) => trade.allProfit && trade.allProfit > 0
    ).length;
    const loseTradeCount = closedTrades.filter(
      (trade) => trade.allProfit && trade.allProfit < 0
    ).length;
    const winRate = totalTradeCount
      ? (winTradeCount / totalTradeCount) * 100
      : 0;

    // 업데이트할 데이터 생성
    const updatedJournalData: Partial<IJournalTableData> = {
      totalTradeCount,
      totalProfit,
      winRate,
      loseTradeCount,
      winTradeCount,
      updatedAt: Date.now(), // 업데이트 시간 기록
    };

    // Firestore의 journals 컬렉션에 업데이트
    const journalDocRef = doc(db, `journals/${journalId}`);
    updateDoc(journalDocRef, updatedJournalData)
      .then(() => {
        console.log("Journal Statistic Update");
      })
      .catch((error) => {
        console.error("Journal Statistic Update Error:", error);
      });
  }
};

const initialState: TradeDataState = {};

// createAsyncThunk
export const addTrade = createAsyncThunk(
  "tradeData/addTrade",
  async (
    {
      journalId,
      trade,
      journalType,
      shouldUpdateJournal = false,
    }: {
      journalId: string;
      trade: TradeDataRecord;
      journalType: string;
      shouldUpdateJournal?: boolean;
    },
    { getState, rejectWithValue }
  ) => {
    const state = getState() as RootState;
    const allTradeData: TradeDataRecord[] =
      state.tradeData[journalId]?.tradeDatas || [];

    if (!trade.id) {
      return rejectWithValue("Trade ID is missing");
    }

    // 거래 시간 검증
    const tradesToValidate = allTradeData.filter(
      (tradeTemp) => tradeTemp.groupId === trade.groupId
    ) as TradeDataRecord[];

    tradesToValidate.push(trade);

    // 거래 검증 실패 시
    if (!validateTradeTimes(tradesToValidate)) {
      return rejectWithValue("Invalid trade times");
    }

    try {
      // Firestore 경로 설정
      const tradeCollection =
        journalType === "OPEN" ? "openTrade" : "closedTrade";
      const tradeDocRef = doc(
        db,
        `journals/${journalId}/${tradeCollection}`,
        trade.id
      );

      // 거래 추가
      await setDoc(
        tradeDocRef,
        convertToFirestoreData(removeUndefinedFields(trade))
      );

      // 추가된 거래가 PositionEnd이고 journalType이 OPEN인 경우
      if (journalType === "OPEN" && trade.tradeType === "PositionEnd") {
        const openTradesCollectionRef = collection(
          db,
          `journals/${journalId}/openTrade`
        );
        const closedTradesCollectionRef = collection(
          db,
          `journals/${journalId}/closedTrade`
        );
        const querySnapshot = await getDocs(openTradesCollectionRef);

        // batch를 사용하여 다수 문서를 이동
        const batch = writeBatch(db);
        querySnapshot.forEach(
          (docSnap: QueryDocumentSnapshot<DocumentData>) => {
            const data = docSnap.data() as TradeDataRecord;
            // groupId가 동일한 모든 거래를 closedTrades로 이동
            if (data.groupId === trade.groupId) {
              const newDocRef = doc(closedTradesCollectionRef, docSnap.id);
              batch.set(
                newDocRef,
                convertToFirestoreData(removeUndefinedFields(data))
              );
              batch.delete(docSnap.ref);
            }
          }
        );

        await batch.commit();
      }

      return { journalId, trade, shouldUpdateJournal };
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// 거래 업데이트 Thunk 함수
export const updateTrade = createAsyncThunk(
  "tradeData/updateTrade",
  async (
    {
      journalId,
      updatedData,
      journalType,
      shouldUpdateJournal = false,
    }: {
      journalId: string;
      updatedData: TradeDataRecord;
      journalType: string;
      shouldUpdateJournal?: boolean;
    },
    { getState, rejectWithValue }
  ) => {
    const state = getState() as RootState;
    const allTradeData: TradeDataRecord[] =
      state.tradeData[journalId]?.tradeDatas || [];

    // 거래 데이터 유효성 검사
    const tradesToValidate = allTradeData.filter(
      (trade) => trade.groupId === updatedData.groupId
    ) as TradeDataRecord[];

    const index = tradesToValidate.findIndex((t) => t.id === updatedData.id);

    if (index !== -1) {
      tradesToValidate[index] = updatedData;
    } else {
      return rejectWithValue("Trade data not found");
    }

    // 거래 시간 검증
    if (!validateTradeTimes(tradesToValidate)) {
      return rejectWithValue("Invalid trade times");
    }

    // 검증 성공 시 리덕스 상태 업데이트
    // Firestore에 데이터 업데이트 로직 추가
    try {
      // Firestore의 경로 설정
      const subCollection =
        journalType === "OPEN" ? "openTrade" : "closedTrade";
      const tradeDocRef = doc(
        db,
        `journals/${journalId}/${subCollection}`,
        updatedData.id
      );

      // Firestore의 해당 문서 업데이트
      await updateDoc(
        tradeDocRef,
        removeUndefinedFields(convertToFirestoreData(updatedData))
      );

      return { journalId, updatedData, shouldUpdateJournal };
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// 거래 기록 삭제 Thunk 함수
export const deleteTrade = createAsyncThunk(
  "tradeData/deleteTrade",
  async (
    {
      journalId,
      id,
      groupId,
      tradeType,
      journalType,
      shouldUpdateJournal = false,
    }: {
      journalId: string;
      id: string;
      groupId: string;
      tradeType: string;
      journalType: string;
      shouldUpdateJournal?: boolean;
    },
    { getState, rejectWithValue }
  ) => {
    const state = getState() as RootState;

    try {
      // Firestore에서 삭제할 경로 설정
      const subCollection =
        journalType === "OPEN" ? "openTrade" : "closedTrade";
      const docRef = doc(db, `journals/${journalId}/${subCollection}`, id);

      // 거래 기록 삭제 시도
      await deleteDoc(docRef);

      // tradeType이 "PositionStart"인 경우, groupId와 동일한 문서 모두 제거
      if (tradeType === "PositionStart") {
        const tradeSnapshot = await getDocs(
          collection(db, `journals/${journalId}/${subCollection}`)
        );
        const groupTrades = tradeSnapshot.docs.filter(
          (trade) => trade.data().groupId === groupId
        );

        // 해당 그룹 ID와 동일한 모든 문서 삭제
        await Promise.all(groupTrades.map((trade) => deleteDoc(trade.ref)));
      }

      // tradeType이 "PositionEnd"인 경우, 문서 삭제 후 그룹의 나머지 문서 이동 처리
      if (journalType === "CLOSED" && tradeType === "PositionEnd") {
        const tradeSnapshot = await getDocs(
          collection(db, `journals/${journalId}/closedTrade`)
        );
        const groupTrades = tradeSnapshot.docs.filter(
          (trade) => trade.data().groupId === groupId
        );

        // 해당 그룹의 나머지 문서들을 openTrades로 이동
        await Promise.all(
          groupTrades.map(async (trade) => {
            const tradeData = trade.data();
            const openTradeRef = doc(
              db,
              `journals/${journalId}/openTrade`,
              trade.id
            );
            await setDoc(openTradeRef, tradeData);
            await deleteDoc(trade.ref);
          })
        );
      }

      // Firestore 삭제 작업 성공 시 기존 상태에서 거래 기록 제거
      let newTradeDatas = state.tradeData[journalId]?.tradeDatas || [];
      if (tradeType === "PositionStart") {
        newTradeDatas = newTradeDatas.filter(
          (trade: TradeDataRecord) => trade.groupId !== groupId
        );
      } else {
        newTradeDatas = newTradeDatas.filter(
          (trade: TradeDataRecord) => trade.id !== id
        );
      }

      if (
        newTradeDatas.length === state.tradeData[journalId]?.tradeDatas.length
      ) {
        return rejectWithValue("Trade data not found or no changes made");
      }

      return { journalId, newTradeDatas, shouldUpdateJournal };
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// 태그 제거 후 데이터베이스에 업데이트하는 Thunk 함수
export const removeTagFromTrades = createAsyncThunk(
  "tradeData/removeTagFromTrades",
  async (
    { journalId, tagNames }: { journalId: string; tagNames: string[] },
    { getState, rejectWithValue }
  ) => {
    const state = getState() as RootState;

    if (!state.tradeData[journalId]) {
      return rejectWithValue("Journal not found");
    }

    // 변경된 거래만 필터링
    const updatedTrades = state.tradeData[journalId].tradeDatas
      .filter((trade: TradeDataRecord) => {
        const originalTags = trade.tags || [];
        const filteredTags = originalTags.filter(
          (tag: TagOptionType) => !tagNames.includes(tag.name)
        );

        // 태그가 실제로 변경되었는지 확인
        return originalTags.length !== filteredTags.length;
      })
      .map((trade: TradeDataRecord) => ({
        ...trade,
        tags: trade.tags?.filter(
          (tag: TagOptionType) => !tagNames.includes(tag.name)
        ),
      }));

    // Firestore에 반영 (변경된 거래만 업데이트)
    if (updatedTrades.length === 0) {
      return rejectWithValue("No trades were modified");
    }

    try {
      const batch = writeBatch(db);

      // Firestore에 업데이트할 때 openTrades와 closedTrades를 기준으로 subCollection 설정
      updatedTrades.forEach((trade) => {
        // openTrades에 있는지 closedTrades에 있는지 확인
        const isInOpenTrades = state.tradeData[journalId].openTrades.some(
          (openTrade) => openTrade.id === trade.id
        );

        const subCollection = isInOpenTrades ? "openTrade" : "closedTrade";

        const tradeDocRef = doc(
          db,
          `journals/${journalId}/${subCollection}`,
          trade.id
        );

        // Firestore에 업데이트 (변경된 거래만)
        batch.update(tradeDocRef, {
          tags: trade.tags || [],
        });
      });

      await batch.commit(); // Firestore에 일괄 업데이트

      // 상태 업데이트 및 TradeDataState 업데이트 호출
      const newTradeDatas = state.tradeData[journalId].tradeDatas.map(
        (trade) => {
          const updatedTrade = updatedTrades.find((t) => t.id === trade.id);
          return updatedTrade ? updatedTrade : trade;
        }
      );

      return { journalId, newTradeDatas }; // newTradeDatas를 return
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// 태그 교체 후 데이터베이스에 업데이트하는 Thunk 함수
export const replaceTagInTrades = createAsyncThunk(
  "tradeData/replaceTagInTrades",
  async (
    {
      journalId,
      oldTagName,
      newTagName,
    }: { journalId: string; oldTagName: string; newTagName: string },
    { getState, rejectWithValue }
  ) => {
    const state = getState() as RootState;

    if (!state.tradeData[journalId]) {
      return rejectWithValue("Journal not found");
    }

    // 변경된 거래만 필터링
    const updatedTrades = state.tradeData[journalId].tradeDatas
      .filter((trade: TradeDataRecord) => {
        const originalTags = trade.tags || [];
        const hasOldTag = originalTags.some(
          (tag: TagOptionType) => tag.name === oldTagName
        );

        // 태그가 실제로 변경되었는지 확인 (oldTag가 있는지 확인)
        return hasOldTag;
      })
      .map((trade: TradeDataRecord) => ({
        ...trade,
        tags: trade.tags?.map((tag: TagOptionType) =>
          tag.name === oldTagName ? { ...tag, name: newTagName } : tag
        ),
      }));

    // Firestore에 반영 (변경된 거래만 업데이트)
    if (updatedTrades.length === 0) {
      return rejectWithValue("No trades were modified");
    }

    try {
      const batch = writeBatch(db);

      // Firestore에 업데이트할 때 openTrades와 closedTrades를 기준으로 subCollection 설정
      updatedTrades.forEach((trade) => {
        // openTrades에 있는지 closedTrades에 있는지 확인
        const isInOpenTrades = state.tradeData[journalId].openTrades.some(
          (openTrade) => openTrade.id === trade.id
        );

        const subCollection = isInOpenTrades ? "openTrade" : "closedTrade";

        const tradeDocRef = doc(
          db,
          `journals/${journalId}/${subCollection}`,
          trade.id
        );

        // Firestore에 업데이트 (변경된 거래만)
        batch.update(tradeDocRef, {
          tags: trade.tags || [],
        });
      });

      await batch.commit(); // Firestore에 일괄 업데이트

      // 상태 업데이트 및 TradeDataState 업데이트 호출
      const newTradeDatas = state.tradeData[journalId].tradeDatas.map(
        (trade) => {
          const updatedTrade = updatedTrades.find((t) => t.id === trade.id);
          return updatedTrade ? updatedTrade : trade;
        }
      );

      return { journalId, newTradeDatas }; // newTradeDatas를 return
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// `fetchTradeData` Thunk 정의
export const fetchTradeData = createAsyncThunk<
  { journalId: string; tradeDatas: TradeDataRecord[] },
  string, // `journalId`는 문자열로 처리
  { rejectValue: string }
>("tradeData/fetchTradeData", async (journalId, { rejectWithValue }) => {
  try {
    // Fetch openTrades from the subcollection
    const openTradesSnapshot = await getDocs(
      collection(db, `journals/${journalId}/openTrade`)
    );
    const openTrades = openTradesSnapshot.docs.map((doc) => ({
      ...doc.data(),
      marketType: "USDT-FUTURES",
    })) as TradeDataRecord[];

    // Fetch closedTrades from the subcollection
    const closedTradesSnapshot = await getDocs(
      collection(db, `journals/${journalId}/closedTrade`)
    );
    const closedTrades = closedTradesSnapshot.docs.map((doc) => ({
      ...doc.data(),
      marketType: "USDT-FUTURES",
    })) as TradeDataRecord[];

    // Merge openTrades and closedTrades
    const tradeDatas = [...openTrades, ...closedTrades];

    // Return the merged trade data along with the journalId
    return { journalId, tradeDatas };
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

// Slice
const tradeDataSlice = createSlice({
  name: "tradeData",
  initialState,
  reducers: {
    setTradeDatas(
      state,
      action: PayloadAction<{ journalId: string; data: TradeDataRecord[] }>
    ) {
      const { journalId, data } = action.payload;
      if (!state[journalId]) {
        state[journalId] = {
          tradeDatas: [],
          openTrades: [],
          closedTrades: [],
          allTags: [],
          loading: false,
          error: null,
        };
      }
      state[journalId].tradeDatas = data;
      updateTradeDataState(state, journalId, data);
    },
    setOpenTrades(
      state,
      action: PayloadAction<{ journalId: string; data: IOpenTradeTableData[] }>
    ) {
      const { journalId, data } = action.payload;
      if (!state[journalId]) {
        state[journalId] = {
          tradeDatas: [],
          openTrades: [],
          closedTrades: [],
          allTags: [],
          loading: false,
          error: null,
        };
      }
      state[journalId].openTrades = data;
    },
    setClosedTrades(
      state,
      action: PayloadAction<{
        journalId: string;
        data: IClosedTradeTableData[];
      }>
    ) {
      const { journalId, data } = action.payload;
      if (!state[journalId]) {
        state[journalId] = {
          tradeDatas: [],
          openTrades: [],
          closedTrades: [],
          allTags: [],
          loading: false,
          error: null,
        };
      }
      state[journalId].closedTrades = data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTradeData.pending, (state, action) => {
        const journalId = action.meta.arg;
        if (!state[journalId]) {
          state[journalId] = {
            tradeDatas: [],
            openTrades: [],
            closedTrades: [],
            allTags: [],
            loading: true,
            error: null,
          };
        } else {
          state[journalId].loading = true;
        }
      })
      .addCase(fetchTradeData.fulfilled, (state, action) => {
        const { journalId, tradeDatas } = action.payload;
        state[journalId].loading = false;
        state[journalId].error = null;
        state[journalId].tradeDatas = tradeDatas;
        updateTradeDataState(state, journalId, tradeDatas);
      })
      .addCase(fetchTradeData.rejected, (state, action) => {
        const journalId = action.meta.arg;
        state[journalId].loading = false;
        state[journalId].error = action.payload as string;
      })
      .addCase(addTrade.pending, (state, action) => {
        const { journalId } = action.meta.arg;
        if (!state[journalId]) {
          state[journalId] = {
            tradeDatas: [],
            openTrades: [],
            closedTrades: [],
            allTags: [],
            loading: true,
            error: null,
          };
        } else {
          state[journalId].loading = true;
        }
      })
      .addCase(addTrade.fulfilled, (state, action) => {
        const { journalId, trade, shouldUpdateJournal } = action.payload;
        state[journalId].loading = false;
        state[journalId].error = null;
        state[journalId].tradeDatas.push(trade);
        updateTradeDataState(
          state,
          journalId,
          state[journalId].tradeDatas,
          shouldUpdateJournal
        );
      })
      .addCase(addTrade.rejected, (state, action) => {
        const { journalId } = action.meta.arg;
        state[journalId].loading = false;
        state[journalId].error = action.error.message || "Failed to add trade";
      })
      .addCase(updateTrade.pending, (state, action) => {
        const { journalId } = action.meta.arg;
        if (!state[journalId]) {
          state[journalId] = {
            tradeDatas: [],
            openTrades: [],
            closedTrades: [],
            allTags: [],
            loading: true,
            error: null,
          };
        } else {
          state[journalId].loading = true;
        }
      })
      .addCase(updateTrade.fulfilled, (state, action) => {
        const { journalId, updatedData, shouldUpdateJournal } = action.payload;
        state[journalId].loading = false;
        state[journalId].error = null;
        const index = state[journalId].tradeDatas.findIndex(
          (t: TradeDataRecord) => t.id === updatedData.id
        );
        if (index !== -1) {
          state[journalId].tradeDatas[index] = updatedData;
          updateTradeDataState(
            state,
            journalId,
            state[journalId].tradeDatas,
            shouldUpdateJournal
          );
        }
      })
      .addCase(updateTrade.rejected, (state, action) => {
        const { journalId } = action.meta.arg;
        state[journalId].loading = false;
        state[journalId].error =
          action.error.message || "Failed to update trade";
      })
      .addCase(deleteTrade.pending, (state, action) => {
        const { journalId } = action.meta.arg;
        if (!state[journalId]) {
          state[journalId] = {
            tradeDatas: [],
            openTrades: [],
            closedTrades: [],
            allTags: [],
            loading: true,
            error: null,
          };
        } else {
          state[journalId].loading = true;
        }
      })
      .addCase(deleteTrade.fulfilled, (state, action) => {
        const { journalId, newTradeDatas, shouldUpdateJournal } =
          action.payload;
        state[journalId].loading = false;
        state[journalId].error = null;
        state[journalId].tradeDatas = newTradeDatas;
        updateTradeDataState(
          state,
          journalId,
          newTradeDatas,
          shouldUpdateJournal
        );
      })
      .addCase(deleteTrade.rejected, (state, action) => {
        const { journalId } = action.meta.arg;
        state[journalId].loading = false;
        state[journalId].error =
          action.error.message || "Failed to delete trade";
      })
      .addCase(removeTagFromTrades.pending, (state, action) => {
        const journalId = action.meta.arg.journalId;
        if (!state[journalId]) {
          state[journalId] = {
            tradeDatas: [],
            openTrades: [],
            closedTrades: [],
            allTags: [],
            loading: true,
            error: null,
          };
        } else {
          state[journalId].loading = true;
        }
      })
      .addCase(removeTagFromTrades.fulfilled, (state, action) => {
        const { journalId, newTradeDatas } = action.payload;
        state[journalId].loading = false;
        state[journalId].error = null;

        // 업데이트된 거래 상태를 Redux에 반영
        state[journalId].tradeDatas = newTradeDatas;
        updateTradeDataState(state, journalId, newTradeDatas);
      })
      .addCase(removeTagFromTrades.rejected, (state, action) => {
        const journalId = action.meta.arg.journalId;
        state[journalId].loading = false;
        state[journalId].error =
          action.error.message || "Failed to remove tags from trades";
      })
      .addCase(replaceTagInTrades.pending, (state, action) => {
        const journalId = action.meta.arg.journalId;
        if (!state[journalId]) {
          state[journalId] = {
            tradeDatas: [],
            openTrades: [],
            closedTrades: [],
            allTags: [],
            loading: true,
            error: null,
          };
        } else {
          state[journalId].loading = true;
        }
      })
      .addCase(replaceTagInTrades.fulfilled, (state, action) => {
        const { journalId, newTradeDatas } = action.payload;
        state[journalId].loading = false;
        state[journalId].error = null;

        // 업데이트된 거래 상태를 Redux에 반영
        state[journalId].tradeDatas = newTradeDatas;
        updateTradeDataState(state, journalId, newTradeDatas);
      })
      .addCase(replaceTagInTrades.rejected, (state, action) => {
        const journalId = action.meta.arg.journalId;
        state[journalId].loading = false;
        state[journalId].error =
          action.error.message || "Failed to replace tags in trades";
      });
  },
});

export const { setTradeDatas, setOpenTrades, setClosedTrades } =
  tradeDataSlice.actions;
export default tradeDataSlice.reducer;
