import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import CssBaseline from "@mui/material/CssBaseline";
import { store, history } from "./store";
import { enqueueSnackbar, SnackbarProvider } from "notistack";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "./store/store";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase";
import { setUser, signIn } from "./store/reducers/auth";
import { readFirestoreDoc } from "./hooks/firebase/firestoreCRUD";
import { fetchUserData } from "./store/reducers/user";

onAuthStateChanged(auth, async (user) => {
  if (user) {
    const token = await user.getIdToken();
    store.dispatch(setUser({ user: user, idToken: token }));
    store
      .dispatch(fetchUserData(user.uid))
      .then((result) => {
        if (result.meta.requestStatus === "fulfilled") {
          // enqueueSnackbar("환영합니다.", {
          //   variant: "info",
          //   autoHideDuration: 2000,
          // });
          console.log("user check");
        }
      })
      .catch((error) => {
        // console.error(" onAuthStateChanged Failed to fetch user data:", error);
        enqueueSnackbar(`회원정보 확인 중 에러가 발생하였습니다. ${error}`, {
          variant: "error",
          autoHideDuration: 2000,
        });
      });
  }
});

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <PersistGate loading={null} persistor={persistor}>
        <SnackbarProvider maxSnack={5}>
          <App />
        </SnackbarProvider>
      </PersistGate>
    </ThemeProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
