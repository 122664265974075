import { Box, Typography } from "@mui/material";

export interface RoundedTextBoxProps {
  text: string | number;
  borderRadius?: number;
  backgroundColor?: string;
  textColor?: string;
  fontSize?: string | number;
  bold?: boolean;
}

const RoundedTextBox: React.FC<RoundedTextBoxProps> = ({
  text,
  borderRadius = 4,
  backgroundColor = "#4880FF",
  textColor = "white",
  fontSize = 12,
  bold = false,
}) => {
  return (
    <Box
      sx={{
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor,
        borderRadius,
        padding: "2px 14px",
      }}
    >
      <Typography
        sx={{
          color: textColor,
          fontSize: fontSize,
          fontWeight: bold ? "bold" : "normal",
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default RoundedTextBox;
