export const paths = {
  home: "/",
  contact: "/contact",
  about: "/about",
  faq: "/faq",
  pricing: "/pricing",
  auth: {
    signIn: "/auth/sign-in",
    signUp: "/auth/sign-up",
    resetPassword: "/auth/reset-password",
  },
  dashboard: {
    overview: "/dashboard",
    journal: {
      layout: "/dashboard/journal",
      overview: "/dashboard/journal",
      detail: "/dashboard/journal/details",
    },
    analysis: "/dashboard/analysis",
    leaderboard: "/dashboard/leaderboard",
    pricing: "/dashboard/pricing",
    exchange: {
      connect: "/dashboard/exchange-connect",
    },
    apimanager: "/dashboard/apimanager",
    settings: "/dashboard/settings",
    logout: "/",
    guide: "https://blog.naver.com/memememo_official/223608570369",
    qna: "https://open.kakao.com/o/szklaASg",
  },
  errors: { notFound: "/errors/not-found" },
} as const;
