import { createSelector } from "reselect";
import { RootState } from "../store";

// tradeDataSelectors.ts
export const selectTradeDataByJournalId = (
  state: RootState,
  journalId: string
) => state.tradeData[journalId];

export const selectTradeDatas = createSelector(
  [selectTradeDataByJournalId],
  (tradeData) => tradeData?.tradeDatas || []
);

export const selectOpenTrades = createSelector(
  [selectTradeDataByJournalId],
  (tradeData) => tradeData?.openTrades || []
);

export const selectClosedTrades = createSelector(
  [selectTradeDataByJournalId],
  (tradeData) => tradeData?.closedTrades || []
);

export const selectAllTags = createSelector(
  [selectTradeDataByJournalId],
  (tradeData) => tradeData?.allTags || []
);

export const selectTradeLoading = createSelector(
  [selectTradeDataByJournalId],
  (tradeData) => tradeData?.loading || false
);
